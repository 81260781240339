import { API_URL } from "../config";
import apisauce from "apisauce";
import Store from "../redux/Store";
import { REDUX_CONST } from "../config/constats";

const apiClient = (baseURL = API_URL) => {
    const token = Store.getState().userReducer.authToken;

    const withToken = !(token !== undefined && token !== null);

    const data =
        (withToken && {
            //'Content-Type': 'application/json',
            //'Cache-Control': 'no-cache',
            Accept: "application/json",
        }) ||
        (!withToken && {
            //'Content-Type': 'application/json',
            //'Cache-Control': 'no-cache',
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
        });

    const api = apisauce.create({
        baseURL,
        headers: data,
        timeout: 10000,
    });

    const apiMonitor = (response) => {
        //console.log('monitoring initiated');
        //console.log(JSON.stringify(response));
        if (response.status === 401) {
            localStorage.removeItem("token");
            Store.dispatch({
                type: REDUX_CONST.LOGOUT,
            });
        }
    };
    api.addMonitor(apiMonitor);

    return api;
};

export { apiClient };
