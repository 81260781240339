import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import {HashRouter} from 'react-router-dom'
import ScrollToTop from './template/ScrollToTop';
import {Provider} from "react-redux";
import Store from "./redux/Store";
import App from "./App";
import {Toast} from "primereact/toast";

ReactDOM.render(
    <Provider store={Store}>
        <HashRouter>
            <ScrollToTop>
                <App/>
            </ScrollToTop>
        </HashRouter>
    </Provider>,
    document.getElementById('root')
);

