import {REDUX_CONST} from "../../../config/constats";
import Store from "../../Store";
import {getGlobaleToast} from "../../../utils";
import {apiClient} from "../../../api/ApiClient";
import {loadEspeceCombo} from "../../espece/EspeceActions";


const endpoint = 'site/valeurs';

const showNetworkLoading = payload => {
    return async dispatch => {
        await dispatch({
            type: REDUX_CONST.SITE_VALEUR.LOADING,
            payload: payload,
        });
    };
};

const showNetworkLoadingValidationProcess = payload => {
    return async dispatch => {
        await dispatch({
            type: REDUX_CONST.SITE_VALEUR.LOADING_VALIDATION_PROCESS,
            payload: payload,
        });
    };
};

const showErrorMessage = payload => {
    return async dispatch => {
        await dispatch(showNetworkLoading(false));
        await dispatch(showNetworkLoadingValidationProcess(false));
    };
};

export const formatData = (payload) => {
    return async dispatch => {
        await dispatch({
            type: REDUX_CONST.SITE_VALEUR.LIST,
            payload: payload,
        });

        await dispatch(showNetworkLoading(false));
        await dispatch(showNetworkLoadingValidationProcess(false));
    };
};


export const loadSiteValeurs = (text = "") => {
    return async dispatch => {
        let data = {};
        await dispatch(showNetworkLoading(true));

        let site_id = Store.getState().siteReducer.selectedRecord.id

        await apiClient()
            .get(
                `${endpoint}/?site_id=${site_id}&search=${text}`,
            )
            .then(res => {
                if (res.ok) {
                    let data = res.data
                    setTimeout(() => {
                        dispatch(formatData(data.results));
                    }, Math.random() * 1000 + 250)
                }
            }).catch((error) => {
                if (error.response) {
                    return error.response.data
                    dispatch(showErrorMessage(error.response.data))
                }
            });
    };
}


export const saveSiteRecord = (data) => {
    return async dispatch => {
        await dispatch(showNetworkLoadingValidationProcess(true));
        await apiClient()
            .post(
                `${endpoint}/`, data
            )
            .then(async res => {
                if (res.ok) {
                    let data = {...res.data};
                    await dispatch({
                        type: REDUX_CONST.SITE_VALEUR.DATA_SAVED,
                        payload: {
                            data: res.data,
                            operation: 'ADD'
                        }
                    })
                    getGlobaleToast({severity: 'success', summary: 'Successful', detail: "L'enregistrement a été crée avec succès", life: 5000});
                } else {
                    getGlobaleToast({severity: 'error', summary: 'ERROR', detail: res.error.toString(), life: 3000});
                }
            });
    }
};

export const setSelectedSiteValeur = siteValeur => {
    return async dispatch => {
        await dispatch({
            type: REDUX_CONST.SITE_VALEUR.SET_SELECTED,
            payload: siteValeur,
        });
    };
};

export const addSiteValeur = (valeur) => {
    return async dispatch => {
        await dispatch(loadEspeceCombo());
        await dispatch({
            type: REDUX_CONST.SITE_VALEUR.ADD_NEW,
            payload: valeur,
        });
    };
};

export const editSiteValeur = (valeur) => {
    return async dispatch => {
        await dispatch({
            type: REDUX_CONST.SITE_VALEUR.EDIT_SELECTED,
            payload: valeur,
        });
    };
};

export const cancelEditSiteValeur = () => {
    return async dispatch => {
        await dispatch({
            type: REDUX_CONST.SITE_VALEUR.CANCEL_EDIT,
            payload: [],
        });
    };
};





