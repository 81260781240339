export const menu = [{
    label: '', items: [{
        label: 'Tableau de board', icon: 'pi pi-fw pi-home', to: '/'
    }]
},
    {
        label: 'Recensements',
        icon: 'pi pi-fw pi-sitemap',
        items: [
            {label: 'Recensements', icon: 'pi pi-fw pi-search', to: '/visites'},
            {label: 'Importations', icon: 'pi pi-fw pi-search', to: '/importations'},
            /*{
                label: 'Référentiel', icon: 'pi pi-fw pi-cog',
                items: [
                    {label: 'Marées', icon: 'pi pi-fw pi-sun', to: '/marees'},
                    {label: 'Matériel', icon: 'pi pi-fw pi-box', to: '/typemarl'},
                    {label: 'Gel', icon: 'pi pi-fw pi-cloud', to: '/typegel'},
                    {label: 'Dérangment', icon: 'pi pi-fw pi-bolt', to: '/typederg'},
                    {label: 'Source dérangment', icon: 'pi pi-fw pi-bolt', to: '/sourcederg'},
                    {label: 'Méteo', icon: 'pi pi-fw pi-sun', to: '/typemeteo'},
                    {label: 'Couverture', icon: 'pi pi-fw pi-sun', to: '/typecouverture'},
                    {label: 'Méthodes', icon: 'pi pi-fw pi-sun', to: '/typemethode'},
                    {label: 'Inondations', icon: 'pi pi-fw pi-sun', to: '/typeinnondation'},
                    {label: 'Qualité', icon: 'pi pi-fw pi-sun', to: '/typequalite'},
                    {label: 'Type de bagues', icon: 'pi pi-fw pi-sun', to: '/typebague'},
                ]
            },*/
            {
                label: 'Repporting', icon: 'pi pi-fw pi-chart-bar',
                items: [
                    {label: 'Synthèses', icon: 'pi pi-fw pi-sun', to: '/syntheses'},
                ]
            },

        ]
    }, {
        label: 'Espaces', icon: 'pi pi-fw pi-sitemap',
        items: [
            {label: 'Sites', icon: 'pi pi-fw pi-map-marker', to: '/sites'},
            /*{label: 'Sites2', icon: 'pi pi-fw pi-map-marker', to: '/sites2'},*/
            /*{label: 'Sites3', icon: 'pi pi-fw pi-map-marker', to: '/sites3'},*/
            {label: 'Régions', icon: 'pi pi-fw pi-map', to: '/region'},
            {label: 'Complexes', icon: 'pi pi-fw pi-map-marker', to: '/complexe'},
            /*{
                label: 'Référentiel', icon: 'pi pi-fw pi-cog',
                items: [
                    {label: 'Types sites', icon: 'pi pi-fw pi-user-edit', to: '/typesites'},
                    {label: 'Types fonciers', icon: 'pi pi-fw pi-user-edit', to: '/typefoncier'},
                    {label: 'Types acteurs', icon: 'pi pi-fw pi-user-edit', to: '/typeacteur'},
                    {label: 'Types interventions', icon: 'pi pi-fw pi-user-edit', to: '/typeintervention'},
                    {label: 'Typologies d\'habitats', icon: 'pi pi-fw pi-user-edit', to: '/typehabitat'},
                ]
            },*/
            {
                label: 'Repporting', icon: 'pi pi-fw pi-chart-bar',
                items: [
                    {label: 'Carte des sites', icon: 'pi pi-fw pi-user-edit', to: '/sitesmap'},
                    {label: 'Carte des point', icon: 'pi pi-fw pi-user-edit', to: '/sitesmarkers'},
                    {label: 'Distribution des oiseaux d\'eau', icon: 'pi pi-fw pi-user-edit', to: '/sitesclusters'},

                ]
            },
        ]
    }, {
        label: 'Espèces', icon: 'pi pi-fw pi-bird',
        items: [
            {label: 'Espèces nationale', icon: 'pi pi-fw pi-twitter', to: '/especenat'},
            /* {
                 label: 'Référentiel', icon: 'pi pi-fw pi-cog',
                 items: [
                     {label: 'Status Nationale', icon: 'pi pi-fw pi-user-edit', to: '/statutnats'},
                     {label: 'Status Internationale', icon: 'pi pi-fw pi-user-edit', to: '/statutwis'},
                     {label: 'Type tendance', icon: 'pi pi-fw pi-user-edit', to: '/typetendance'},
                     {label: 'Langues', icon: 'pi pi-fw pi-user-edit', to: '/langues'},
                 ]
             },*/
            {
                label: 'Repporting', icon: 'pi pi-fw pi-chart-bar',
                items: [
                    {label: 'Status Nationale', icon: 'pi pi-fw pi-user-edit', to: '/chart'},
                ]
            },
        ]
    },
    {
        label: 'Paramétrages',
        icon: 'pi pi-fw pi-sitemap',
        items: [
            {
                label: 'Référentiel', icon: 'pi pi-fw pi-cog',
                items: [
                    {label: 'Marées', icon: 'pi pi-fw pi-sun', to: '/marees'},
                    {label: 'Matériel', icon: 'pi pi-fw pi-box', to: '/typemarl'},
                    {label: 'Gel', icon: 'pi pi-fw pi-cloud', to: '/typegel'},
                    {label: 'Dérangment', icon: 'pi pi-fw pi-bolt', to: '/typederg'},
                    {label: 'Source dérangment', icon: 'pi pi-fw pi-bolt', to: '/sourcederg'},
                    {label: 'Méteo', icon: 'pi pi-fw pi-sun', to: '/typemeteo'},
                    {label: 'Couverture', icon: 'pi pi-fw pi-sun', to: '/typecouverture'},
                    {label: 'Méthodes', icon: 'pi pi-fw pi-sun', to: '/typemethode'},
                    {label: 'Inondations', icon: 'pi pi-fw pi-sun', to: '/typeinnondation'},
                    {label: 'Qualité', icon: 'pi pi-fw pi-sun', to: '/typequalite'},
                    {label: 'Type de bagues', icon: 'pi pi-fw pi-sun', to: '/typebague'},

                    {label: 'Types sites', icon: 'pi pi-fw pi-user-edit', to: '/typesites'},
                    {label: 'Types fonciers', icon: 'pi pi-fw pi-user-edit', to: '/typefoncier'},
                    {label: 'Types acteurs', icon: 'pi pi-fw pi-user-edit', to: '/typeacteur'},
                    {label: 'Types interventions', icon: 'pi pi-fw pi-user-edit', to: '/typeintervention'},
                    {label: 'Typologies d\'habitats', icon: 'pi pi-fw pi-user-edit', to: '/typehabitat'},

                    {label: 'Status Nationale', icon: 'pi pi-fw pi-user-edit', to: '/statutnats'},
                    {label: 'Status Internationale', icon: 'pi pi-fw pi-user-edit', to: '/statutwis'},
                    {label: 'Type tendance', icon: 'pi pi-fw pi-user-edit', to: '/typetendance'},
                    {label: 'Langues', icon: 'pi pi-fw pi-user-edit', to: '/langues'},

                ]
            },
        ]
    },
    /*{
    label: 'Paramétrages',
    icon: 'pi pi-fw pi-sitemap',
    items: [
        {label: 'Users', icon: 'pi pi-fw pi-user-edit', to: '/marees'},
    ]
},*/


    /*{
    label: 'Autre', icon: 'pi pi-fw pi-sitemap',
    items: [
        {label: 'Login Page', icon: 'pi pi-fw pi-id-card', to: '/loginpage'},
        {label: 'MAP', icon: 'pi pi-fw pi-user-edit', to: '/map'},
    ]
},*/
];
