import {REDUX_CONST} from '../config/constats';

const initialState = {
    layoutMode: 'static',
    layoutColorMode: 'light',
    inputStyle: "outlined",
    ripple: false,
    staticMenuInactive: false,
    overlayMenuActive: false,
    mobileMenuActive: false,
    mobileTopbarMenuActive: false,
    TOAST: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case REDUX_CONST.SET_GLOBALE_TOAST:
            return {
                ...state,
                TOAST: action.payload
            };
        case REDUX_CONST.LAYOUT_MODE:
            return {
                ...state,
                layoutMode: action.payload
            };
        case REDUX_CONST.LAYOUT_COLOR:
            MODE:
                return {
                    ...state,
                    layoutColorMode: action.payload
                };
        case REDUX_CONST.INPUT_STYLE:
            return {
                ...state,
                inputStyle: action.payload
            };
        case REDUX_CONST.RIPPLE:
            return {
                ...state,
                ripple: action.payload
            };
        case REDUX_CONST.STATIC_MENU_ACTIVE:
            return {
                ...state,
                staticMenuInactive: action.payload
            };
        case REDUX_CONST.OVERLAY_MENU_ACTIVE:
            return {
                ...state,
                overlayMenuActive: action.payload
            };
        case REDUX_CONST.MOBIEL_MENU_ACTIVE:
            return {
                ...state,
                mobileMenuActive: action.payload
            };
        case REDUX_CONST.MOBILE_TOP_BAR_MENU_ACTIVE:
            return {
                ...state,
                mobileTopbarMenuActive: action.payload
            };
        default:
            return state;
    }
};
