import React, {useMemo} from 'react';
import L,{CRS} from 'leaflet';

import {MapContainer, ScaleControl, ZoomControl} from 'react-leaflet'
import MapLayers from "./MapLayers";

import "react-leaflet-fullscreen/dist/styles.css";
import "leaflet-draw/dist/leaflet.draw.css";
import {FullscreenControl} from "react-leaflet-fullscreen/index";
import MapEditPolygons from "./MapEditPolygons";
import LocationMarker from "./LocationMarker";
import {connect} from "react-redux";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png",
    iconUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png",
    shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-shadow.png",
});


const SitesMapComponent = (props) => {
    const eventHandlers = useMemo(
        (e) => ({
            click(e) {
                props.setSlected(e.sourceTarget.options.data)
            },
        }),
        [],
    )


    return (<React.Fragment>
        <MapContainer
            center={props.center}
            zoom={props.zoom_level}
            zoomControl={false}
            fullscreenControl={false}
            style={{height: props.height}}
            crs={CRS.EPSG3857}
        >

            <ScaleControl position='bottomright'/>
            <ZoomControl position='bottomleft'/>
            <FullscreenControl position='bottomleft'/>

            <MapLayers position="bottomleft" polygonClicked={eventHandlers}/>
            <MapEditPolygons position="topleft" selected={props.selected} onChange={false}/> :

            <LocationMarker/>
        </MapContainer>
    </React.Fragment>);
};


const mapStateToProps = state => {
    return {
        loading: state.mapReducer.loading,
        data: state.mapReducer.data,
        center: state.mapReducer.center,
        zoom_level: state.mapReducer.zoom_level,
        editionMode: state.mapReducer.editionMode,
    };
};


export default connect(mapStateToProps, {})(SitesMapComponent)
