import React, {useState} from "react";
import {connect} from "react-redux";
import {classNames} from "primereact/utils";
import {getFormikFormErrorMessage, isFormikFormFieldValid} from "../../../utils";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {useFormik} from "formik";
import * as Yup from "yup";
import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import ActionBodyTemplate from "../../../components/actionBodyTemplate";


const ModalSynonyms = (props) => {

    const [editionMode, setEditionMode] = useState(false);


    const validationSchema = Yup.object().shape({
        synonyme: Yup.string().required("Le synonyme est obligatoire").nullable(),
    })

    const formik = useFormik({
        initialValues: props.selected,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if (values.code && values.nom.trim()) {
                let record = {...values}
                props.saveRecord(record)
                formik.resetForm();
            }
        }
    });


    const renderFooter = (name) => {
        return (
            <div>
                <Button label="No" icon="pi pi-times" onClick={() => props.hide()} className="p-button-text"/>
                <Button label="Yes" icon="pi pi-check" onClick={() => props.hide()} autoFocus/>
            </div>
        );
    }

    const ActionBtns = () => {
        return <React.Fragment>
            {editionMode ? (
                <>
                    <Button
                        icon="pi pi-save"
                        type="submit"
                        form="formTabSiteRechercheSc"
                        className="mr-2"/>
                    <Button
                        icon="pi pi-times"
                        className="p-button-secondary"
                        type="button"
                        //onClick={() => null}
                    />
                </>
            ) : (
                <Button
                    icon="pi pi-pencil"
                    className="p-button-warning"
                    type="button"
                    //onClick={() => null}
                />
            )}

        </React.Fragment>
    };

    return (
        <Dialog
            header={"Liste des Synonyms"}
            closeOnEscape={true}
            closable
            visible={props.show}
            modal={false}
            style={{width: '50vw'}}
            //footer={renderFooter('displayModal')}
            onHide={() => props.hide()}>

            <div className="p-fluid formgrid grid">
                <div className="field col-12 md:col-3">
                    <label
                        htmlFor="synonyme"
                        className={classNames({'p-error': isFormikFormFieldValid(formik, "synonyme")})}>
                        Synonyme *
                    </label>
                    <InputText
                        id="synonyme"
                        name="synonyme"
                        value={formik.values.synonyme}
                        onChange={formik.handleChange}
                        autoFocus
                        className={classNames({
                            "p-invalid": isFormikFormFieldValid(formik, "synonyme")
                        })}
                    />
                    {getFormikFormErrorMessage(formik, "synonyme")}
                </div>
                <div className="field col-12 md:col-6">
                    <label
                        htmlFor="note"
                        className={classNames({'p-error': isFormikFormFieldValid(formik, "note")})}>
                        Note *
                    </label>
                    <InputTextarea
                        id="note"
                        name="note"
                        value={formik.values.note}
                        onChange={formik.handleChange}
                        rows={1}
                        className={classNames({
                            "p-invalid": isFormikFormFieldValid(formik, "note")
                        })}
                    />
                    {getFormikFormErrorMessage(formik, "note")}
                </div>
                <div className="field col-12 md:col-3 mt-4">
                    <ActionBtns/>
                </div>
                <div className="field col-12 md:col-12">
                    <DataTable value={props.selected?.synonyms} size="small" responsiveLayout="scroll"
                               onSelectionChange={(event) => props.setSelectedSiteRedacteur(event.value)}
                    >
                        <Column field="synonyme" header="Synonyme" style={{width: '20%'}}></Column>
                        <Column field="note" header="Note" style={{width: '60%'}}></Column>
                        <Column
                            style={{width: '10%'}}
                            body={(rowData) => <ActionBodyTemplate
                                data={rowData}
                                editRecord={() => setEditionMode(true)}
                                deleteRecord={true}
                            />}
                        />
                    </DataTable>
                </div>
            </div>

        </Dialog>
    );
}


const mapStateToProps = state => {
    return {
        selected: state.siteReducer.selectedRecord,
    };
};

export default connect(mapStateToProps, {})(ModalSynonyms)


