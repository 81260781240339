import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Toolbar} from "primereact/toolbar";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {SiteService} from "../../service/espace/SiteService";
import LeftToolbarTemplate from "../../components/leftToolbarTemplate";
import RightToolbarTemplate from "../../components/rightToolbarTemplate";
import {RecensementService} from "../../service/recensement/RecensementService";
import {composeValidators, findIndexById, focusNextInputByRef, formatDate, getFormErrorMessage, getNewDate, identity, isFormFieldValid, maxLength, mustBeNumber, required} from "../../utils";
import {Calendar} from "primereact/calendar";
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import {RecensementResultatService} from "../../service/recensement/RecensementResultatService";
import ResRowExpansionTemplate from "./recRowExpansionTemplate";
import {Field, Form} from "react-final-form";
import {Dialog} from "primereact/dialog";
import ErrorMessageTemplate from "../../components/errorMessageTemplate";
import classNames from "classnames";
import {AutoComplete} from "primereact/autocomplete";
import {PersonneService} from "../../service/personne/PersonneService";
import {TypeCouvertureService} from "../../service/recensement/TypeCouvertureService";
import {TypeQualiteRecService} from "../../service/recensement/TypeQualiteRecService";
import {RecTypeMethodeService} from "../../service/recensement/RecTypeMethodeService";
import {RecTypemarlsService} from "../../service/recensement/RecTypemarlsService";
import {RecInnondationService} from "../../service/recensement/RecInnondationService";
import {RecGelService} from "../../service/recensement/RecGelService";
import {MareeService} from "../../service/recensement/MareeService";
import {TypeMeteoService} from "../../service/recensement/TypeMeteoService";
import {TypedergService} from "../../service/recensement/TypedergService";
import {TypeSourcedergService} from "../../service/recensement/TypeSourcedergService";
import {InputTextarea} from "primereact/inputtextarea";
import ActionBodyTemplate from "../../components/actionBodyTemplate";
import {InputText} from "primereact/inputtext";
import {EspeceNatService} from "../../service/espece/EspeceNatService";
import {connect} from "react-redux";
import {Login} from "../../redux/UserActions";


const RecensementPage = (props) => {
    const dtRec = useRef(null);
    const dtRes = useRef(null);


    let loadLazyTimeout = null;

    //-------------------------------------
    //-----REC-----------------------------
    //-------------------------------------
    let emptyRec = {
        id: null, site: {}, date: new Date(), heurdebut: new Date(), heurfin: new Date(), recencouvr: {}, recensqual: {}, typemeth: {}, receninnond: {}, recengel: {}, maree: {}, recenmeteo: {}, typederg: {}, typemarl: {}, observation: '', note: '', groupe: '', sourcederg: ''
    };
    const [lazyParamsRec, setLazyParamsRec] = useState({
        first: 0, rows: 50, page: 0, sortField: null, sortOrder: null, filters: {
            'site': {value: '', matchMode: 'contains'}, 'date': {value: '', matchMode: 'contains'}, 'year': {value: '', matchMode: 'contains'}, 'global': {value: '', matchMode: 'contains'},
        }
    });
    const [yearFilter, setYearFilter] = useState('')
    const [recList, setRecList] = useState([])
    const [selectedRec, setSelectedRec] = useState(emptyRec)
    const [rowsRec, setRowsRec] = useState(50);
    const [totalRecordsRec, setTotalRecordsRec] = useState(0);
    const [globalFilterRec, setGlobalFilterRec] = useState(null);
    const [loadingRec, setLoadingRec] = useState(false);


    const [dialogTitleRec, setDialogTitleRec] = useState('');
    const [recordDialogRec, setDialogRec] = useState(false);
    const [deleteRecordDialogRec, setDeleteRecordDialogRec] = useState(false);
    const [submittedRec, setSubmittedRec] = useState(false);


    const [siteComboItems, setSiteComboItems] = useState();
    const [yearRecComboItems, setYearRecComboItems] = useState();
    const [observateurComboItems, setObservateursComboItems] = useState();
    const [couverturesComboItems, setCouverturesComboItems] = useState();
    const [qualitesComboItems, setQualitesComboItems] = useState();

    const [typeMethodesComboItems, setTypeMethodesComboItems] = useState();
    const [materielsComboItems, setMaterielsComboItems] = useState();
    const [innondationsComboItems, setInnondationsComboItems] = useState();
    const [gelsComboItems, setGelsComboItems] = useState();
    const [mareesComboItems, setMareesComboItems] = useState();
    const [visibilitesComboItems, setVisibilitesComboItems] = useState();
    const [typeDerangementsComboItems, setTypeDerangementsComboItems] = useState();
    const [sourceDerangementsComboItems, setSourceDerangementsComboItems] = useState();
    const [filteredObservateus, setFilteredObservateus] = useState(null);
    const [filteredEspeces, setFilteredEspeces] = useState(null);
    const [filteredSourceDerangements, setFilteredSourceDerangements] = useState(null);
    const [selectedObservateurs, setSelectedObservateurs] = useState(null);
    const [selectedEspece, setSelectedEspece] = useState(null);


    //-------------------------------------
    //-----RES-----------------------------
    //-------------------------------------
    let emptyRes = {
        id: null, recensement_id: null, recensqual: null, especenat: null, effectif: null, donneebags: null,
    };
    const [lazyParamsRes, setLazyParamsRes] = useState({
        first: 0, rows: 50, page: 0, sortField: null, sortOrder: null, filters: {
            'rec_id': {value: '', matchMode: 'contains'}, 'espece': {value: '', matchMode: 'contains'}, 'global': {value: '', matchMode: 'contains'},
        }
    });
    const [resList, setResList] = useState([])
    const [selectedRes, setSelectedRes] = useState(emptyRes)
    const [rowsRes, setRowsRes] = useState(50);
    const [totalRecordsRes, setTotalRecordsRes] = useState(0);
    const [globalFilterRes, setGlobalFilterRes] = useState(null);
    const [loadingRes, setLoadingRes] = useState(false);
    const [expandedRowsRes, setExpandedRowsRes] = useState();


    const [dialogTitleRes, setDialogTitleRes] = useState('');
    const [recordDialogRes, setRecordDialogRes] = useState(false);
    const [deleteRecordDialogRes, setDeleteRecordDialogRes] = useState(false);
    const [submittedRes, setSubmittedRes] = useState(false);

    const [especeResComboItems, setEspeceResComboItems] = useState()


    const [showObservationInput, setShowObservationInput] = useState(false)


    const recService = new RecensementService();
    const resService = new RecensementResultatService();
    const siteService = new SiteService();
    const personneService = new PersonneService();
    const couvertureService = new TypeCouvertureService();
    const qualiteRecService = new TypeQualiteRecService();
    const recTypeMethodeService = new RecTypeMethodeService();
    const recTypemarlsService = new RecTypemarlsService();
    const recInnondationService = new RecInnondationService();
    const recGelService = new RecGelService();
    const mareeService = new MareeService();
    const visibiliteService = new TypeMeteoService();
    const typedergService = new TypedergService();
    const sourcedergService = new TypeSourcedergService();
    const especeNatService = new EspeceNatService();


    useEffect(() => {
        loadLazyDataRec();
        loadSiteCombo();
        loadYearRecCombo();
        loadPersonneCombo();
        loadCouvertureCombo();
        loadQualiteRecCombo();
        loadMethodeRecCombo();
        loadMaterielCombo();
        loadInnondationCombo();
        loadGelCombo();
        loadMareeCombo();
        loadVisibiliteCombo();
        loadTypeDergCombo();
        loadSourceDergCombo();
        loadEspeceCombo();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        loadLazyDataRec();
    }, [lazyParamsRec]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (selectedRec.id) loadLazyDataRes();
    }, [lazyParamsRes]) // eslint-disable-line react-hooks/exhaustive-deps


    const loadSiteCombo = () => {
        siteService.getCombo("", props.authToken).then(data => {
            setSiteComboItems(data)
        });
    }

    const loadPersonneCombo = () => {
        personneService.getCombo("", props.authToken).then(data => {
            setObservateursComboItems(data)
        });
    }


    const loadCouvertureCombo = () => {
        couvertureService.getCombo("", props.authToken).then(data => {
            setCouverturesComboItems(data)
        });
    }

    const loadQualiteRecCombo = () => {
        qualiteRecService.getCombo("", props.authToken).then(data => {
            setQualitesComboItems(data)
        });
    }

    const loadMethodeRecCombo = () => {
        recTypeMethodeService.getCombo("", props.authToken).then(data => {
            setTypeMethodesComboItems(data)
        });
    }

    const loadMaterielCombo = () => {
        recTypemarlsService.getCombo("", props.authToken).then(data => {
            setMaterielsComboItems(data)
        });
    }

    const loadInnondationCombo = () => {
        recInnondationService.getCombo("", props.authToken).then(data => {
            setInnondationsComboItems(data)
        });
    }

    const loadGelCombo = () => {
        recGelService.getCombo("", props.authToken).then(data => {
            setGelsComboItems(data)
        });
    }

    const loadMareeCombo = () => {
        mareeService.getCombo("", props.authToken).then(data => {
            setMareesComboItems(data)
        });
    }

    const loadVisibiliteCombo = () => {
        visibiliteService.getCombo("", props.authToken).then(data => {
            setVisibilitesComboItems(data)
        });
    }

    const loadTypeDergCombo = () => {
        typedergService.getCombo("", props.authToken).then(data => {
            setTypeDerangementsComboItems(data)
        });
    }

    const loadSourceDergCombo = () => {
        sourcedergService.getCombo("", props.authToken).then(data => {
            setSourceDerangementsComboItems(data)
        });
    }


    const loadYearRecCombo = () => {
        recService.getComboYear("", props.authToken).then(data => {
            setYearRecComboItems(data)
        });
    }

    const loadEspeceCombo = () => {
        especeNatService.getCombo("", props.authToken).then(data => {
            setEspeceResComboItems(data)
        });
    }

    const loadEspeceResCombo = () => {
        recService.getComboYear("", props.authToken).then(data => {
            setYearRecComboItems(data)
        });
    }

    //-------------------------------------
    //-----REC-----------------------------
    //-------------------------------------

    const loadLazyDataRec = () => {
        setLoadingRec(true);
        setResList(null)
        setSelectedRec(emptyRec)
        setSelectedRes(emptyRes)
        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }
        loadLazyTimeout = setTimeout(() => {
            recService.getList(lazyParamsRec, props.authToken).then(data => {
                setTotalRecordsRec(data.count);
                setRecList(data.results);
                setLoadingRec(false);
            });
        }, Math.random() * 1000 + 250);
    }

    const onPageRec = (event) => {
        setRowsRec(event.rows);
        setLazyParamsRec(event);
        event['page'] = event['page'] + 1
    }

    const onSortRec = (event) => {
        setLazyParamsRec(event);
    }
    const onFilterRec = (event) => {
        const year = event.filters.date.value?.year
        event.filters.year.value = year ? `${year}` : ''
        event['first'] = 1;
        setLazyParamsRec(event);
    }

    const clearFilterRec = () => {
        setYearFilter('')
        setLazyParamsRec({
            first: 0, rows: 10, page: 0, sortField: null, sortOrder: null, filters: {
                'site': {value: '', matchMode: 'contains'}, 'date': {value: '', matchMode: 'contains'}, 'year': {value: '', matchMode: 'contains'}, 'global': {value: '', matchMode: 'contains'},
            }
        });
    }

    const onRecSelectionChange = async (event) => {
        await setSelectedRec(event.value);
        const lazyparam = {
            first: 0,
            rows: 50,
            page: 0,
            sortField: null,
            sortOrder: null,
            filters: {
                'rec_id': {value: event.value.id, matchMode: 'contains'},
                'espece': {value: '', matchMode: 'contains'},
                'global': {value: '', matchMode: 'contains'},
            }
        }
        setSelectedRes(emptyRes)
        setLazyParamsRes(lazyparam)
        setRecordDialogRes(false)
    }

    const validateRec = (data) => {
        let errors = {};
        if (!data.site) {
            errors.site = "La site est obligatoire";
        }
        if (!data.date) {
            errors.date = "La date est obligatoire";
        }
        return errors;
    };


    const newRec = () => {
        setDialogTitleRec('Nouveau recensement')
        setSelectedRec(emptyRec);
        setSubmittedRec(false);
        setDialogRec(true);
    }
    const editRec = async (selectedRow) => {
        const _selectedRow = {...selectedRow}

        _selectedRow.heurdebut = _selectedRow.date + ' ' + _selectedRow.heurdebut
        _selectedRow.heurfin = _selectedRow.date + ' ' + _selectedRow.heurfin

        setDialogTitleRec(selectedRow.id === null ? 'Nouveau recensement' : `Edition recensement: (${_selectedRow.site.site_nom}) ${formatDate(_selectedRow.date)}`)
        setSelectedRec(_selectedRow);
        setDialogRec(true);
    }

    const saveRec = async (data, form) => {
        setSubmittedRec(true);
        let _recordList = [...recList];
        let _record = {...data}
        let newObject = {...data}
        newObject.date = new Date(data.date).toISOString().slice(0, 10)
        newObject.heurdebut = new Date(data.heurdebut).toLocaleTimeString('en-US')
        newObject.heurfin = new Date(data.heurfin).toLocaleTimeString('en-US')
        newObject.site_id = data.site?.id
        newObject.recencouvr_id = data.recencouvr?.id
        newObject.recensqual_id = data.recensqual?.id
        newObject.typemeth_id = data.typemeth?.id
        newObject.receninnond_id = data.receninnond?.id
        newObject.recengel_id = data.recengel?.id
        newObject.maree_id = data.maree?.id
        newObject.recenmeteo_id = data.recenmeteo?.id
        newObject.typederg_id = data.typederg?.id
        newObject.typemarl_id = data.typemarl?.id
        newObject.groupe = data.groupe ? data.groupe.map(item => item.id) : []
        newObject.sourcederg = data.sourcederg ? data.sourcederg.map(item => item.id) : []


        delete newObject.site
        delete newObject.recencouvr
        delete newObject.recensqual
        delete newObject.typemeth
        delete newObject.receninnond
        delete newObject.recengel
        delete newObject.maree
        delete newObject.recenmeteo
        delete newObject.typederg
        delete newObject.typemarl

        if (newObject.id) {
            const index = findIndexById(_recordList, newObject.id);
            recService.updateOne(newObject, props.authToken).then((res) => {
                _recordList[index] = _record;
                setRecList(_recordList);
                setDialogRec(false);
                setSelectedRec(emptyRec);
                //toast.current.show({severity: 'success', summary: 'Successful', detail: 'Le recensement a été mis a jour avec succés', life: 3000});
                form.reset()
            }).catch(error => {


            })
        } else {
            recService.addOne(newObject, props.authToken).then((res) => {
                lazyParamsRec.sortField = 'id';
                lazyParamsRec.sortOrder = -1
                setLazyParamsRec(lazyParamsRec)
                loadLazyDataRec()
                setDialogRec(false);
                setSelectedRec(emptyRec);
                //toast.current.show({severity: 'success', summary: 'Successful', detail: 'Le recensement a été crée avec succès', life: 3000});
                form.reset()
            }).catch(error => {
                //toast.current.show({severity: 'error', summary: 'ERROR', detail: error.toString(), life: 3000});
            })
        }
    }

    const hideDialogRec = () => {
        setSubmittedRec(false);
        setDialogRec(false);
    }

    const hideDeleteDialogRec = () => {
        setDeleteRecordDialogRec(false);
    }
    const confirmDeleteRec = () => {
        setSelectedRec(selectedRec);
        setDeleteRecordDialogRec(true);
    }

    const deleteRec = () => {
        recService.deleteOne(selectedRec.id, props.authToken).then((res) => {

            if (res.error) {
                setDeleteRecordDialogRec(false);
                //toast.current.show({severity: 'error', summary: 'Opération annulé', detail: res.error, life: 3000});
            } else {
                let _sitesList = recList.filter(val => val.id !== selectedRec.id);
                setRecList(_sitesList);
                setDeleteRecordDialogRec(false);
                setSelectedRec(emptyRec);
                //toast.current.show({severity: 'success', summary: 'Successful', detail: "L'enregistrement a bien été supprimé", life: 3000});
            }

        }).catch(error => {


        })
    }
    const filterByYear = async (options, value) => {
        return options.filterCallback(value);
    }

    const dateBodyTemplate = (rowData) => {
        return formatDate(rowData.date);
    }


    const siteFilterTemplate = (options) => {
        return <Dropdown
            id="site"
            options={siteComboItems}
            optionLabel="site_nom"
            filter
            showClear
            filterBy="site_nom"
            placeholder="List sites"
            emptyMessage="Pas d'enregistrement"
            value={options.value}
            onChange={(e) => options.filterCallback(e.value)}
        />
    }


    const searchObservateurs = (event) => {
        setTimeout(() => {
            let _filteredObservateurs;
            if (!event.query.trim().length) {
                _filteredObservateurs = [...observateurComboItems];
            } else {
                _filteredObservateurs = observateurComboItems.filter((obs) => {
                    return obs.nom_complet.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }

            setFilteredObservateus(_filteredObservateurs);
        }, 250);
    }


    const yearRecFilterTemplate = (options) => {
        return <Dropdown
            id="year"
            options={yearRecComboItems}
            optionLabel="year"
            filter
            showClear
            filterBy="year"
            placeholder="Année de visite"
            emptyMessage="Pas d'enregistrement"
            value={options.value}
            onChange={(e) => options.filterCallback(e.value)}
        />
    }

    const searchSourceDerangement = (event) => {
        setTimeout(() => {
            let _filteredSourceDerangement;
            if (!event.query.trim().length) {
                _filteredSourceDerangement = [...sourceDerangementsComboItems];
            } else {
                _filteredSourceDerangement = sourceDerangementsComboItems.filter((obs) => {
                    return obs.nom.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }

            setFilteredSourceDerangements(_filteredSourceDerangement);
        }, 250);
    }


    const recDialogFooter = (<>
        <Button label="Annuler" icon="pi pi-times" className="p-button-text" onClick={hideDialogRec}/>
        <Button label="Enregistrer" icon="pi pi-check" type="submit" form="recForm"/>
    </>);
    const recDeleteDialogFooter = (<>
        <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDialogRec}/>
        <Button label="Oui" icon="pi pi-check" className="p-button-text" onClick={deleteRec}/>
    </>);


    //-------------------------------------
    //-----RES-----------------------------
    //-------------------------------------

    const loadLazyDataRes = () => {

        setLoadingRes(true);
        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }
        loadLazyTimeout = setTimeout(() => {
            resService.getList(lazyParamsRes, props.authToken).then(data => {
                setTotalRecordsRes(data.count);
                setResList(data.results);
                setLoadingRes(false);
            });
        }, Math.random() * 1000 + 250);
    }

    const onPageRes = (event) => {
        setRowsRes(event.rows);
        setLazyParamsRes(event);
        event['page'] = event['page'] + 1
    }

    const onSortRes = (event) => {
        setLazyParamsRes(event);
    }

    const onFilterRes = (event) => {
        setSelectedRes(emptyRes)
        const espece_id = event.filters.espece.value?.id
        event.filters.espece.value = espece_id ? `${espece_id}` : ''
        event['first'] = 1;
        setLazyParamsRes(event);
    }

    const onResSelectionChange = (event) => {
        const value = event.value;
        setSelectedRes(value);
        setSelectedEspece(event.value.especenat)
    }

    /*const newRes = () => {
        setDialogTitleRes('Nouveau résultat de recensement')
        setSelectedRes(emptyRes);
        setSubmittedRes(false);
        setRecordDialogRes(true);
    }*/

    const validateRes = (data) => {
        let errors = {};
        if (!data.especenat) {
            errors.especenat = "L'espèce est obligatoire";
        }
        if (!data.effectif) {
            errors.effectif = "L'effectif est obligatoire";
        }
        if (!data.recensqual) {
            errors.recensqual = "La qualité est obligatoire";
        }
        return errors;
    };

    const editRes = (selectedRow) => {
        const _selectedRow = {...selectedRow}
        setDialogTitleRes(selectedRow.id === null ? 'Nouveau résultat de recensement' : `Edition résultat de recensement: (${_selectedRow.especenat.nom})`)
        setSelectedRes(_selectedRow);
        setRecordDialogRes(true);
    }
    const saveRes = async (data, form) => {
        setSubmittedRes(true);
        let _recordList = [...resList];
        let _record = {...data}
        let newObject = {...data}
        newObject.recensement_id = selectedRec.id


        newObject.especenat_id = data.especenat?.id
        newObject.recensqual_id = data.recensqual?.id

        delete newObject.especenat
        delete newObject.recensqual

        if (newObject.id) {
            const index = findIndexById(_recordList, newObject.id);
            resService.updateOne(newObject, props.authToken).then((res) => {
                _recordList[index] = _record;
                setResList(_recordList);
                setRecordDialogRes(false);
                setSelectedRes(emptyRes);
                //toast.current.show({severity: 'success', summary: 'Successful', detail: 'Le résultat de recensement a été mis a jour avec succés', life: 3000});
                form.reset()
            }).catch(error => {


            })
        } else {
            resService.addOne(newObject, props.authToken).then((res) => {
                lazyParamsRes.sortField = 'id';
                lazyParamsRes.sortOrder = -1
                setLazyParamsRes(lazyParamsRes)
                loadLazyDataRes()
                setRecordDialogRes(false);
                setSelectedRes(emptyRes);
                //toast.current.show({severity: 'success', summary: 'Successful', detail: 'Le résultat recensement a été crée avec succès', life: 3000});
                form.reset()
            }).catch(error => {
                //toast.current.show({severity: 'error', summary: 'ERROR', detail: error.toString(), life: 3000});
            })
        }
    }
    const hideDialogRes = () => {
        setSubmittedRes(false);
        setRecordDialogRes(false);
    }

    const hideDeleteDialogRes = () => {
        setDeleteRecordDialogRes(false);
    }
    const confirmDeleteRes = () => {
        setSelectedRes(selectedRes);
        setDeleteRecordDialogRes(true);
    }

    const deleteRes = () => {
        resService.deleteOne(selectedRes.id, props.authToken).then((res) => {

            if (res.error) {
                setDeleteRecordDialogRes(false);
                //toast.current.show({severity: 'error', summary: 'Opération annulé', detail: res.error, life: 3000});
            } else {
                let _resList = resList.filter(val => val.id !== selectedRes.id);
                setResList(_resList);
                setDeleteRecordDialogRes(false);
                setSelectedRes(emptyRes);
                //toast.current.show({severity: 'success', summary: 'Successful', detail: "L'enregistrement a bien été supprimé", life: 3000});
            }

        }).catch(error => {


        })
    }


    const especeResFilterTemplate = (options) => {
        return <Dropdown
            id="espece"
            options={especeResComboItems}
            optionLabel="espece"
            filter
            showClear
            filterBy="espece"
            placeholder="List des espèces"
            emptyMessage="Pas d'enregistrement"
            value={options.value}
            onChange={(e) => options.filterCallback(e.value)}
        />
    }

    const searchEspeces = (event) => {
        setTimeout(() => {
            let _filteredEspeces;
            if (!event.query.trim().length) {
                _filteredEspeces = [...especeResComboItems];
            } else {
                _filteredEspeces = especeResComboItems.filter((obs) => {
                    return obs.espece.toLowerCase().includes(event.query.toLowerCase());
                });
            }

            setFilteredEspeces(_filteredEspeces);
        }, 250);
    }


    const resDialogFooter = (<>
        <Button label="Annuler" icon="pi pi-times" className="p-button-text" onClick={hideDialogRes}/>
        <Button label="Enregistrer" icon="pi pi-check" type="submit" form="resForm"/>
    </>);
    const resDeleteDialogFooter = (<>
        <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDialogRes}/>
        <Button label="Oui" icon="pi pi-check" className="p-button-text" onClick={deleteRes}/>
    </>);


    //-------------------------------------
    //-----COMMUNS-------------------------
    //-------------------------------------

    const filterClearTemplate = (options) => {
        return <Button type="button" icon="pi pi-times" onClick={options.filterClearCallback} className="p-button-secondary"></Button>;
    }
    const filterApplyTemplate = (options) => {
        return <Button type="button" icon="pi pi-check" onClick={options.filterApplyCallback} className="p-button-success"></Button>
    }


    //-------------------------------------
    //-----TEMPLATE-------------------------
    //-------------------------------------

    const textEditor = (options) => {
        return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)}/>;
    }

    const especeRef = useRef();
    const effectifRef = useRef();
    const observationInputRef = useRef();
    const btnSaveRef = useRef();

    const filterAndFocusNextInput = useCallback(event => {
        if (event.originalEvent.code === 'Enter') {
            let espece_id = event.value?.id
            espece_id = espece_id ? `${espece_id}` : '';
            const lazyparam = {
                first: 0, rows: 50, page: 0, sortField: null, sortOrder: null, filters: {
                    'rec_id': {value: selectedRec.id, matchMode: 'contains'}, 'espece': {value: espece_id, matchMode: 'contains'}, 'global': {value: '', matchMode: 'contains'},
                }
            }
            setLazyParamsRes(lazyparam)
            setTimeout(() => {
                effectifRef.current.focus()
            }, 600);
        }
        setSelectedEspece(event.value)
    }, [])


    const addObservation = async () => {
        await setShowObservationInput(!showObservationInput)
        if (showObservationInput === false) {
            observationInputRef.current.focus();
        }
    }


    const resHeaderForm = (<div className="table-header">
        <Form
            onSubmit={saveRes}
            initialValues={selectedRes}
            validate={validateRes}
            render={({
                         errors, handleSubmit, touched
                     }) => (<form
                onSubmit={handleSubmit}
                className="p-fluid"
                id="resForm">
                <div className="col-12">
                    <div className="p-fluid formgrid grid">
                        <Field
                            name="especenat"
                            validate={composeValidators(required('espece'))}
                            render={({input, meta}) => (<div className="field col-12 md:col-4">
                                <label htmlFor="especenat" className={classNames({'p-error': isFormFieldValid(meta)})}>Espèce *</label>
                                <AutoComplete
                                    tabIndex={1}
                                    ref={ref => especeRef}
                                    id="especenat"
                                    field="espece"
                                    {...input}
                                    showClear
                                    forceSelection
                                    autoHighlight
                                    dropdownAutoFocus
                                    autoFocus
                                    removeIcon={true}
                                    placeholder="Liste des espèces..."
                                    suggestions={filteredEspeces}
                                    completeMethod={searchEspeces}
                                    //onKeyUp={e => filterAndFocusNextInput(e)}
                                    //onSelect={e => filterAndFocusNextInput(e)}
                                    onChange={(e) => filterAndFocusNextInput(e)}

                                    //className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                    value={selectedEspece}
                                />
                                {getFormErrorMessage(meta)}
                            </div>)}/>

                        <Field parse={identity}
                               name="effectif"
                               validate={composeValidators(required("effectif"), maxLength(4, "effectif"), mustBeNumber("effectif"))}
                               render={({input, meta}) => (<div className="field col-12 md:col-1">
                                   <label htmlFor="effectif" className={classNames({'p-error': isFormFieldValid(meta)})}>Effectif*</label>
                                   <InputText
                                       tabIndex={2}
                                       ref={effectifRef}
                                       id="effectif"
                                       {...input}
                                       onKeyUp={e => focusNextInputByRef(e, btnSaveRef)}
                                       className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                       type="number"
                                   />
                                   {getFormErrorMessage(meta)}
                               </div>)}/>

                        <Field
                            name="recensqual"
                            render={({input, meta}) => (<div className="field col-12 md:col-2">
                                <label htmlFor="recensqual">Qualité *</label>
                                <Dropdown
                                    tabIndex={3}
                                    id="recensqual"
                                    {...input}
                                    options={qualitesComboItems}
                                    optionLabel="libelle"
                                    filter
                                    filterBy="libelle"
                                    placeholder="Qualités..."
                                    emptyMessage="Pas d'enregistrement"
                                    onKeyUp={e => focusNextInputByRef(e, btnSaveRef)}
                                />
                            </div>)}/>
                        <div className="field col-6 md:col-2 mt-4">
                            <Button tabIndex={4} type='button' label="Observation" className="p-button-warning" onClick={addObservation}/>
                        </div>
                        {/*<div className="field col-6 md:col-1 mt-4">
                            <Button tabIndex={5} type='button' label="Bagues" className="p-button-info" onClick={addObservation}/>
                        </div>*/}
                        <div className="field col-6 md:col-2 mt-4">
                            <Button tabIndex={6} ref={btnSaveRef} type='submit' label="Enregistrer" icon="pi pi-check"/>
                        </div>

                        {showObservationInput && <Field
                            parse={identity}
                            name="note"
                            render={({input, meta}) => (<div className="field col-12 md:col-12">
                                <label htmlFor="note" className={classNames({'p-error': isFormFieldValid(meta)})}>Observation</label>
                                <InputTextarea
                                    tabIndex={7}
                                    ref={observationInputRef}
                                    {...input}
                                    id="observation"
                                    rows={3}
                                    cols={20}
                                />
                                {getFormErrorMessage(meta)}
                            </div>)}/>}


                    </div>
                    {/*<div className="col-12">
                                <ErrorMessageTemplate touched={touched} errors={errors}/>
                            </div>*/}
                </div>
            </form>)}/>
    </div>);

    return (<div className="card" style={{height: 'calc(100vh - 137px)'}}>
        <div className="grid">
            <div className="col-5" style={{height: 'calc(100vh - 235px)'}}>
                <Toolbar
                    className="mb-4"
                    left={<LeftToolbarTemplate
                        addNew={newRec}
                        confirmDelete={confirmDeleteRec}
                        disabled={selectedRec.id === null}
                        clearFilters={clearFilterRec}
                    />}
                    right={<RightToolbarTemplate dt={dtRec}/>}/>
                <DataTable
                    ref={dtRec}
                    scrollable
                    scrollHeight="flex"
                    size="small"
                    responsiveLayout="scroll"
                    loading={loadingRec}
                    value={recList}
                    dataKey="id"
                    lazy
                    rows={rowsRec}
                    paginator first={lazyParamsRec.first}
                    rowsPerPageOptions={[10, 20, 50]}
                    filterDisplay="menu"
                    filters={lazyParamsRec.filters}
                    sortField={lazyParamsRec.sortField}
                    totalRecords={totalRecordsRec}
                    selection={selectedRec}
                    selectionMode="single"
                    sortOrder={lazyParamsRec.sortOrder}
                    onSort={onSortRec}
                    onFilter={onFilterRec}
                    onPage={onPageRec}
                    globalFilter={globalFilterRec} emptyMessage="Pas de recensement trouvé"
                    onSelectionChange={onRecSelectionChange}
                >
                    <Column field="site.site_nom"
                            header="Site"
                            sortable
                            sortField="site"
                            style={{flexGrow: 1, flexBasis: '40%'}}
                            filter
                            filterField="site"
                            showFilterMenu={true}
                            showFilterMatchModes={false}
                            filterMenuStyle={{width: '14rem'}}
                            filterElement={siteFilterTemplate}
                            filterApply={filterApplyTemplate}
                            filterClear={filterClearTemplate}
                    />
                    <Column field="date"
                            header="Date"
                            sortable
                            dataType="date"
                            body={dateBodyTemplate}
                            style={{minWidth: '11rem'}}
                            filter
                            filterField="date"
                            showFilterMenu={true}
                            showFilterMatchModes={false}
                            filterMenuStyle={{width: '14rem'}}
                            filterElement={yearRecFilterTemplate}
                            filterApply={filterApplyTemplate}
                            filterClear={filterClearTemplate}
                    />
                    <Column
                        body={(rowData) => <ActionBodyTemplate
                            data={rowData}
                            editRecord={editRec}
                            deleteRecord={confirmDeleteRec}
                        />}
                        style={{flexGrow: 1, flexBasis: '8%'}}
                    />
                </DataTable>
            </div>
            <div className="col-7" style={{height: 'calc(100vh - 150px)'}}>
                <DataTable
                    ref={dtRes}
                    scrollable
                    scrollHeight="flex"
                    size="small"
                    responsiveLayout="scroll"
                    loading={loadingRes}
                    value={resList}
                    dataKey="id"
                    lazy
                    header={selectedRec && selectedRec.id !== null ? resHeaderForm : null}
                    rows={rowsRes}
                    paginator first={lazyParamsRes.first}
                    rowsPerPageOptions={[10, 20, 50]}
                    filterDisplay="menu"
                    filters={lazyParamsRes.filters}
                    sortField={lazyParamsRes.sortField}
                    totalRecords={totalRecordsRes}
                    selection={selectedRes}
                    selectionMode="single"
                    sortOrder={lazyParamsRes.sortOrder}
                    onSort={onSortRes}
                    //onFilter={onFilterRes}
                    onPage={onPageRes}
                    selectionMode="single"
                    globalFilter={globalFilterRes}
                    emptyMessage="Pas de résultat de recensement trouvé"
                    onSelectionChange={onResSelectionChange}
                    rowExpansionTemplate={(rowData) => <ResRowExpansionTemplate data={rowData}/>}
                    expandedRows={expandedRowsRes} onRowToggle={(e) => setExpandedRowsRes(e.data)}
                >
                    <Column expander style={{width: '3em'}}/>
                    <Column field="especenat.espece"
                            header="Espèce"
                            sortable
                            style={{minWidth: '27em'}}
                    />
                    <Column field="effectif"
                            header="Effectif"
                            sortable
                            dataType="number"
                            editor={(options) => textEditor(options)}
                            style={{width: '20%', minWidth: '10em', textAlign: 'center'}}
                    />
                    <Column field="recensqual.libelle"
                            header="Qualité"
                            sortable
                            style={{minWidth: '10rem'}}
                    />

                    <Column field="bague_count"
                            header="Bagues"
                            sortable
                        //className="p-align-center"
                            style={{minWidth: '10rem'}}
                    />
                    <Column
                        body={(rowData) => <ActionBodyTemplate
                            data={rowData}
                            //editRecord={editRes}
                            deleteRecord={confirmDeleteRes}
                        />}
                        style={{flexGrow: 1, flexBasis: '1%'}}
                    />
                </DataTable>
                {/*--REC FORM------------------------------------------*/}
                {/*----------------------------------------------------*/}


                <Form
                    onSubmit={saveRec}
                    initialValues={selectedRec}
                    validate={validateRec}
                    render={({
                                 errors, handleSubmit, touched,
                             }) => (<form
                        onSubmit={handleSubmit}
                        className="p-fluid"
                        id="recForm">
                        <Dialog
                            modal
                            position='top'
                            draggable={false}
                            maximizable
                            style={{width: '50vw'}}
                            className="p-fluid"
                            visible={recordDialogRec}
                            header={dialogTitleRec}
                            footer={recDialogFooter}
                            onHide={hideDialogRec}
                        >
                            <div className="col-12">
                                <div className="p-fluid formgrid grid">
                                    <Field
                                        name="site"
                                        validate={composeValidators(required('site'))}
                                        render={({input, meta}) => (<div className="field col-12 md:col-6">
                                            <label htmlFor="site" className={classNames({'p-error': isFormFieldValid(meta)})}>Site *</label>
                                            <Dropdown
                                                id="site"
                                                {...input}
                                                options={siteComboItems}
                                                optionLabel="site_nom"
                                                filter
                                                showClear
                                                filterBy="site_nom"
                                                placeholder="List sites"
                                                emptyMessage="Pas d'enregistrement"
                                            />
                                            {getFormErrorMessage(meta)}
                                        </div>)}/>
                                    <Field
                                        format={getNewDate}
                                        name="date"
                                        render={({input, meta}) => (<div className="field col-12 md:col-2">
                                            <label htmlFor="date" className={classNames({'p-error': isFormFieldValid(meta)})}>Date *</label>
                                            <Calendar
                                                id="date"
                                                showIcon
                                                {...input}
                                                mask="99/99/9999"
                                                dateFormat="dd/mm/yy"
                                            />
                                        </div>)}/>
                                    <Field
                                        format={getNewDate}
                                        name="heurdebut"
                                        validate={composeValidators(required('heurdebut'))}
                                        render={({input, meta}) => (<div className="field col-12 md:col-2">
                                            <label htmlFor="heurdebut" className={classNames({'p-error': isFormFieldValid(meta)})}>Heur début *</label>
                                            <Calendar
                                                id="heurdebut"
                                                {...input}
                                                className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                                showIcon
                                                icon={<i className="pi pi-clock"/>}
                                                timeOnly hourFormat="24"
                                            />
                                            {getFormErrorMessage(meta)}
                                        </div>)}/>
                                    <Field
                                        format={getNewDate}
                                        name="heurfin"
                                        validate={composeValidators(required('heurfin'))}
                                        render={({input, meta}) => (<div className="field col-12 md:col-2">
                                            <label htmlFor="heurfin" className={classNames({'p-error': isFormFieldValid(meta)})}>Heur fin*</label>
                                            <Calendar
                                                id="heurfin"
                                                {...input}
                                                className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                                showIcon
                                                icon={<i className="pi pi-clock"/>}
                                                timeOnly hourFormat="24"
                                            />
                                            {getFormErrorMessage(meta)}
                                        </div>)}/>

                                    <Field parse={identity}
                                           name="groupe"
                                           validate={composeValidators(required('observateurs'))}
                                           render={({input, meta}) => (<div className="field col-12 md:col-12">
                                               <label htmlFor="groupe" className={classNames({'p-error': isFormFieldValid(meta)})}>Observateurs*</label>

                                               <AutoComplete
                                                   id="groupe"
                                                   field="nom_complet"
                                                   {...input}
                                                   dropdown
                                                   multiple
                                                   forceSelection
                                                   dropdownMode={'current'}
                                                   suggestions={filteredObservateus}
                                                   completeMethod={searchObservateurs}
                                                   className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                               />
                                               {getFormErrorMessage(meta)}
                                           </div>)}/>
                                    <Field
                                        name="recencouvr"
                                        render={({input, meta}) => (<div className="field col-12 md:col-6">
                                            <label htmlFor="recencouvr" className={classNames({'p-error': isFormFieldValid(meta)})}>Couverture *</label>
                                            <Dropdown
                                                id="recencouvr"
                                                {...input}
                                                options={couverturesComboItems}
                                                optionLabel="libelle"
                                                filter
                                                showClear
                                                filterBy="libelle"
                                                placeholder="List couvertures"
                                                emptyMessage="Pas d'enregistrement"
                                            />
                                            {getFormErrorMessage(meta)}
                                        </div>)}/>
                                    <Field
                                        name="recensqual"
                                        render={({input, meta}) => (<div className="field col-12 md:col-6">
                                            <label htmlFor="recensqual" className={classNames({'p-error': isFormFieldValid(meta)})}>Qualité *</label>
                                            <Dropdown
                                                id="recensqual"
                                                {...input}
                                                options={qualitesComboItems}
                                                optionLabel="libelle"
                                                filter
                                                showClear
                                                filterBy="libelle"
                                                placeholder="List qualités"
                                                emptyMessage="Pas d'enregistrement"
                                            />
                                            {getFormErrorMessage(meta)}
                                        </div>)}/>
                                    <Field
                                        name="typemeth"
                                        render={({input, meta}) => (<div className="field col-12 md:col-6">
                                            <label htmlFor="typemeth" className={classNames({'p-error': isFormFieldValid(meta)})}>Méthode *</label>
                                            <Dropdown
                                                id="typemeth"
                                                {...input}
                                                options={typeMethodesComboItems}
                                                optionLabel="nom"
                                                filter
                                                showClear
                                                filterBy="nom"
                                                placeholder="List type méthode"
                                                emptyMessage="Pas d'enregistrement"
                                            />
                                            {getFormErrorMessage(meta)}
                                        </div>)}/>
                                    <Field
                                        name="typemarl"
                                        render={({input, meta}) => (<div className="field col-12 md:col-6">
                                            <label htmlFor="typemarl" className={classNames({'p-error': isFormFieldValid(meta)})}>Matériel *</label>
                                            <Dropdown
                                                id="typemarl"
                                                {...input}
                                                options={materielsComboItems}
                                                optionLabel="nom"
                                                filter
                                                showClear
                                                filterBy="nom"
                                                placeholder="List matériel"
                                                emptyMessage="Pas d'enregistrement"
                                            />
                                            {getFormErrorMessage(meta)}
                                        </div>)}/>
                                    <Field
                                        name="receninnond"
                                        render={({input, meta}) => (<div className="field col-12 md:col-6">
                                            <label htmlFor="receninnond" className={classNames({'p-error': isFormFieldValid(meta)})}>Innondation *</label>
                                            <Dropdown
                                                id="receninnond"
                                                {...input}
                                                options={innondationsComboItems}
                                                optionLabel="libelle"
                                                filter
                                                showClear
                                                filterBy="libelle"
                                                placeholder="List innondation"
                                                emptyMessage="Pas d'enregistrement"
                                            />
                                            {getFormErrorMessage(meta)}
                                        </div>)}/>
                                    <Field
                                        name="recengel"
                                        render={({input, meta}) => (<div className="field col-12 md:col-6">
                                            <label htmlFor="recengel" className={classNames({'p-error': isFormFieldValid(meta)})}>Superfaces gelée*</label>
                                            <Dropdown
                                                id="recengel"
                                                {...input}
                                                options={gelsComboItems}
                                                optionLabel="libelle"
                                                filter
                                                showClear
                                                filterBy="libelle"
                                                placeholder="List gel"
                                                emptyMessage="Pas d'enregistrement"
                                            />
                                            {getFormErrorMessage(meta)}
                                        </div>)}/>

                                    <Field
                                        name="maree"
                                        render={({input, meta}) => (<div className="field col-12 md:col-6">
                                            <label htmlFor="maree" className={classNames({'p-error': isFormFieldValid(meta)})}>Marée*</label>
                                            <Dropdown
                                                id="maree"
                                                {...input}
                                                options={gelsComboItems}
                                                optionLabel="libelle"
                                                filter
                                                showClear
                                                filterBy="libelle"
                                                placeholder="List marée"
                                                emptyMessage="Pas d'enregistrement"
                                            />
                                            {getFormErrorMessage(meta)}
                                        </div>)}/>

                                    <Field
                                        name="recenmeteo"
                                        render={({input, meta}) => (<div className="field col-12 md:col-6">
                                            <label
                                                htmlFor="recenmeteo"
                                                className={classNames({'p-error': isFormFieldValid(meta)})}>
                                                Temps/ Visibilité *
                                            </label>
                                            <Dropdown
                                                id="recenmeteo"
                                                {...input}
                                                options={gelsComboItems}
                                                optionLabel="libelle"
                                                filter
                                                showClear
                                                filterBy="libelle"
                                                placeholder="List temps/ visibilité"
                                                emptyMessage="Pas d'enregistrement"
                                            />
                                            {getFormErrorMessage(meta)}
                                        </div>)}/>

                                    <Field
                                        name="typederg"
                                        render={({input, meta}) => (<div className="field col-12 md:col-6">
                                            <label
                                                htmlFor="typederg"
                                                className={classNames({'p-error': isFormFieldValid(meta)})}>
                                                Dérangements Intensité*
                                            </label>
                                            <Dropdown
                                                id="typederg"
                                                {...input}
                                                options={typeDerangementsComboItems}
                                                optionLabel="nom"
                                                filter
                                                showClear
                                                filterBy="nom"
                                                placeholder="List Type dérangements"
                                                emptyMessage="Pas d'enregistrement"
                                            />
                                            {getFormErrorMessage(meta)}
                                        </div>)}/>
                                    <Field parse={identity}
                                           name="sourcederg"
                                           validate={composeValidators(//required('sourcederg')
                                           )}
                                           render={({input, meta}) => (<div className="field col-12 md:col-12">
                                               <label htmlFor="sourcederg"
                                                      className={classNames({'p-error': isFormFieldValid(meta)})}>Source dérangmant*</label>

                                               <AutoComplete
                                                   id="sourcederg"
                                                   field="nom"
                                                   {...input}
                                                   dropdown
                                                   multiple
                                                   forceSelection
                                                   dropdownMode={'current'}
                                                   suggestions={filteredSourceDerangements}
                                                   completeMethod={searchSourceDerangement}
                                                   className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                               />
                                               {getFormErrorMessage(meta)}
                                           </div>)}/>
                                    <Field
                                        parse={identity}
                                        name="observation"
                                        render={({input, meta}) => (<div className="field col-12 md:col-12">
                                            <label htmlFor="observation" className={classNames({'p-error': isFormFieldValid(meta)})}>Observation</label>
                                            <InputTextarea
                                                {...input}
                                                id="observation"
                                                rows={3}
                                                cols={20}
                                            />
                                            {getFormErrorMessage(meta)}
                                        </div>)}/>
                                </div>
                                <div className="col-12">
                                    <ErrorMessageTemplate touched={touched} errors={errors}/>
                                </div>
                            </div>

                        </Dialog>

                    </form>)}/>

                <Dialog visible={deleteRecordDialogRec} style={{width: '450px'}} header="Confirm" modal footer={recDeleteDialogFooter} onHide={hideDeleteDialogRes}>
                    <div className="flex align-items-center justify-content-center">
                        <i className="pi pi-exclamation-triangle mr-3" style={{fontSize: '2rem'}}/>
                        {selectedRes && <span>Etes-vous sûr que vous voulez supprimer?</span>}
                    </div>
                </Dialog>
                {/*--RES FORM-------------------------------------------------------------------------------------------*/}
                {/*--RES FORM-------------------------------------------------------------------------------------------*/}
                {/*<Form
                        onSubmit={saveRes}
                        initialValues={selectedRes}
                        validate={validateRes}
                        render={({
                                     errors,
                                     handleSubmit,
                                     touched,
                                 }) => (
                            <form
                                onSubmit={handleSubmit}
                                className="p-fluid"
                                id="resForm">
                                <Dialog
                                    modal
                                    position='top'
                                    draggable={true}
                                    maximizable
                                    style={{width: '50vw'}}
                                    className="p-fluid"
                                    visible={recordDialogRes}
                                    header={dialogTitleRes}
                                    footer={resDialogFooter}
                                    onHide={hideDialogRes}
                                >
                                    <div className="col-12">
                                        <div className="p-fluid formgrid grid">
                                            <Field
                                                name="especenat"
                                                validate={
                                                    composeValidators(
                                                        required('espece')
                                                    )}
                                                render={({input, meta}) => (
                                                    <div className="field col-12 md:col-4">
                                                        <label htmlFor="especenat" className={classNames({'p-error': isFormFieldValid(meta)})}>Espèce *</label>
                                                        <Dropdown
                                                            id="especenat"
                                                            {...input}
                                                            options={especeResComboItems}
                                                            optionLabel="espece"
                                                            filter
                                                            showClear
                                                            filterBy="espece"
                                                            placeholder="List espèces"
                                                            emptyMessage="Pas d'enregistrement"
                                                        />
                                                        {getFormErrorMessage(meta)}
                                                    </div>
                                                )}/>

                                            <Field parse={identity}
                                                   name="effectif"
                                                   validate={
                                                       composeValidators(
                                                           required("effectif"),
                                                           maxLength(4, "abbreviation"),
                                                       )}
                                                   render={({input, meta}) => (
                                                       <div className="field col-12 md:col-2">
                                                           <label htmlFor="effectif" className={classNames({'p-error': isFormFieldValid(meta)})}>Effectif*</label>
                                                           <InputText
                                                               id="effectif"
                                                               {...input}
                                                               className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                                           />
                                                           {getFormErrorMessage(meta)}
                                                       </div>
                                                   )}/>

                                            <Field
                                                name="recensqual"
                                                render={({input, meta}) => (
                                                    <div className="field col-12 md:col-6">
                                                        <label htmlFor="recensqual" className={classNames({'p-error': isFormFieldValid(meta)})}>Qualité *</label>
                                                        <Dropdown
                                                            id="recensqual"
                                                            {...input}
                                                            options={qualitesComboItems}
                                                            optionLabel="libelle"
                                                            filter
                                                            showClear
                                                            filterBy="libelle"
                                                            placeholder="List qualités"
                                                            emptyMessage="Pas d'enregistrement"
                                                        />
                                                        {getFormErrorMessage(meta)}
                                                    </div>
                                                )}/>
                                            <Field
                                                parse={identity}
                                                name="note"
                                                render={({input, meta}) => (
                                                    <div className="field col-12 md:col-12">
                                                        <label htmlFor="note" className={classNames({'p-error': isFormFieldValid(meta)})}>Observation</label>
                                                        <InputTextarea
                                                            {...input}
                                                            id="observation"
                                                            rows={3}
                                                            cols={20}
                                                        />
                                                        {getFormErrorMessage(meta)}
                                                    </div>
                                                )}/>


                                        </div>
                                        <div className="col-12">
                                            <ErrorMessageTemplate touched={touched} errors={errors}/>
                                        </div>
                                    </div>

                                </Dialog>

                            </form>
                        )}/>*/}

                <Dialog visible={deleteRecordDialogRes} style={{width: '450px'}} header="Confirm" modal footer={resDeleteDialogFooter} onHide={hideDeleteDialogRes}>
                    <div className="flex align-items-center justify-content-center">
                        <i className="pi pi-exclamation-triangle mr-3" style={{fontSize: '2rem'}}/>
                        {selectedRec && <span>Etes-vous sûr que vous voulez supprimer<b></b>?</span>}
                    </div>
                </Dialog>


                {/*--RES FORM------------------------------------------*/}
                {/*----------------------------------------------------*/}


            </div>
        </div>
    </div>);
}


const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};


const mapStateToProps = state => {
    return {
        authToken: state.userReducer.authToken,
        isLoggedIn: state.userReducer.isLoggedIn,
    };
};
export default connect(mapStateToProps, {Login})(React.memo(RecensementPage, comparisonFn))
