import React from 'react';
import {Button} from "primereact/button";

const DialogFooterTemplate = (props) => {
    return (
        <React.Fragment>
            <Button label="Annuler" icon="pi pi-times" className="p-button-text" onClick={props.hideDialog}/>
            <Button label="Enregistrer" icon="pi pi-check" type="submit" form={props.form}/>
        </React.Fragment>
    );
}

export default DialogFooterTemplate

