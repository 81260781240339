import {apiClient} from '../api/ApiClient';
import {REDUX_CONST} from "../config/constats";
import {getComboOfRecYears} from "./recensement/RecencementActions";
//import {loadDecoupageAdmin} from "../pages/sites2/redux/old/DecoupageAdminActions";

export const setGlobaleToastRef = (ref) => {
    return async dispatch => {
        await dispatch({
            type: REDUX_CONST.SET_GLOBALE_TOAST,
            payload: ref,
        });
    };
};

export const Init = () => {
    return async dispatch => {
        let token = localStorage.getItem('token');

        if (token !== null && token !== undefined) {
            await dispatch({
                type: REDUX_CONST.LOGIN, payload: token,
            });
            await dispatch(getComboOfRecYears());
            //await dispatch(loadDecoupageAdmin());
        }
    };
};

export const Login = (username, password) => {
    return async dispatch => {
        let data = {};
        if (username !== null && password != null) {
            await dispatch({
                type: REDUX_CONST.LOGIN_ERRORS, payload: {
                    status: false, message: '', loading: true,
                },
            });

            const body = JSON.stringify({
                username: username, password: password,
            });

            await apiClient()
                .post('token/', body)
                .then(async res => {


                    if (res.ok) {
                        data.token = res.data.access;
                        localStorage.setItem('token', data.token);
                        await dispatch({
                            type: REDUX_CONST.LOGIN, payload: data,
                        });
                        await dispatch(Init());
                    } else if (res.data && res.data.status === 'error') {
                        await dispatch({
                            type: REDUX_CONST.LOGIN_ERRORS, payload: {
                                status: true, message: res.data.message, loading: false,
                            },
                        });
                    }
                })
                .catch(error => {
                });
        }
    };
};

export const Logout = () => {
    return async dispatch => {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        await dispatch({
            type: REDUX_CONST.LOGOUT,
        });

        /*await apiClient()
            .post('logout')
            .then(async res => {
                if (res.ok) {
                    await dispatch({
                        type: REDUX_CONST.LOGOUT,
                    });
                }
            })
            .catch(error => {
            });*/
    };
};
