import { REDUX_CONST } from "../../../config/constats";
import Store from "../../Store";
import { constructFilter, getGlobaleToast } from "../../../utils";
import { apiClient } from "../../../api/ApiClient";

const endpoint = "recensements/upload";

export const showNetworkLoading = (payload) => {
    return async (dispatch) => {
        await dispatch({
            type: REDUX_CONST.REC_IMPORTATION.LOADING,
            payload: payload,
        });
    };
};

export const showNetworkLoadingValidationProcess = (payload) => {
    return async (dispatch) => {
        await dispatch({
            type: REDUX_CONST.REC_IMPORTATION.LOADING_VALIDATION_PROCESS,
            payload: payload,
        });
    };
};

export const showErrorMessage = (payload) => {
    return async (dispatch) => {
        await dispatch(showNetworkLoading(false));
        await dispatch(showNetworkLoadingValidationProcess(false));
    };
};

export const formatData = (payload) => {
    return async (dispatch) => {
        await dispatch({
            type: REDUX_CONST.REC_IMPORTATION.LIST,
            payload: payload,
        });
        if (Store.getState().importationReducer.loading) {
            await dispatch(showNetworkLoading(false));
            await dispatch(showNetworkLoadingValidationProcess(false));
        }
    };
};

export const executeSelectedImportation = (data) => {

    return async (dispatch) => {
        await dispatch(showNetworkLoading(true));
        await apiClient()
            .get(`recensements/executeimportation/${data.id}`)
            .then(async (res) => {
                if (res.ok && res.data.status==='processed') {
                    getGlobaleToast({ severity: "success", summary: "Successful", detail: "L'importation a été effectué avec succès", life: 5000 });
                } else {
                    getGlobaleToast({ severity: "error", summary: "ERROR", detail: "L'importation a échoué. Voir le rapport d'importation", life: 3000 });
                    await dispatch(showErrorMessage("ERROROROORO"));
                }
                await dispatch({
                    type: REDUX_CONST.REC_IMPORTATION.DATA_SAVED,
                    payload: {
                        data: res.data,
                        operation: "EDIT",
                    },
                });
            })
            .catch((error) => {
                if (error.response) {
                    return error.response.data;
                    //dispatch(showErrorMessage(error.response.data))
                }
            });
    };
};

export const loadRecImportationList = () => {
    return async (dispatch) => {
        let data = {};
        await dispatch(showNetworkLoading(true));

        let id = Store.getState().importationReducer.selectedRecord.id;
        let lazyParams = Store.getState().importationReducer.datatable.lazyParams;
        lazyParams.filters.id.value = `${id}`;
        let filters = constructFilter(lazyParams);

        await apiClient()
            .get(`${endpoint}/${filters}`)
            .then((res) => {
                if (res.ok) {
                    data = { ...res.data };
                    setTimeout(() => {
                        dispatch(formatData(data));
                    }, Math.random() * 1000 + 250);
                }
            })
            .catch((error) => {
                if (error.response) {
                    return error.response.data;
                    //dispatch(showErrorMessage(error.response.data))
                }
            });
    };
};

export const loadRecImportationCombo = (q = "") => {
    return async (dispatch) => {
        await apiClient()
            .get(`${endpoint}combo/?search=${q}`)
            .then(async (res) => {
                if (res.ok) {
                    await dispatch({
                        type: REDUX_CONST.REC_IMPORTATION.COMBO,
                        payload: res.data,
                    });
                }
            })
            .catch((error) => {
                if (error.response) {
                    return error.response.data;
                }
            });
    };
};

export const saveRecImportationRecord = (data) => {
    var config = {
        headers: {
            "Content-Type": 'multipart/form-data; charset=utf-8; boundary="another cool boundary";',
        },
    };
    return async (dispatch) => {
        await dispatch(showNetworkLoadingValidationProcess(true));
        var formData = new FormData();
        if (data.id) {
            formData.append("id", data.data);
            formData.append("file_name", data.file_name);
            formData.append("importation_date", data.importation_date);
            formData.append("annee_rec", data.annee_rec);
            formData.append("description", data.description);
            await apiClient()
                .patch(`${endpoint}/${data.id}/`, formData, config)
                .then(async (res) => {
                    if (res.ok) {
                        await dispatch({
                            type: REDUX_CONST.REC_IMPORTATION.DATA_SAVED,
                            payload: {
                                data: res.data,
                                operation: "EDIT",
                            },
                        });
                        getGlobaleToast({ severity: "success", summary: "Successful", detail: "L'enregistrement a été mise à jour avec succès", life: 5000 });
                    } else {
                        getGlobaleToast({ severity: "error", summary: "ERROR", detail: res.error.toString(), life: 3000 });
                        await dispatch(showErrorMessage("ERROROROORO"));
                    }
                });
        } else {
            formData = new FormData();
            formData.append("file_name", data.file_name);
            formData.append("importation_date", data.importation_date);
            formData.append("annee_rec", data.annee_rec);
            formData.append("description", data.description);

            await apiClient()
                .post(`${endpoint}/`, formData, config)
                .then(async (res) => {
                    if (res.ok) {
                        //let data = { ...res.data };
                        await dispatch({
                            type: REDUX_CONST.REC_IMPORTATION.DATA_SAVED,
                            payload: {
                                data: res.data,
                                operation: "ADD",
                            },
                        });
                        getGlobaleToast({ severity: "success", summary: "Successful", detail: "L'enregistrement a été crée avec succès", life: 5000 });
                    } else {
                        //getGlobaleToast({ severity: "error", summary: "ERROR", detail: res.error.toString(), life: 3000 });
                        //await dispatch(showErrorMessage('ERROROROORO'))
                    }
                });
        }
    };
};

export const deleteRecImportationRecord = () => {
    return async (dispatch) => {
        await dispatch(showNetworkLoadingValidationProcess(true));
        let seleected_record = Store.getState().importationReducer.selectedRecord;
        await apiClient()
            .delete(`${endpoint}/${seleected_record.id}/`)
            .then(async (res) => {
                if (res.ok) {
                    await dispatch({
                        type: REDUX_CONST.REC_IMPORTATION.DATA_SAVED,
                        payload: {
                            data: seleected_record,
                            operation: "DELETE",
                        },
                    });
                    getGlobaleToast({ severity: "warn", summary: "Suppression terminé", detail: "L'enregistrement a été supprimer avec succès", life: 3000 });
                } else {
                    getGlobaleToast({ severity: "error", summary: "ERROR", detail: res, life: 3000 });
                    await dispatch(showErrorMessage("ERROROROORO"));
                }
            });
    };
};

export const setSelectedRecImportation = (site) => {
    return async (dispatch) => {
        await dispatch({
            type: REDUX_CONST.REC_IMPORTATION.SET_SELECTED,
            payload: site,
        });
    };
};

export const addNewRecImportation = () => {
    return async (dispatch) => {
        await dispatch({
            type: REDUX_CONST.REC_IMPORTATION.ADD_NEW,
            payload: true,
        });
    };
};

export const editSelectedRecImportation = (data) => {
    console.log(data);
    return async (dispatch) => {
        await dispatch({
            type: REDUX_CONST.REC_IMPORTATION.EDIT_SELECTED,
            payload: data,
        });
    };
};

export const showDeleteSelectedRecImportation = (data) => {
    return async (dispatch) => {
        await dispatch({
            type: REDUX_CONST.REC_IMPORTATION.SHOW_DELETE_DIALOG,
            payload: {
                show: true,
                data: data,
            },
        });
    };
};

export const cancelDeleteRecImportation = () => {
    return async (dispatch) => {
        await dispatch({
            type: REDUX_CONST.REC_IMPORTATION.SHOW_DELETE_DIALOG,
            payload: {
                show: false,
                data: null,
            },
        });
    };
};

export const cancelEditRecImportation = () => {
    return async (dispatch) => {
        await dispatch({
            type: REDUX_CONST.REC_IMPORTATION.CANCEL_EDIT,
            payload: [],
        });
    };
};

export const setRecImportationLazyParams = (payload) => {
    return async (dispatch) => {
        await dispatch({
            type: REDUX_CONST.REC_IMPORTATION.SET_LAZY_PARAMS,
            payload: payload,
        });
        await dispatch(loadRecImportationList());
    };
};

export const setRecImportationGlobalFilter = (payload) => {
    return async (dispatch) => {
        await dispatch({
            type: REDUX_CONST.REC_IMPORTATION.SET_GLOBAL_FILTER,
            payload: payload,
        });
    };
};
