import React from "react";
import {connect} from "react-redux";


const SiteFloreVegetation = (props) => {
    const {selected} = props;


    return (
        <div className="surface-0 ">
            SiteFloreVegetation
        </div>
    );
}


const mapStateToProps = state => {
    return {
        selected: state.siteReducer.selectedRecord,
        //loading: state.siteDescriptionReducer.loading
    };
};

export default connect(mapStateToProps, {})(SiteFloreVegetation)


