import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {useFormik} from "formik";
import * as Yup from "yup";
import {cancelEditSiteValeur} from "../../../../redux/site/valeur/SiteValeurActions";
import {classNames} from "primereact/utils";
import {getFormikFormErrorMessage, isFormikFormFieldValid} from "../../../../utils";
import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import {AutoComplete} from "primereact/autocomplete";


const ModalValeur = (props) => {

    //const [editionMode, setEditionMode] = useState(false);
    const [filteredEspeces, setFilteredEspeces] = useState();
    const [selectedEspece, setSelectedEspece] = useState(null);


    const validationSchema = Yup.object().shape({
        synonyme: Yup.string().required("Le synonyme est obligatoire").nullable(),
    })

    const formik = useFormik({
        initialValues: {...props.selectedSiteValeur},
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if (values.code && values.nom.trim()) {
                let record = {...values}
                props.saveRecord(record)
                formik.resetForm();
            }
        }
    });


    useEffect(() => {
        console.log(props.selectedSiteValeur.synthese)
        if (props.editionMode === true) {
            //formik.initialValues = {...props.selectedSiteValeur}
        }
    }, [props.selectedSiteValeur]);


    const searchEspece = (event) => {
        setTimeout(() => {
            let _filteredEspeces;
            if (!event.query.trim().length) {
                _filteredEspeces = [...props.especeCombo];
            } else {
                _filteredEspeces = props.especeCombo.filter((espece) => {
                    return espece.espece.toLowerCase().includes(event.query.toLowerCase());
                });
            }

            setFilteredEspeces(_filteredEspeces);
        }, 250);
    }


    const ActionBtns = () => {
        return <React.Fragment>
            {props.editionMode ? (
                <>
                    <Button
                        icon="pi pi-save"
                        type="submit"
                        form="formTabSiteRechercheSc"
                        className="mr-2"/>
                    <Button
                        icon="pi pi-times"
                        className="p-button-secondary"
                        type="button"
                        //onClick={() => null}
                    />
                </>
            ) : (
                <Button
                    icon="pi pi-pencil"
                    className="p-button-warning"
                    type="button"
                    //onClick={() => null}
                />
            )}

        </React.Fragment>
    };

    return (
        <Dialog
            header={props.dialogTitle}
            closeOnEscape={true}
            closable
            modal={false}
            visible={props.editionMode}
            modal={false}
            style={{width: '25vw'}}
            footer={ActionBtns}
            onHide={() => props.cancelEditSiteValeur()}

        >
            <div className="p-fluid formgrid grid">
                {props.selectedSiteValeur.typevaleur_id === 2 || props.selectedSiteValeur.typevaleur_id === 3 ?
                    <div className="field col-12 md:col-12">
                        <h5>{props.selectedSiteValeur.typevaleur_id === 2 ? 'Flore' : 'Faune'}</h5>
                        <AutoComplete
                            id="espece"
                            field="espece"
                            dropdown
                            showClear
                            forceSelection
                            placeholder="Toutes les espèces"
                            suggestions={filteredEspeces}
                            completeMethod={searchEspece}
                            onChange={(e) => setSelectedEspece(e.value)}
                            value={selectedEspece}
                        />
                    </div> : <></>
                }
                <div className="field col-12 md:col-12">
                    <label
                        htmlFor="intitule"
                        className={classNames({'p-error': isFormikFormFieldValid(formik, "intitule")})}>
                        Intitulé *
                    </label>
                    <InputText
                        id="intitule"
                        name="intitule"
                        value={formik.values.intitule}
                        onChange={formik.handleChange}
                        autoFocus
                        className={classNames({
                            "p-invalid": isFormikFormFieldValid(formik, "intitule")
                        })}
                    />
                    {getFormikFormErrorMessage(formik, "intitule")}
                </div>
                <div className="field col-12 md:col-12">
                    <label
                        htmlFor="synthese"
                        className={classNames({'p-error': isFormikFormFieldValid(formik, "synthese")})}>
                        Synthèse *
                    </label>
                    <InputTextarea
                        id="synthese"
                        name="synthese"
                        value={formik.values.synthese}
                        onChange={formik.handleChange}
                        rows={4}
                        className={classNames({
                            "p-invalid": isFormikFormFieldValid(formik, "synthese")
                        })}
                    />
                    {getFormikFormErrorMessage(formik, "synthese")}
                </div>
            </div>

        </Dialog>
    );
}


const mapStateToProps = state => {
    return {
        dialogTitle: state.siteValeurReducer.dialogTitle,
        editionMode: state.siteValeurReducer.editionMode,
        selectedSiteValeur: state.siteValeurReducer.selectedRecord,
        especeCombo: state.especeReducer.comboList,
    };
};

export default connect(mapStateToProps, {cancelEditSiteValeur})(ModalValeur)


