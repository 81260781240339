import React from 'react';
import {Route} from "react-router-dom";
import {connect} from "react-redux";

//import EmptyPage from "../pages/EmptyPage";
import Dashboard from "../pages/Dashboard";
import RecensementPage from "../pages/recensement/RecensementPage";
import EspeceNatPage from "../pages/especes/EspeceNatPage";

//import Site2Page from "../pages/sites2/old/Site2Page";
//import SitePageOLD from "../pages/sites2/old/SitePageOLD";
import SitePage from "../pages/sites/SitePage";

import SitesMapMarkersPage from "../pages/sites/report/SitesMapMarkersPage";
import SitesMapClusteringPage from "../pages/sites/report/SitesMapClusteringPage";
import SitesMapPage from "../pages/sites/report/SitesMapPolygonsPage";


import TypeMareePage from "../pages/parametrage/TypeMareePage";
import TypemarlPage from "../pages/parametrage/TypemarlPage";
import TypeGelPage from "../pages/parametrage/TypeGelPage";
import TypeDergPage from "../pages/parametrage/TypeDergPage";
import TypeSourceDergPage from "../pages/parametrage/TypeSourceDergPage";
import TypeMeteoPage from "../pages/parametrage/TypeMeteoPage";
import TypeCouverturePage from "../pages/parametrage/TypeCouverturePage";
import TypeMethodePage from "../pages/parametrage/TypeMethodePage";
import TypeInnondationPage from "../pages/parametrage/TypeInnondationPage";
import TypeQualiteRecPage from "../pages/parametrage/TypeQualiteRecPage";
import TypeBaguePage from "../pages/parametrage/TypeBaguePage";
import RecSynthesePage from "../pages/recensement/report/RecSynthesePage";
import TypeSitePage from "../pages/parametrage/TypeSitePage";
import TypeFoncierPage from "../pages/parametrage/TypeFoncierPage";
import TypeActeurPage from "../pages/parametrage/TypeActeurPage";
import TypeInterventionPage from "../pages/parametrage/TypeInterventionPage";
import TypeHabitatPage from "../pages/parametrage/TypeHabitatPage";
import ComplexePage from "../pages/parametrage/ComplexePage";
import RegionPage from "../pages/parametrage/RegionPage";
import StatutNatPage from "../pages/parametrage/StatutNatPage";
import StatutWiPage from "../pages/parametrage/StatutWiPage";
import TypeTendancePage from "../pages/parametrage/TypeTendancePage";
import LanguePage from "../pages/parametrage/LanguePage";
import RecImportationPage from '../pages/recensement/RecImportationPage';


const Navigation = (props) => {
    return (
        <>
            <Route path="/" exact render={() => <Dashboard colorMode={props.layoutColorMode} location={props.location}/>}/>
            <Route path="/visites" render={() => <RecensementPage/>}/>
            <Route path="/importations" render={() => <RecImportationPage/>}/>
            <Route path="/marees" render={() => <TypeMareePage/>}/>
            <Route path="/typemarl" render={() => <TypemarlPage/>}/>
            <Route path="/typegel" render={() => <TypeGelPage/>}/>
            <Route path="/typederg" render={() => <TypeDergPage/>}/>
            <Route path="/sourcederg" render={() => <TypeSourceDergPage/>}/>
            <Route path="/typemeteo" render={() => <TypeMeteoPage/>}/>
            <Route path="/typecouverture" render={() => <TypeCouverturePage/>}/>
            <Route path="/typemethode" render={() => <TypeMethodePage/>}/>
            <Route path="/typeinnondation" render={() => <TypeInnondationPage/>}/>
            <Route path="/typequalite" render={() => <TypeQualiteRecPage/>}/>
            <Route path="/typebague" render={() => <TypeBaguePage/>}/>
            <Route path="/syntheses" render={() => <RecSynthesePage/>}/>

            <Route path="/sites" render={() => <SitePage/>}/>
            {/*<Route path="/sites2" render={() => <Site2Page/>}/>
            <Route path="/sites3" render={() => <SitePageOLD/>}/>
            <Route path="/map" render={() => <EmptyPage/>}/>*/}
            <Route path="/typesites" render={() => <TypeSitePage/>}/>
            <Route path="/typefoncier" render={() => <TypeFoncierPage/>}/>
            <Route path="/typeacteur" render={() => <TypeActeurPage/>}/>
            <Route path="/typeintervention" render={() => <TypeInterventionPage/>}/>
            <Route path="/typehabitat" render={() => <TypeHabitatPage/>}/>
            <Route path="/complexe" render={() => <ComplexePage/>}/>
            <Route path="/region" render={() => <RegionPage/>}/>
            <Route path="/sitesmap" render={() => <SitesMapPage/>}/>
            <Route path="/sitesmarkers" render={() => <SitesMapMarkersPage/>}/>
            <Route path="/sitesclusters" render={() => <SitesMapClusteringPage/>}/>


            <Route path="/especenat" render={() => <EspeceNatPage/>}/>

            <Route path="/statutnats" render={() => <StatutNatPage/>}/>
            <Route path="/statutwis" render={() => <StatutWiPage/>}/>
            <Route path="/typetendance" render={() => <TypeTendancePage/>}/>
            <Route path="/langues" render={() => <LanguePage/>}/>
        </>)
}
const mapStateToProps = state => {
    return {
        /*        layoutMode: state.globaleReducer.layoutMode,
                staticMenuInactive: state.globaleReducer.staticMenuInactive,
                overlayMenuActive: state.globaleReducer.overlayMenuActive,
                mobileMenuActive: state.globaleReducer.mobileMenuActive,
                inputStyle: state.globaleReducer.inputStyle,
                ripple: state.globaleReducer.ripple,
                layoutColorMode: state.globaleReducer.layoutColorMode,*/
    };
};
export default connect(mapStateToProps, {})(Navigation);
