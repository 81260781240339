import React, { useEffect, useRef, useState } from "react";
import { Toolbar } from "primereact/toolbar";

import { connect } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";

import LeftToolbarTemplate from "../../components/leftToolbarTemplate";
import RightToolbarTemplate from "../../components/rightToolbarTemplate";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import ImportationActionBodyTemplate from "../../components/importationActionBodyTemplate";

import DatatableHeaderTemplate from "../../components/datatableHeaderTemplate";
import DialogFooterTemplate from "../../components/dialogFooterTemplate";
import DeleteDialogComponent from "../../components/DeleteDialogComponent";
import { formatDateTime, getFormikFormErrorMessage, isFormikFormFieldValid } from "../../utils";

import {
    loadRecImportationList,
    executeSelectedImportation,
    addNewRecImportation,
    editSelectedRecImportation,
    showDeleteSelectedRecImportation,
    cancelDeleteRecImportation,
    deleteRecImportationRecord,
    cancelEditRecImportation,
    saveRecImportationRecord,
    setSelectedRecImportation,
    setRecImportationLazyParams,
    setRecImportationGlobalFilter,
} from "../../redux/recensement/importations/ImportationActions";
import { Dialog } from "primereact/dialog";
import classNames from "classnames";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { InputTextarea } from "primereact/inputtextarea";

import "./status.css";
import { Button } from "primereact/button";

const RecImportationPage = (props) => {
    const dt = useRef(null);
    const [showRepport, setShowRepport] = useState(false);

    useEffect(() => {
        props.loadRecImportationList();
    }, []);

    useEffect(() => {
        if (props.selectedRecord.id) {
            props.selectedRecord.file_name = "";
            formik.setValues(props.selectedRecord);
        }
    }, [props.selectedRecord]);

    function generateNumberList() {
        let start = 1987;
        let end = new Date().getFullYear();

        const numberList = [];
        for (let i = end; i >= start; i--) {
            numberList.push({ annee_rec: i, value: i });
        }

        return numberList;
    }

    const validationSchema = Yup.object().shape({
        annee_rec: Yup.number().required("Ce champ est obligatoire"),
        file_name: Yup.mixed()
            .required("Ce champ est obligatoire")
            .test("fileFormat", "Only Excel files are allowed", (value) => {
                if (value) {
                    const supportedFormats = ["xlsx", "xls"];
                    return supportedFormats.includes(value.name.split(".").pop());
                }
                return true;
            })
            .test("fileSize", "File size must be less than 10MB", (value) => {
                if (value) {
                    return value.size <= 1e7;
                }
                return true;
            }),
    });

    const handleFileChange = (e) => {
        formik.setFieldValue("file_name", e.files[0]);
    };

    const formik = useFormik({
        initialValues: props.selectedRecord,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            values.importation_date = "2024-03-23";
            props.saveRecImportationRecord(values);
            formik.resetForm();
        },
    });

    const handleShowRepportChange = (rowData) => {
        props.setSelectedRecImportation(rowData);
        setShowRepport(true);
    };

    const statusBodyTemplate = (rowData) => {
        return <span className={`p-2 item-status status-${rowData.status.toLowerCase()}`}>{rowData.status}</span>;
    };

    const repportBodyTemplate = (rowData) => {

        return rowData.status==='failed'&& <Button icon="pi pi-list" className="p-button-rounded p-button-danger mr-2" label="Afficher le rapport" onClick={(e) => handleShowRepportChange(rowData)} />;
    };

    const renderFooter = (name) => {
        return (
            <div>
                <Button label="Fermer" icon="pi pi-times" onClick={() => setShowRepport(false)} className="p-button-text" />
            </div>
        );
    };

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toolbar
                        className="mb-4"
                        left={
                            <LeftToolbarTemplate
                                addNew={props.addNewRecImportation}
                                //confirmDelete={confirmDeleteSelected}
                                disabled={props.selectedRecord.id === null}
                            />
                        }
                        //right={<RightToolbarTemplate dt={dt} />}
                    />

                    <DataTable
                        ref={dt}
                        size="small"
                        responsiveLayout="scroll"
                        loading={props.loading}
                        value={props.dataList}
                        dataKey="id"
                        lazy
                        rows={props.datatable.rows}
                        paginator
                        first={props.datatable.lazyParams.first}
                        rowsPerPageOptions={[10, 20, 50]}
                        filterDisplay="menu"
                        filters={props.datatable.lazyParams.filters}
                        sortField={props.datatable.lazyParams.sortField}
                        totalRecords={props.datatable.totalRecords}
                        selection={props.selectedRecord}
                        sortOrder={props.datatable.lazyParams.sortOrder}
                        onSort={(ev) => props.setRecImportationLazyParams(ev)}
                        onFilter={(ev) => props.setRecImportationLazyParams(ev)}
                        //onPage={onPage}
                        selectionMode="single"
                        globalFilter={props.datatable.globalFilter}
                        shi
                        emptyMessage="Aucune enregistrement trouvé."
                        header={<DatatableHeaderTemplate title={"Importation"} setGlobalFilter={props.setRecImportationGlobalFilter} />}
                        onSelectionChange={(record) => props.setSelectedRecImportation(record.value)}
                        scrollable
                        scrollHeight="calc(100vh - 11rem"
                    >
                        <Column field="annee_rec" header="Année" filterField="annee_rec" style={{ flexGrow: 1, flexBasis: "5%" }} />
                        <Column
                            field="created_at"
                            header="Date d'importation"
                            body={(rowData) => {
                                return formatDateTime(rowData.created_at);
                            }}
                            showFilterMenu={false}
                            style={{ flexGrow: 1, flexBasis: "10%" }}
                        />

                        <Column field="file_name_without_url" header="Le nom du fichier" showFilterMenu={false} sortable style={{ flexGrow: 1, flexBasis: "30%" }} />
                        <Column field="status" header="Status" body={statusBodyTemplate} style={{ flexGrow: 1, flexBasis: "5%" }} />
                        <Column field="repport" header="Repport" body={repportBodyTemplate} style={{ flexGrow: 1, flexBasis: "30%" }} />

                        <Column
                            body={(rowData) => <ImportationActionBodyTemplate data={rowData}
                            executeimport={props.executeSelectedImportation}
                            //editRecord={(data) => props.editSelectedRecImportation(data)}
                            deleteRecord={props.showDeleteSelectedRecImportation} />}
                            style={{ flexGrow: 1, flexBasis: "8%" }}
                        />
                    </DataTable>
                    <form id="importRecForm" encType="multipart/form-data" onSubmit={formik.handleSubmit}>
                        <Dialog
                            modal
                            position="top"
                            draggable={false}
                            maximizable
                            style={{ width: "40vw" }}
                            className="p-fluid"
                            visible={props.showEditDialog}
                            header={props.dialogTitle}
                            footer={<DialogFooterTemplate hideDialog={props.cancelEditRecImportation} form={"importRecForm"} />}
                            onHide={props.cancelEditRecImportation}
                        >
                            <div className="field col-12 md:col-6">
                                <label htmlFor="annee_rec">Année de recensement*</label>
                                <Dropdown name="annee_rec" optionLabel="annee_rec" options={generateNumberList()} value={formik.values.annee_rec} onChange={formik.handleChange} placeholder="Sélectionner l'année de recensement" />
                                {getFormikFormErrorMessage(formik, "annee_rec")}
                            </div>
                            <div className="field col-12 md:col-8">
                                <label htmlFor="file_name">Fichier de recencements*</label>
                                <FileUpload
                                    mode="basic"
                                    id="file_name"
                                    name="file_name"
                                    //url="recensements/upload"
                                    chooseLabel="Sélectionner le fichier des données a importer"
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                    //maxFileSize={1000000}
                                    onSelect={handleFileChange}
                                />
                                {getFormikFormErrorMessage(formik, "file_name")}
                            </div>
                            <div className="field col-12 md:col-12">
                                <InputTextarea
                                    //disabled={!props.editionMode}
                                    id="rep"
                                    name="description"
                                    value={formik.values.description}
                                    onChange={formik.handleChange}
                                    rows={10}
                                    cols={20}
                                    className={classNames({
                                        "p-invalid": isFormikFormFieldValid(formik, "description"),
                                        //"bg-black-alpha-10": !props.editionMode,
                                    })}
                                />
                                {getFormikFormErrorMessage(formik, "description")}
                            </div>
                        </Dialog>
                        {props.showDeleteDialog === true && (
                            <DeleteDialogComponent
                                showDialog={props.showDeleteDialog}
                                executeDelete={() => props.deleteRecImportationRecord()}
                                executeCancel={() => props.cancelDeleteRecImportation()}
                                message={"l'opération d'importation pour l'année (" + props.selectedRecord.annee_rec + ")"}
                                processing={props.saveProcess}
                            />
                        )}
                    </form>
                    <Dialog header="Rapport d'importation" visible={showRepport && props.selectedRecord.id !== null} style={{ width: "50vw" }} footer={renderFooter} onHide={() => setShowRepport(false)}>
                        <div dangerouslySetInnerHTML={{ __html: props.selectedRecord.rec_report }} />
                        <div dangerouslySetInnerHTML={{ __html: props.selectedRecord.rec_result_report }} />
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

/*const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};*/

const mapStateToProps = (state) => {
    return {
        authToken: state.userReducer.authToken,
        isLoggedIn: state.userReducer.isLoggedIn,
        loading: state.importationReducer.loading,
        saveProcess: state.importationReducer.saveProcess,
        dataList: state.importationReducer.dataList,
        datatable: state.importationReducer.datatable,

        emptyRecord: state.importationReducer.emptyRecord,
        selectedRecord: state.importationReducer.selectedRecord,
        showEditDialog: state.importationReducer.showEditDialog,
        showDeleteDialog: state.importationReducer.showDeleteDialog,
        dialogTitle: state.importationReducer.dialogTitle,
    };
};
export default connect(mapStateToProps, {
    loadRecImportationList,
    setSelectedRecImportation,
    setRecImportationLazyParams,
    setRecImportationGlobalFilter,
    executeSelectedImportation,
    addNewRecImportation,
    editSelectedRecImportation,
    cancelEditRecImportation,
    deleteRecImportationRecord,
    saveRecImportationRecord,

    showDeleteSelectedRecImportation,
    cancelDeleteRecImportation,
})(React.memo(RecImportationPage));
