import {REDUX_CONST} from '../../config/constats';
import Store from "../Store";
import {apiClient} from "../../api/ApiClient";
import {Logout} from "../UserActions";


const endpoint = 'recensements';

export const getList = () => {
    return async dispatch => {
        let {page, rows, filters, sortField, sortOrder} = Store.getState().siteReducer.lazyParams;

        page = page === 0 || page === undefined ? 1 : page
        let site = filters.site.value?.id
        site = site !== undefined && site !== null && `${site}`.length > 0 ? `&site=${`${site}`.replace('&', '')}` : ''
        let date = filters.date.value
        date = date !== undefined && date !== null && date.length > 0 ? `&date=${date.replace('&', '')}` : ''
        let year = filters.year.value
        year = year !== undefined && year !== null && year.length > 0 ? `&year=${year.replace('&', '')}` : ''
        let global = filters.global.value
        global = global !== undefined && global !== null && global.length > 0 ? `&search=${global.replace('&', '')}` : ''
        let ordering = sortField !== undefined && sortField !== null && sortField.length > 0 ? `&ordering=${sortOrder === 1 ? sortField : '-'.concat(sortField)}` : ''


        let filter = `${site}${date}${year}${global}${ordering}`

        let data = {};


        await apiClient()
            .get(
                `${endpoint}?page=${page}&page_size=${rows}${filter}`,
            )
            .then(res => {
                if (res.ok) {
                    data = {...res.data};
                }
            }).catch((error) => {
                if (error.response) {
                    return error.response.data
                }
            });

        await dispatch({
            type: REDUX_CONST.SET_RECENECEMENT_LIST,
            payload: data,
        });
    };
}

export const getCombo = (q = "") => {
    return async dispatch => {
        let data = {};
        await apiClient().get(`${endpoint}combo/?search=${q}`).then(async res => {
            if (res.ok) {
                data = {...res.data};
                await dispatch({
                    type: REDUX_CONST.SET_RECENECEMENT_COMBO,
                    payload: data,
                });
            }
        }).catch((error) => {
            if (error.response) {
                return error.response.data
            }
        });
    };
}

export const getComboOfRecYears = (q = "") => {
    return async dispatch => {
        let data = {};

        await apiClient()
            .get(`${endpoint}year/?search=${q}`)
            .then(async res => {
                if (res.ok) {
                    data = res.data;
                } else {
                    if (res.status === 401) {
                        dispatch(Logout());
                    }
                }
            }).catch((error) => {
                if (error.response) {
                    return error.response.data
                }
            });

        await dispatch({
            type: REDUX_CONST.SET_RECENECEMENT_COMBO_YEAR,
            payload: data,
        });
    };
}

export const getRecNbrByYearSiteEspece = (q = "") => {
    return async dispatch => {
        let data = {};
        await apiClient().get(`${endpoint}effectifbyyearsiteespece/?${q}`).then(async res => {
            if (res.ok) {
                data = {...res.data};
                await dispatch({
                    type: REDUX_CONST.SET_RECENECEMENT_NBR_BY_YEAR_SITE_ESPECE,
                    payload: data,
                });
            }
        }).catch((error) => {
            if (error.response) {
                return error.response.data
            }
        });
    };
}

export const getRecNbrByYear = (q = "") => {
    return async dispatch => {
        let data = {};
        await apiClient().get(`${endpoint}nbrbyyear/?search=${q}`).then(async res => {
            if (res.ok) {
                data = {...res.data};
                await dispatch({
                    type: REDUX_CONST.SET_RECENECEMENT_NBR_BY_YEAR,
                    payload: data,
                });
            }
        }).catch((error) => {
            if (error.response) {
                return error.response.data
            }
        });
    };
}

export const getRecEffectifByYear = (q = "") => {
    return async dispatch => {
        let data = {};
        await apiClient().get(`${endpoint}effectifbyyear/?search=${q}`).then(async res => {
            if (res.ok) {
                data = {...res.data};
                await dispatch({
                    type: REDUX_CONST.SET_RECENECEMENT_EFFECTIF_BY_YEAR,
                    payload: data,
                });
            }
        }).catch((error) => {
            if (error.response) {
                return error.response.data
            }
        });
    };
}

export const addOne = (data) => {
    return async dispatch => {
        let data = {}
        await apiClient().post(`${endpoint}/`, data).then(async res => {
            if (res.ok) {
                data = {...res.data};
                await dispatch({
                    type: REDUX_CONST.SET_RECENECEMENT_ADDED,
                    payload: data,
                });
            }
        }).catch((error) => {
            if (error.response) {
                return error.response.data
            }
        });
    };
}

export const updateOne = (data) => {
    return async dispatch => {
        let data = {}
        await apiClient().patch(`${endpoint}/${data.id}/`, data).then(async res => {
            if (res.ok) {
                data = {...res.data};
                await dispatch({
                    type: REDUX_CONST.SET_RECENECEMENT_UPDATED,
                    payload: data,
                });
            }
        }).catch((error) => {
            if (error.response) {
                return error.response.data
            }
        });
    };
}

export const deleteOne = (id) => {
    return async dispatch => {
        let data = {}
        await apiClient().delete(`${endpoint}/${id}/`).then(async res => {
            if (res.ok) {
                data = {...res.data};
                await dispatch({
                    type: REDUX_CONST.SET_RECENECEMENT_DELETED,
                    payload: data,
                });
            }
        }).catch((error) => {
            if (error.response) {
                return error.response.data
            }
        });
    };
}


export const setSiteLazyParams = state => {
    return async dispatch => {
        await dispatch({
            type: REDUX_CONST.SET_SITE_LAZY_PARAMS,
            payload: state,
        });
    };
};

export const setSiteGlobalFilter = state => {
    return async dispatch => {
        await dispatch({
            type: REDUX_CONST.SET_SITE_GLOBAL_FILTER,
            payload: state,
        });
    };
};

export const setSiteLoading = state => {
    return async dispatch => {
        await dispatch({
            type: REDUX_CONST.SET_SITE_LOADING,
            payload: state,
        });
    };
};

export const setExpandedRows = (state) => {
    return async dispatch => {
        await dispatch({
            type: REDUX_CONST.SET_SITE_EXPANDED_ROWS,
            payload: state,
        });
    };
};

export const setSiteFilter = (state) => {
    state['first'] = 1;

    return async dispatch => {
        await dispatch({
            type: REDUX_CONST.SET_SITE_LAZY_PARAMS,
            payload: state,
        });
    };
};

export const setSelectedSite = site => {
    return async dispatch => {
        await dispatch({
            type: REDUX_CONST.SET_SELECTED_SITE,
            payload: site,
        });
    };
};

export const setDeleteSiteDialog = state => {
    return async dispatch => {
        await dispatch({
            type: REDUX_CONST.SET_DELETE_SITE_DIALOG,
            payload: state,
        });
    };
};

export const setSubmitted = state => {
    return async dispatch => {
        await dispatch({
            type: REDUX_CONST.SET_SITE_FORM_SUBMITTED,
            payload: state,
        });
    };
};

export const setShowMessage = state => {
    return async dispatch => {
        await dispatch({
            type: REDUX_CONST.SET_SITE_SHOW_MESSAGE,
            payload: state,
        });
    };
};


export const addNewSite = () => {
    return async dispatch => {
        await dispatch({
            type: REDUX_CONST.ADD_NEW_SITE,
            payload: [],
        });
    };
};

export const editSelectedSite = () => {
    return async dispatch => {
        await dispatch({
            type: REDUX_CONST.EDIT_SELECTED_SITE,
            payload: [],
        });
    };
};

export const cancelEditSelectedSite = () => {
    return async dispatch => {
        await dispatch({
            type: REDUX_CONST.CANCEL_EDIT_SELECTED_SITE,
            payload: [],
        });
    };
};


