import axios from 'axios';
import {API_URL} from "../../config";

export class EspeceNatService {
    constructor() {
        this.endpoint = "especenat"
    }

    getList({page, rows, filters, sortField, sortOrder}, token) {
        //?ordering=-published_at&page=1&page_size=10&search=d
        //?ordering=-published_at&page=1&page_size=10&search=d&title=a

        page = page === 0 || page === undefined ? 1 : page


        let codenat = filters.codenat.value
        codenat = codenat !== undefined && codenat !== null && codenat.length > 0 ? `&codenat=${codenat.replace('&', '')}` : ''
        let ordre = filters.ordre.value
        ordre = ordre !== undefined && ordre !== null && ordre.length > 0 ? `&ordre=${ordre.replace('&', '')}` : ''
        let famille = filters.famille.value
        famille = famille !== undefined && famille !== null && famille.length > 0 ? `&famille=${famille.replace('&', '')}` : ''
        let genre = filters.genre.value
        genre = genre !== undefined && genre !== null && genre.length > 0 ? `&genre=${genre.replace('&', '')}` : ''
        let espece = filters.espece.value
        espece = espece !== undefined && espece !== null && espece.length > 0 ? `&espece=${espece.replace('&', '')}` : ''
        let sespece = filters.sespece.value
        sespece = sespece !== undefined && sespece !== null && sespece.length > 0 ? `&sespece=${sespece.replace('&', '')}` : ''

        let global = filters.global.value
        global = global !== undefined && global !== null && global.length > 0 ? `&search=${global.replace('&', '')}` : ''


        let ordering = sortField !== undefined && sortField !== null && sortField.length > 0 ? `&ordering=${sortOrder === 1 ? sortField : '-'.concat(sortField)}` : ''


        let filter = `${codenat}${ordre}${famille}${genre}${espece}${sespece}${global}${ordering}`

        return axios.get(`${API_URL}/${this.endpoint}?page=${page}&page_size=${rows}${filter}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {
            return res.data;
        }).catch((error) => {
        })
    }

    getCombo(q, token) {
        return axios.get(`${API_URL}/${this.endpoint}combo/?search=${q}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {
            return res.data;
        }).catch((error) => {
            console.error(error)
        })
    }

    addOne(data, token) {
        return axios.post(`${API_URL}/${this.endpoint}/`, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {

            return res.data;
        })
    }

    updateOne(data, token) {
        return axios.patch(`${API_URL}/${this.endpoint}/${data.id}/`, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {
            return res.data;
        }).catch((error) => {
            if (error.response) {
                return error.response.data
            }
        })
    }

    deleteOne(id, token) {
        return axios.delete(`${API_URL}/${this.endpoint}/${id}/`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {
            return res.data;
        }).catch((error) => {
            if (error.response) {
                return error.response.data
            }
        })
    }
}
