import {REDUX_CONST} from "../../../config/constats";
import {getCentroid} from "../../../utils";


const initialState = {
    loading: false,
    center: [31.558059, -7.483406],
    zoom_level: 6,
    marker: [],
    polygon: [],
    data: [],
    editionMode: false
};

export default (state = initialState, action) => {
    //let dt = {};
    switch (action.type) {
        case REDUX_CONST.MAP.LOADING:
            return {
                ...state,
                loading: action.payload
            };
        case REDUX_CONST.MAP.SET_SELECTED_GEO:
            let delimCoord = action.payload?.delimitations?.coordinates;
            let locCoord = action.payload?.localisation?.coordinates;

            let center = delimCoord ? getCentroid(delimCoord) : locCoord
            let zoom_level = center ? delimCoord ? 13 : 15 : 5;
            return {
                ...state,
                polygon: delimCoord,
                marker: locCoord,
                center: center,
                zoom_level: zoom_level
            };
        case REDUX_CONST.MAP.SET_CENTER:
            return {
                ...state,
                center: action.payload
            };
        case REDUX_CONST.MAP.SET_ZOOM_LEVEL:
            return {
                ...state,
                zoom_level: action.payload
            };
        case REDUX_CONST.MAP.SET_MARKER:
            return {
                ...state,
                marker: action.payload
            };
        case REDUX_CONST.MAP.SET_POLYGON:
            return {
                ...state,
                polygon: action.payload
            };
        case REDUX_CONST.MAP.SET_DATA:
            return {
                ...state,
                data: action.payload,
                center: [31.558059, -7.483406],
                zoom_level: 6
            };
        case REDUX_CONST.MAP.SET_EDITION_MODE:
            return {
                ...state,
                editionMode: action.payload,
            };
        default:
            return state;
    }
}
;
