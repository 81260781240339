import React, {useEffect, useRef, useState} from 'react';
import {Button} from 'primereact/button';
import {Chart} from 'primereact/chart';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {RecensementService} from "../service/recensement/RecensementService";
import {connect} from "react-redux";
import {Login} from "../redux/UserActions";


const Dashboard = (props) => {
    let loadLazyTimeout = null;
    //const [loadingRec, setLoadingRec] = useState(false);
    //const [totalRecordsRec, setTotalRecordsRec] = useState(0);
    const [lineData, setLineData] = useState({
        labels: [], datasets: [{
            label: 'Nbr de visites par années', data: [], fill: false, backgroundColor: '#00bb6d', borderColor: '#bb0041', tension: .5
        }]
    })


    const [recEffectifNbrData, setRecEffectifNbrData] = useState({
        labels: [], datasets: [{
            label: 'Effectif d\'espéces recensé par années ', data: [], fill: false, backgroundColor: '#00bb6d', borderColor: '#bb0041', tension: .5
        }]
    })

    const recService = new RecensementService();
    const [lazyParamsRec, setLazyParamsRec] = useState({
        first: 0, rows: 50, page: 0, sortField: null, sortOrder: null, filters: {
            'site': {value: '', matchMode: 'contains'}, 'date': {value: '', matchMode: 'contains'}, 'year': {value: '', matchMode: 'contains'}, 'global': {value: '', matchMode: 'contains'},
        }
    });
    //const [products, setProducts] = useState(null);
    //const menu1 = useRef(null);
    //const menu2 = useRef(null);
    //const [lineOptions, setLineOptions] = useState(null)

    const [recList, setRecList] = useState([])


    useEffect(() => {
        loadLazyDataRec();
    }, []);

    useEffect(() => {
        recService.getRecNbrByYear('', props.authToken).then(data => {
            for (let i = 0; i < data.length; i++) {
                lineData.labels.push(data[i].annee.replace('.0', ''))
                lineData.datasets[0].data.push(data[i].nbr)
            }
            setLineData(lineData)
        });
    }, []);

    useEffect(() => {
        recService.getRecEffectifByYear('', props.authToken).then(data => {

            for (let i = 0; i < data.length; i++) {
                recEffectifNbrData.labels.push(data[i].annee.replace('.0', ''))
                recEffectifNbrData.datasets[0].data.push(data[i].nbr)
            }
            setRecEffectifNbrData(recEffectifNbrData)
        });
    }, []);


    const loadLazyDataRec = () => {
        //setLoadingRec(true);
        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }
        loadLazyTimeout = setTimeout(() => {
            recService.getList(lazyParamsRec, props.authToken).then(data => {
                //setTotalRecordsRec(data.count);
                setRecList(data.results);
                //setLoadingRec(false);
            });
        }, Math.random() * 1000 + 250);
    }

    const applyLightTheme = () => {
        const lineOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: '#0041bb'
                    }
                }
            }, scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    }, grid: {
                        color: '#ebedef',
                    }
                }, y: {
                    ticks: {
                        color: '#495057'
                    }, grid: {
                        color: '#ebedef',
                    }
                },
            }
        };

        //setLineOptions(lineOptions)
    }

    const applyDarkTheme = () => {
        const lineOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: '#020202'
                    }
                }
            }, scales: {
                x: {
                    ticks: {
                        color: '#01101f'
                    }, grid: {
                        color: 'rgba(160, 167, 181, .3)',
                    }
                }, y: {
                    ticks: {
                        color: '#01101f'
                    }, grid: {
                        color: 'rgba(160, 167, 181, .3)',
                    }
                },
            }
        };

        //setLineOptions(lineOptions)
    }

    let basicOptions = {
        maintainAspectRatio: false,
        aspectRatio: .8,
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            },
            y: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            }
        }
    };

    useEffect(() => {
        if (props.colorMode === 'light') {
            applyLightTheme();
        } else {
            applyDarkTheme();
        }
    }, [props.colorMode]);

    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
    };

    return (<div className="grid">
        <div className="col-12 lg:col-6 xl:col-3">
            <div className="card mb-0">
                <div className="flex justify-content-between mb-3">
                    <div>
                        <span className="block text-500 font-medium mb-3">Nombre de site</span>
                        <div className="text-900 font-medium text-xl">275 sites</div>
                    </div>
                    <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{width: '2.5rem', height: '2.5rem'}}>
                        <i className="pi pi-map text-blue-500 text-xl"/>
                    </div>
                </div>
                <span className="text-green-500 font-medium"></span>
                <span className="text-500"></span>
            </div>
        </div>
        <div className="col-12 lg:col-6 xl:col-3">
            <div className="card mb-0">
                <div className="flex justify-content-between mb-3">
                    <div>
                        <span className="block text-500 font-medium mb-3">Nombre d'espèce</span>
                        <div className="text-900 font-medium text-xl">305 espèces nationale</div>
                    </div>
                    <div className="flex align-items-center justify-content-center bg-orange-100 border-round" style={{width: '2.5rem', height: '2.5rem'}}>
                        <i className="pi pi-twitter text-orange-500 text-xl"/>
                    </div>
                </div>
                <span className="text-green-500 font-medium"></span>
                <span className="text-500"></span>
            </div>
        </div>
        <div className="col-12 lg:col-6 xl:col-3">
            <div className="card mb-0">
                <div className="flex justify-content-between mb-3">
                    <div>
                        <span className="block text-500 font-medium mb-3">Total des vistes</span>
                        <div className="text-900 font-medium text-xl">3129 Visites</div>
                    </div>
                    <div className="flex align-items-center justify-content-center bg-cyan-100 border-round" style={{width: '2.5rem', height: '2.5rem'}}>
                        <i className="pi pi-inbox text-cyan-500 text-xl"/>
                    </div>
                </div>
                <span className="text-green-500 font-medium"></span>
                <span className="text-500"></span>
            </div>
        </div>
        <div className="col-12 lg:col-6 xl:col-3">
            <div className="card mb-0">
                <div className="flex justify-content-between mb-3">
                    <div>
                        <span className="block text-500 font-medium mb-3">Nombre des observateurs</span>
                        <div className="text-900 font-medium text-xl">332 Observateurs</div>
                    </div>
                    <div className="flex align-items-center justify-content-center bg-purple-100 border-round" style={{width: '2.5rem', height: '2.5rem'}}>
                        <i className="pi pi-user text-purple-500 text-xl"/>
                    </div>
                </div>
                <span className="text-green-500 font-medium"> </span>
                <span className="text-500"></span>
            </div>
        </div>


        <div className="col-12 xl:col-6">
            <div className="card">
                <h5>Les 14 derniéres visite</h5>
                <DataTable value={recList} rows={14} paginator responsiveLayout="scroll">
                    <Column field="site.site_nom" header="Site" sortable style={{width: '45%'}}/>
                    <Column field="date" header="Date de visite" sortable style={{width: '30%'}}/>
                    <Column field="nbr_result" header="Nombre de resultats" sortable style={{width: '30%'}}/>
                    <Column header="Afficher" style={{width: '15%'}} body={() => (<>
                        <Button icon="pi pi-search" type="button" className="p-button-text"/>
                    </>)}/>
                </DataTable>
            </div>
        </div>
        <div className="col-12 xl:col-6">
            <div className="col-12 xl:col-12">
                <div className="card">
                    <h5>Nombre de visite par année</h5>
                    <Chart type="bar" data={lineData} options={basicOptions}/>
                </div>
            </div>
            <div className="col-12 xl:col-12">
                <div className="card">
                    <h5>Effectifs recensés par année</h5>
                    <Chart type="bar" data={recEffectifNbrData} options={basicOptions}/>
                </div>
            </div>
        </div>


    </div>);
}

const comparisonFn = function (prevProps, nextProps) {
    return (prevProps.location.pathname === nextProps.location.pathname) && (prevProps.colorMode === nextProps.colorMode);
};

const mapStateToProps = state => {
    return {
        authToken: state.userReducer.authToken,
        isLoggedIn: state.userReducer.isLoggedIn,
    };
};
export default connect(mapStateToProps, {Login})(React.memo(Dashboard, comparisonFn));
