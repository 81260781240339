import React, {useCallback, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import classNames from 'classnames';
import {connect} from "react-redux";
import {Logout} from "../redux/UserActions";

const AppTopbar = (props) => {
    const [selectedCity1, setSelectedCity1] = useState(null);


    const [theme, setTheme] = useState();
    useEffect(() => {
        let themeElement = document.getElementById('theme-link');
        const themeHref = 'assets/themes/' + theme + '/theme.css';
        replaceLink(themeElement, themeHref);
    }, [theme])

    const changeTheme = () => {
        if (theme === 'vela-green') {
            setTheme('saga-green')
        } else {
            setTheme('vela-green')
        }
    }

    const replaceLink = useCallback((linkElement, href, callback) => {
        if (isIE()) {
            linkElement.setAttribute('href', href);

            if (callback) {
                callback();
            }
        } else {
            const id = linkElement.getAttribute('id');
            const cloneLinkElement = linkElement.cloneNode(true);

            cloneLinkElement.setAttribute('href', href);
            cloneLinkElement.setAttribute('id', id + '-clone');

            linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

            cloneLinkElement.addEventListener('load', () => {
                linkElement.remove();
                cloneLinkElement.setAttribute('id', id);

                if (callback) {
                    callback();
                }
            });
        }
    }, [])
    const isIE = () => {
        return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent)
    }
    const logoutUser = () => {
        props.Logout()
    }

    const cities = [
        {name: 'Oiseaux', code: 'NY'},
        {name: 'Règne végétal', code: 'RM'},
        {name: 'London', code: 'LDN'},
        {name: 'Istanbul', code: 'IST'},
        {name: 'Paris', code: 'PRS'}
    ];

    const onCityChange = (e) => {
        setSelectedCity1(e.value);
    }


    return (<div className="layout-topbar">
        <Link to="/" className="layout-topbar-logo">
            {/*<img src={props.layoutColorMode === 'light' ? 'assets/layout/images/logo-dark.svg' : 'assets/layout/images/logo-white.svg'} alt="logo"/>*/}
            <span>IWC</span>
        </Link>

        <button type="button" className="p-link  layout-menu-button layout-topbar-button" onClick={props.onToggleMenuClick}>
            <i className="pi pi-bars"/>
        </button>

        <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={props.onMobileTopbarMenuClick}>
            <i className="pi pi-ellipsis-v"/>
        </button>


        <ul className={classNames("layout-topbar-menu lg:flex origin-top", {'layout-topbar-menu-mobile-active': props.mobileTopbarMenuActive})}>
            {/*<li>
                <Dropdown value={selectedCity1} options={cities} onChange={onCityChange} optionLabel="name" placeholder="choix de phylome"/>
            </li>*/}
            <li>
                <button className="p-link layout-topbar-button" onClick={(e) => changeTheme()}>
                    <i className={theme == 'vela-green' ? "pi pi-sun" : 'pi pi-moon'}/>
                    <span>Events</span>
                </button>
            </li>
            <li>
                <button className="p-link layout-topbar-button" onClick={() => logoutUser()}>
                    <i className="pi pi-sign-out"/>
                    <span>Profile</span>
                </button>
            </li>
        </ul>
    </div>);
}

const mapStateToProps = state => {
    return {};
};
export default connect(mapStateToProps, {Logout})(AppTopbar);
