//import React, {useState} from 'react';
import {useMapEvents,} from 'react-leaflet'
import {connect} from "react-redux";

const LocationMarker = (props) => {
    const map = useMapEvents({})
    map.flyTo(props.center, props.zoom_level,{animate:true})
    return null;
};


const mapStateToProps = state => {
    return {
        center: state.mapReducer.center,
        zoom_level: state.mapReducer.zoom_level,
    };
};


export default connect(mapStateToProps, {})(LocationMarker)
