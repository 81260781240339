const DEFAULT_CONST = {
    LOADING: 'LOADING',
    LOADING_VALIDATION_PROCESS: 'LOADING_VALIDATION_PROCESS',
    LIST: 'LIST',
    FULL_LIST: 'FULL_LIST',
    COMBO: 'COMBO',
    ADD_NEW: 'ADD_ONE',
    SET_SELECTED: 'SET_SELECTED',
    EDIT_SELECTED: 'EDIT_SELECTED',
    CANCEL_EDIT: 'CANCEL_EDIT',
    DATA_SAVED: 'DATA_SAVED',
    SET_LAZY_PARAMS: 'SET_LAZY_PARAMS',
    SET_GLOBAL_FILTER: 'SET_GLOBAL_FILTER',
    SHOW_DELETE_DIALOG: 'SHOW_DELETE_DIALOG',
}
export const REDUX_CONST = {
    SET_GLOBALE_TOAST: "SET_GLOBALE_TOAST",

    SITE2: {
        ...DEFAULT_CONST,
    },

    MAP: {
        LOADING: 'LOADING',
        SET_SELECTED_GEO: 'SET_SELECTED_GEO',
        SET_CENTER: 'SET_CENTER',
        SET_ZOOM_LEVEL: 'SET_ZOOM_LEVEL',
        SET_MARKER: 'SET_MARKER',
        SET_POLYGON: 'SET_POLYGON',
        SET_EDITION_MODE: 'SET_EDITION_MODE',
        SET_DATA: 'SET_DATA'
    },

    ESPECE: {
        LOADING: 'LOADING_ESPECE',
        LOADING_VALIDATION_PROCESS: 'LOADING_VALIDATION_PROCESS_ESPECE',
        LIST: 'LIST_ESPECE',
        FULL_LIST: 'FULL_LIST_ESPECE',
        COMBO: 'COMBO_ESPECE',
        ADD_NEW: 'ADD_ONE_ESPECE',
        SET_SELECTED: 'SET_SELECTED_ESPECE',
        EDIT_SELECTED: 'EDIT_SELECTED_ESPECE',
        CANCEL_EDIT: 'CANCEL_EDIT_ESPECE',
        DATA_SAVED: 'DATA_SAVED_ESPECE',
        SET_LAZY_PARAMS: 'SET_LAZY_PARAMS_ESPECE',
        SET_GLOBAL_FILTER: 'SET_GLOBAL_FILTER_ESPECE',
        SHOW_DELETE_DIALOG: 'SHOW_DELETE_DIALOG_ESPECE',
    },

    SITE: {
        LOADING: 'LOADING_SITE',
        LOADING_VALIDATION_PROCESS: 'LOADING_VALIDATION_PROCESS_SITE',
        LIST: 'LIST_SITE',
        FULL_LIST: 'FULL_LIST_SITE',
        COMBO: 'COMBO_SITE',
        ADD_NEW: 'ADD_ONE_SITE',
        SET_SELECTED: 'SET_SELECTED_SITE',
        EDIT_SELECTED: 'EDIT_SELECTED_SITE',
        CANCEL_EDIT: 'CANCEL_EDIT_SITE',
        DATA_SAVED: 'DATA_SAVED_SITE',
        SET_LAZY_PARAMS: 'SET_LAZY_PARAMS_SITE',
        SET_GLOBAL_FILTER: 'SET_GLOBAL_FILTER_SITE',
        SHOW_DELETE_DIALOG: 'SHOW_DELETE_DIALOG_SITE',
    },
    SITE_DESCRIPTION: {
        LOADING: 'LOADING_SITE_DESCRIPTION',
        LOADING_VALIDATION_PROCESS: 'LOADING_VALIDATION_PROCESS_SITE_DESCRIPTION',
        LIST: 'LIST_SITE_DESCRIPTION',
        FULL_LIST: 'FULL_LIST_SITE_DESCRIPTION',
        COMBO: 'COMBO_SITE_DESCRIPTION',
        ADD_NEW: 'ADD_ONE_SITE_DESCRIPTION',
        SET_SELECTED: 'SET_SELECTED_SITE_DESCRIPTION',
        EDIT_SELECTED: 'EDIT_SELECTED_SITE_DESCRIPTION',
        CANCEL_EDIT: 'CANCEL_EDIT_SITE_DESCRIPTION',
        DATA_SAVED: 'DATA_SAVED_SITE_DESCRIPTION',
        SET_LAZY_PARAMS: 'SET_LAZY_PARAMS_SITE_DESCRIPTION',
        SET_GLOBAL_FILTER: 'SET_GLOBAL_FILTER_SITE_DESCRIPTION',
        SHOW_DELETE_DIALOG: 'SHOW_DELETE_DIALOG_SITE_DESCRIPTION',
    },
    SITE_VALEUR: {
        LOADING: 'LOADING_SITE_VALEUR',
        LOADING_VALIDATION_PROCESS: 'LOADING_VALIDATION_PROCESS_SITE_VALEUR',
        LIST: 'LIST_SITE_VALEUR',
        FULL_LIST: 'FULL_LIST_SITE_VALEUR',
        COMBO: 'COMBO_SITE_VALEUR',
        ADD_NEW: 'ADD_ONE_SITE_VALEUR',
        SET_SELECTED: 'SET_SELECTED_SITE_VALEUR',
        EDIT_SELECTED: 'EDIT_SELECTED_SITE_VALEUR',
        CANCEL_EDIT: 'CANCEL_EDIT_SITE_VALEUR',
        DATA_SAVED: 'DATA_SAVED_SITE_VALEUR',
        SET_LAZY_PARAMS: 'SET_LAZY_PARAMS_SITE_VALEUR',
        SET_GLOBAL_FILTER: 'SET_GLOBAL_FILTER_SITE_VALEUR',
        SHOW_DELETE_DIALOG: 'SHOW_DELETE_DIALOG_SITE_VALEUR',
    },



    RECENECEMENT: {
        LOADING: 'LOADING_RECENCEMENT',
        LOADING_VALIDATION_PROCESS: 'LOADING_VALIDATION_PROCESS_RECENCEMENT',
        LIST: 'LIST_RECENCEMENT',
        FULL_LIST: 'FULL_LIST_RECENCEMENT',
        COMBO: 'COMBO_RECENCEMENT',
        ADD_NEW: 'ADD_ONE_RECENCEMENT',
        SET_SELECTED: 'SET_SELECTED_RECENCEMENT',
        EDIT_SELECTED: 'EDIT_SELECTED_RECENCEMENT',
        CANCEL_EDIT: 'CANCEL_EDIT_RECENCEMENT',
        DATA_SAVED: 'DATA_SAVED_RECENCEMENT',
        SET_LAZY_PARAMS: 'SET_LAZY_PARAMS_RECENCEMENT',
        SET_GLOBAL_FILTER: 'SET_GLOBAL_FILTER_RECENCEMENT',
        SHOW_DELETE_DIALOG: 'SHOW_DELETE_DIALOG_RECENCEMENT',
    },

    SITE_DELIMITATION: {
        LOADING: 'LOADING_SITE_DELIMITATION',
        LOADING_VALIDATION_PROCESS: 'LOADING_VALIDATION_PROCESS_SITE_DELIMITATION',
        LIST: 'LIST_SITE_DELIMITATION',
        FULL_LIST: 'FULL_LIST_SITE_DELIMITATION',
        COMBO: 'COMBO_SITE_DELIMITATION',
        ADD_NEW: 'ADD_ONE_SITE_DELIMITATION',
        SET_SELECTED: 'SET_SELECTED_SITE_DELIMITATION',
        EDIT_SELECTED: 'EDIT_SELECTED_SITE_DELIMITATION',
        CANCEL_EDIT: 'CANCEL_EDIT_SITE_DELIMITATION',
        DATA_SAVED: 'DATA_SAVED_SITE_DELIMITATION',
        SET_LAZY_PARAMS: 'SET_LAZY_PARAMS_SITE_DELIMITATION',
        SET_GLOBAL_FILTER: 'SET_GLOBAL_FILTER_SITE_DELIMITATION',
        SHOW_DELETE_DIALOG: 'SHOW_DELETE_DIALOG_SITE_DELIMITATION',
    },

    SITE_SYNONYME: {
        LOADING: 'LOADING_SITE_SYNONYME',
        LOADING_VALIDATION_PROCESS: 'LOADING_VALIDATION_PROCESS_SITE_SYNONYME',
        LIST: 'LIST_SITE_SYNONYME',
        FULL_LIST: 'FULL_LIST_SITE_SYNONYME',
        COMBO: 'COMBO_SITE_SYNONYME',
        ADD_NEW: 'ADD_ONE_SITE_SYNONYME',
        SET_SELECTED: 'SET_SELECTED_SITE_SYNONYME',
        EDIT_SELECTED: 'EDIT_SELECTED_SITE_SYNONYME',
        CANCEL_EDIT: 'CANCEL_EDIT_SITE_SYNONYME',
        DATA_SAVED: 'DATA_SAVED_SITE_SYNONYME',
        SET_LAZY_PARAMS: 'SET_LAZY_PARAMS_SITE_SYNONYME',
        SET_GLOBAL_FILTER: 'SET_GLOBAL_FILTER_SITE_SYNONYME',
        SHOW_DELETE_DIALOG: 'SHOW_DELETE_DIALOG_SITE_SYNONYME',
    },

    SITE_REGIME_FONCIER: {
        LOADING: 'LOADING_SITE_REGIME_FONCIER',
        LOADING_VALIDATION_PROCESS: 'LOADING_VALIDATION_PROCESS_SITE_REGIME_FONCIER',
        LIST: 'LIST_SITE_REGIME_FONCIER',
        FULL_LIST: 'FULL_LIST_SITE_REGIME_FONCIER',
        COMBO: 'COMBO_SITE_REGIME_FONCIER',
        ADD_NEW: 'ADD_ONE_SITE_REGIME_FONCIER',
        SET_SELECTED: 'SET_SELECTED_SITE_REGIME_FONCIER',
        EDIT_SELECTED: 'EDIT_SELECTED_SITE_REGIME_FONCIER',
        CANCEL_EDIT: 'CANCEL_EDIT_SITE_REGIME_FONCIER',
        DATA_SAVED: 'DATA_SAVED_SITE_REGIME_FONCIER',
        SET_LAZY_PARAMS: 'SET_LAZY_PARAMS_SITE_REGIME_FONCIER',
        SET_GLOBAL_FILTER: 'SET_GLOBAL_FILTER_SITE_REGIME_FONCIER',
        SHOW_DELETE_DIALOG: 'SHOW_DELETE_DIALOG_SITE_REGIME_FONCIER',
    },

    SITE_STATUT_CONSERVATION: {
        LOADING: 'LOADING_SITE_STATUT_CONSERVATION',
        LOADING_VALIDATION_PROCESS: 'LOADING_VALIDATION_PROCESS_SITE_STATUT_CONSERVATION',
        LIST: 'LIST_SITE_STATUT_CONSERVATION',
        FULL_LIST: 'FULL_LIST_SITE_STATUT_CONSERVATION',
        COMBO: 'COMBO_SITE_STATUT_CONSERVATION',
        ADD_NEW: 'ADD_ONE_SITE_STATUT_CONSERVATION',
        SET_SELECTED: 'SET_SELECTED_SITE_STATUT_CONSERVATION',
        EDIT_SELECTED: 'EDIT_SELECTED_SITE_STATUT_CONSERVATION',
        CANCEL_EDIT: 'CANCEL_EDIT_SITE_STATUT_CONSERVATION',
        DATA_SAVED: 'DATA_SAVED_SITE_STATUT_CONSERVATION',
        SET_LAZY_PARAMS: 'SET_LAZY_PARAMS_SITE_STATUT_CONSERVATION',
        SET_GLOBAL_FILTER: 'SET_GLOBAL_FILTER_SITE_STATUT_CONSERVATION',
        SHOW_DELETE_DIALOG: 'SHOW_DELETE_DIALOG_SITE_STATUT_CONSERVATION',
    },

    SITE_BIOCLIMAT: {
        LOADING: 'LOADING_SITE_BIOCLIMAT',
        LOADING_VALIDATION_PROCESS: 'LOADING_VALIDATION_PROCESS_SITE_BIOCLIMAT',
        LIST: 'LIST_SITE_BIOCLIMAT',
        FULL_LIST: 'FULL_LIST_SITE_BIOCLIMAT',
        COMBO: 'COMBO_SITE_BIOCLIMAT',
        ADD_NEW: 'ADD_ONE_SITE_BIOCLIMAT',
        SET_SELECTED: 'SET_SELECTED_SITE_BIOCLIMAT',
        EDIT_SELECTED: 'EDIT_SELECTED_SITE_BIOCLIMAT',
        CANCEL_EDIT: 'CANCEL_EDIT_SITE_BIOCLIMAT',
        DATA_SAVED: 'DATA_SAVED_SITE_BIOCLIMAT',
        SET_LAZY_PARAMS: 'SET_LAZY_PARAMS_SITE_BIOCLIMAT',
        SET_GLOBAL_FILTER: 'SET_GLOBAL_FILTER_SITE_BIOCLIMAT',
        SHOW_DELETE_DIALOG: 'SHOW_DELETE_DIALOG_SITE_BIOCLIMAT',
    },

    SITE_REDACTEUR: {
        LOADING: 'LOADING_SITE_REDACTEUR',
        LOADING_VALIDATION_PROCESS: 'LOADING_VALIDATION_PROCESS_SITE_REDACTEUR',
        LIST: 'LIST_SITE_REDACTEUR',
        FULL_LIST: 'FULL_LIST_SITE_REDACTEUR',
        COMBO: 'COMBO_SITE_REDACTEUR',
        ADD_NEW: 'ADD_ONE_SITE_REDACTEUR',
        SET_SELECTED: 'SET_SELECTED_SITE_REDACTEUR',
        EDIT_SELECTED: 'EDIT_SELECTED_SITE_REDACTEUR',
        CANCEL_EDIT: 'CANCEL_EDIT_SITE_REDACTEUR',
        DATA_SAVED: 'DATA_SAVED_SITE_REDACTEUR',
        SET_LAZY_PARAMS: 'SET_LAZY_PARAMS_SITE_REDACTEUR',
        SET_GLOBAL_FILTER: 'SET_GLOBAL_FILTER_SITE_REDACTEUR',
        SHOW_DELETE_DIALOG: 'SHOW_DELETE_DIALOG_SITE_REDACTEUR',
    },

    TYPE_FONCIER: {
        LOADING: 'LOADING_TYPE_FONCIER',
        LOADING_VALIDATION_PROCESS: 'LOADING_VALIDATION_PROCESS_TYPE_FONCIER',
        LIST: 'LIST_TYPE_FONCIER',
        FULL_LIST: 'FULL_LIST_TYPE_FONCIER',
        COMBO: 'COMBO_TYPE_FONCIER',
        ADD_NEW: 'ADD_ONE_TYPE_FONCIER',
        SET_SELECTED: 'SET_SELECTED_TYPE_FONCIER',
        EDIT_SELECTED: 'EDIT_SELECTED_TYPE_FONCIER',
        CANCEL_EDIT: 'CANCEL_EDIT_TYPE_FONCIER',
        DATA_SAVED: 'DATA_SAVED_TYPE_FONCIER',
        SET_LAZY_PARAMS: 'SET_LAZY_PARAMS_TYPE_FONCIER',
        SET_GLOBAL_FILTER: 'SET_GLOBAL_FILTER_TYPE_FONCIER',
        SHOW_DELETE_DIALOG: 'SHOW_DELETE_DIALOG_TYPE_FONCIER',
    },

    TYPE_BIOCLIMAT: {
        LOADING: 'LOADING_TYPE_BIOCLIMAT',
        LOADING_VALIDATION_PROCESS: 'LOADING_VALIDATION_PROCESS_TYPE_BIOCLIMAT',
        LIST: 'LIST_TYPE_BIOCLIMAT',
        FULL_LIST: 'FULL_LIST_TYPE_BIOCLIMAT',
        COMBO: 'COMBO_TYPE_BIOCLIMAT',
        ADD_NEW: 'ADD_ONE_TYPE_BIOCLIMAT',
        SET_SELECTED: 'SET_SELECTED_TYPE_BIOCLIMAT',
        EDIT_SELECTED: 'EDIT_SELECTED_TYPE_BIOCLIMAT',
        CANCEL_EDIT: 'CANCEL_EDIT_TYPE_BIOCLIMAT',
        DATA_SAVED: 'DATA_SAVED_TYPE_BIOCLIMAT',
        SET_LAZY_PARAMS: 'SET_LAZY_PARAMS_TYPE_BIOCLIMAT',
        SET_GLOBAL_FILTER: 'SET_GLOBAL_FILTER_TYPE_BIOCLIMAT',
        SHOW_DELETE_DIALOG: 'SHOW_DELETE_DIALOG_TYPE_BIOCLIMAT',
    },

    TYPE_VALEUR_SITE: {
        LOADING: 'LOADING_TYPE_VALEUR_SITE',
        LOADING_VALIDATION_PROCESS: 'LOADING_VALIDATION_PROCESS_TYPE_VALEUR_SITE',
        LIST: 'LIST_TYPE_VALEUR_SITE',
        FULL_LIST: 'FULL_LIST_TYPE_VALEUR_SITE',
        COMBO: 'COMBO_TYPE_VALEUR_SITE',
        ADD_NEW: 'ADD_ONE_TYPE_VALEUR_SITE',
        SET_SELECTED: 'SET_SELECTED_TYPE_VALEUR_SITE',
        EDIT_SELECTED: 'EDIT_SELECTED_TYPE_VALEUR_SITE',
        CANCEL_EDIT: 'CANCEL_EDIT_TYPE_VALEUR_SITE',
        DATA_SAVED: 'DATA_SAVED_TYPE_VALEUR_SITE',
        SET_LAZY_PARAMS: 'SET_LAZY_PARAMS_TYPE_VALEUR_SITE',
        SET_GLOBAL_FILTER: 'SET_GLOBAL_FILTER_TYPE_VALEUR_SITE',
        SHOW_DELETE_DIALOG: 'SHOW_DELETE_DIALOG_TYPE_VALEUR_SITE',
    },

    PERSONNE: {
        LOADING: 'LOADING_PERSONNE',
        LOADING_VALIDATION_PROCESS: 'LOADING_VALIDATION_PROCESS_PERSONNE',
        LIST: 'LIST_PERSONNE',
        FULL_LIST: 'FULL_LIST_PERSONNE',
        COMBO: 'COMBO_PERSONNE',
        ADD_NEW: 'ADD_ONE_PERSONNE',
        SET_SELECTED: 'SET_SELECTED_PERSONNE',
        EDIT_SELECTED: 'EDIT_SELECTED_PERSONNE',
        CANCEL_EDIT: 'CANCEL_EDIT_PERSONNE',
        DATA_SAVED: 'DATA_SAVED_PERSONNE',
        SET_LAZY_PARAMS: 'SET_LAZY_PARAMS_PERSONNE',
        SET_GLOBAL_FILTER: 'SET_GLOBAL_FILTER_PERSONNE',
        SHOW_DELETE_DIALOG: 'SHOW_DELETE_DIALOG_PERSONNE',
    },

    REC_IMPORTATION: {
        LOADING: 'LOADING_REC_IMPORTATION',
        LOADING_VALIDATION_PROCESS: 'LOADING_VALIDATION_PROCESS_REC_IMPORTATION',
        LIST: 'LIST_REC_IMPORTATION',
        FULL_LIST: 'FULL_LIST_REC_IMPORTATION',
        COMBO: 'COMBO_REC_IMPORTATION',
        ADD_NEW: 'ADD_ONE_REC_IMPORTATION',
        SET_SELECTED: 'SET_SELECTED_REC_IMPORTATION',
        EDIT_SELECTED: 'EDIT_SELECTED_REC_IMPORTATION',
        CANCEL_EDIT: 'CANCEL_EDIT_REC_IMPORTATION',
        DATA_SAVED: 'DATA_SAVED_REC_IMPORTATION',
        SET_LAZY_PARAMS: 'SET_LAZY_PARAMS_REC_IMPORTATION',
        SET_GLOBAL_FILTER: 'SET_GLOBAL_FILTER_REC_IMPORTATION',
        SHOW_DELETE_DIALOG: 'SHOW_DELETE_DIALOG_REC_IMPORTATION',
    },


    LOGIN: 'LOGIN',
    LOGOUT: 'LOGOUT',
    LOGIN_ERRORS: 'LOGIN_ERRORS',
    IS_LOGGED_IN: 'IS_LOGGED_IN',

    SITE_LIST: "SITE_LIST",

    LAYOUT_MODE: "LAYOUT_MODE",
    LAYOUT_COLOR: "LAYOUT_COLOR",
    INPUT_STYLE: "INPUT_STYLE",
    RIPPLE: "RIPPLE",
    STATIC_MENU_ACTIVE: "STATIC_MENU_ACTIVE",
    OVERLAY_MENU_ACTIVE: "OVERLAY_MENU_ACTIVE",
    MOBIEL_MENU_ACTIVE: "MOBIEL_MENU_ACTIVE",
    MOBILE_TOP_BAR_MENU_ACTIVE: "MOBILE_TOP_BAR_MENU_ACTIVE",


    SET_RECENECEMENT_LIST: "SET_RECENECEMENTS_LIST",
    SET_RECENECEMENT_COMBO: "SET_RECENECEMENTS_COMBO",
    SET_RECENECEMENT_COMBO_YEAR: "SET_RECENECEMENTS_COMBO_YEAR",
    SET_RECENECEMENT_NBR_BY_YEAR_SITE_ESPECE: "SET_RECENECEMENTS_NBR_BY_YEAR_SITE_ESPECE",
    SET_RECENECEMENT_NBR_BY_YEAR: "SET_RECENECEMENTS_NBR_BY_YEAR",
    SET_RECENECEMENT_EFFECTIF_BY_YEAR: "SET_RECENECEMENTS_EFFECTIF_BY_YEAR",
    SET_RECENECEMENT_ADDED: "SET_RECENECEMENTS_ADDED",
    SET_RECENECEMENT_UPDATED: "SET_RECENECEMENTS_UPDATED",
    SET_RECENECEMENT_DELETED: "SET_RECENECEMENTS_DELETED",

    SET_SITE_LIST: "LOAD_SITE_LIST",
    SET_SITE_COMBO: "SET_SITE_COMBO",
    SET_SITE_LOADING: "SET_SITE_LOADING",
    SET_SELECTED_SITE: "SET_SELECTED_SITE",
    SET_SITE_FORM_SUBMITTED: "SET_SITE_FORM_SUBMITTED",
    SET_SITE_SHOW_MESSAGE: "SET_SITE_SHOW_MESSAGE",
    ADD_NEW_SITE: "ADD_NEW_SITE",
    SET_DELETE_SITE_DIALOG: "SET_DELETE_SITE_DIALOG",
    EDIT_SELECTED_SITE: "EDIT_SELECTED_SITE",
    CANCEL_EDIT_SELECTED_SITE: "CANCEL_EDIT_SELECTED_SITE",
    SET_SITE_LAZY_PARAMS: "SET_SITE_LAZY_PARAMS",
    SET_SITE_GLOBAL_FILTER: "SET_SITE_GLOBAL_FILTER",
    SET_SITE_EXPANDED_ROWS: "SET_SITE_EXPANDED_ROWS",


    SET_REGION_NATURELLE_LIST: "LOAD_REGION_NATURELLE_LIST",
    SET_REGION_NATURELLE_COMBO: "SET_REGION_NATURELLE_COMBO",
    SET_REGION_NATURELLE_LOADING: "SET_REGION_NATURELLE_LOADING",
    SET_SELECTED_REGION_NATURELLE: "SET_SELECTED_REGION_NATURELLE",
    SET_REGION_NATURELLE_FORM_SUBMITTED: "SET_REGION_NATURELLE_FORM_SUBMITTED",
    SET_REGION_NATURELLE_SHOW_MESSAGE: "SET_REGION_NATURELLE_SHOW_MESSAGE",
    ADD_NEW_REGION_NATURELLE: "ADD_NEW_REGION_NATURELLE",
    SET_DELETE_REGION_NATURELLE_DIALOG: "SET_DELETE_REGION_NATURELLE_DIALOG",
    EDIT_SELECTED_REGION_NATURELLE: "EDIT_SELECTED_REGION_NATURELLE",
    CANCEL_EDIT_SELECTED_REGION_NATURELLE: "CANCEL_EDIT_SELECTED_REGION_NATURELLE",
    SET_REGION_NATURELLE_LAZY_PARAMS: "SET_REGION_NATURELLE_LAZY_PARAMS",
    SET_REGION_NATURELLE_GLOBAL_FILTER: "SET_REGION_NATURELLE_GLOBAL_FILTER",
    SET_REGION_NATURELLE_EXPANDED_ROWS: "SET_REGION_NATURELLE_EXPANDED_ROWS",


    SET_COMPLEXE_LIST: "LOAD_COMPLEXE_LIST",
    SET_COMPLEXE_COMBO: "SET_COMPLEXE_COMBO",
    SET_COMPLEXE_LOADING: "SET_COMPLEXE_LOADING",
    SET_SELECTED_COMPLEXE: "SET_SELECTED_COMPLEXE",
    SET_COMPLEXE_FORM_SUBMITTED: "SET_COMPLEXE_FORM_SUBMITTED",
    SET_COMPLEXE_SHOW_MESSAGE: "SET_COMPLEXE_SHOW_MESSAGE",
    ADD_NEW_COMPLEXE: "ADD_NEW_COMPLEXE",
    SET_DELETE_COMPLEXE_DIALOG: "SET_DELETE_COMPLEXE_DIALOG",
    EDIT_SELECTED_COMPLEXE: "EDIT_SELECTED_COMPLEXE",
    CANCEL_EDIT_SELECTED_COMPLEXE: "CANCEL_EDIT_SELECTED_COMPLEXE",
    SET_COMPLEXE_LAZY_PARAMS: "SET_COMPLEXE_LAZY_PARAMS",
    SET_COMPLEXE_GLOBAL_FILTER: "SET_COMPLEXE_GLOBAL_FILTER",
    SET_COMPLEXE_EXPANDED_ROWS: "SET_COMPLEXE_EXPANDED_ROWS",


    SET_TYPESITE_LIST: "LOAD_TYPESITE_LIST",
    SET_TYPESITE_COMBO: "SET_TYPESITE_COMBO",
    SET_TYPESITE_LOADING: "SET_TYPESITE_LOADING",
    SET_SELECTED_TYPESITE: "SET_SELECTED_TYPESITE",
    SET_TYPESITE_FORM_SUBMITTED: "SET_TYPESITE_FORM_SUBMITTED",
    SET_TYPESITE_SHOW_MESSAGE: "SET_TYPESITE_SHOW_MESSAGE",
    ADD_NEW_TYPESITE: "ADD_NEW_TYPESITE",
    SET_DELETE_TYPESITE_DIALOG: "SET_DELETE_TYPESITE_DIALOG",
    EDIT_SELECTED_TYPESITE: "EDIT_SELECTED_TYPESITE",
    CANCEL_EDIT_SELECTED_TYPESITE: "CANCEL_EDIT_SELECTED_TYPESITE",
    SET_TYPESITE_LAZY_PARAMS: "SET_TYPESITE_LAZY_PARAMS",
    SET_TYPESITE_GLOBAL_FILTER: "SET_TYPESITE_GLOBAL_FILTER",
    SET_TYPESITE_EXPANDED_ROWS: "SET_TYPESITE_EXPANDED_ROWS",


    SET_DECOUPAGE_ADMIN: "SET_DECOUPAGE_ADMIN",
    FILTER_PROVINCE_ADMIN: "FILTER_PROVINCE_ADMIN",
    FILTER_COMMUNE_ADMIN: "FILTER_COMMUNE_ADMIN",


    SET_LOAD_SITE_DESCRIPTION: "SET_LOAD_SITE_DESCRIPTION",
    SET_EDIT_SELECTED_SITE_DESCRIPTION: "SET_EDIT_SELECTED_SITE_DESCRIPTION",
    SET_SAVE_SITE_DESCRIPTION: "SET_SAVE_SITE_DESCRIPTION",
    SET_SELECTED_SITE_DESCRIPTION: "SET_SELECTED_SITE_DESCRIPTION",

    SET_LOAD_SITE_RECHERCHE_SC: "SET_LOAD_SITE_RECHERCHE_SC",
    SET_EDIT_SELECTED_SITE_RECHERCHE_SC: "SET_EDIT_SELECTED_SITE_RECHERCHE_SC",
    SET_SAVE_SITE_RECHERCHE_SC: "SET_SAVE_SITE_RECHERCHE_SC",
    SET_SELECTED_SITE_RECHERCHE_SC: "SET_SELECTED_SITE_RECHERCHE_SC",


    SET_LOAD_SITE_BASSINS_VERSANT: "SET_LOAD_SITE_BASSINS_VERSANT",
    SET_EDIT_SELECTED_SITE_BASSINS_VERSANT: "SET_EDIT_SELECTED_SITE_BASSINS_VERSANT",
    SET_SAVE_SITE_BASSINS_VERSANT: "SET_SAVE_SITE_BASSINS_VERSANT",
    SET_SELECTED_SITE_BASSINS_VERSANT: "SET_SELECTED_SITE_BASSINS_VERSANT",


    SET_SITE_SYNONYME_LIST: "LOAD_SITE_SYNONYME_LIST",
    SET_SITE_SYNONYME_COMBO: "SET_SITE_SYNONYME_COMBO",
    SET_SITE_SYNONYME_LOADING: "SET_SITE_SYNONYME_LOADING",
    SET_SELECTED_SITE_SYNONYME: "SET_SELECTED_SITE_SYNONYME",
    SET_SITE_SYNONYME_SHOW_MESSAGE: "SET_SITE_SYNONYME_SHOW_MESSAGE",
    ADD_NEW_SITE_SYNONYME: "ADD_NEW_SITE_SYNONYME",
    SET_DELETE_SITE_SYNONYME_DIALOG: "SET_DELETE_SITE_SYNONYME_DIALOG",
    EDIT_SELECTED_SITE_SYNONYME: "EDIT_SELECTED_SITE_SYNONYME",
    CANCEL_EDIT_SELECTED_SITE_SYNONYME: "CANCEL_EDIT_SELECTED_SITE_SYNONYME",
    SET_SITE_SYNONYME_LAZY_PARAMS: "SET_SITE_SYNONYME_LAZY_PARAMS",
    SET_SITE_SYNONYME_GLOBAL_FILTER: "SET_SITE_SYNONYME_GLOBAL_FILTER",
    SET_HIDE_NEW_SITE_SYNONYME: "SET_HIDE_NEW_SITE_SYNONYME",




}
