import React from 'react';
import {Button} from "primereact/button";

const ImportationActionBodyTemplate = (props) => {
    return (<div className="actions">
        {props.executeimport && <Button icon="pi pi-play" className="p-button-rounded p-button-secondary mr-2" onClick={(e) => props.executeimport(props.data)}/>}
        {props.editRecord && <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={(e) => props.editRecord(props.data)}/>}
        {props.deleteRecord && <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2" onClick={(e) => props.deleteRecord(props.data)}/>}
    </div>);
}

export default ImportationActionBodyTemplate



