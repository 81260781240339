import React, {useEffect, useState} from 'react';
import {LayersControl, MapContainer, Polygon, Popup, TileLayer, Tooltip} from 'react-leaflet'
import "react-leaflet-fullscreen/dist/styles.css";
import "leaflet-draw/dist/leaflet.draw.css";
import {SiteService} from "../../../service/espace/SiteService";

import {connect} from "react-redux";
import {Login} from "../../../redux/UserActions";

const center = [31.558059, -7.483406,]

const SitesMapPolygonsPage = (props) => {

    let loadLazyTimeout = null;
    //const [loading, setLoading] = useState(false);

    const [listOfSite, setListOfSite] = useState();
    const sitesService = new SiteService();

    useEffect(() => {
        loadLazyData()
    })


    const loadLazyData = () => {
        //setLoading(true);
        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }
        loadLazyTimeout = setTimeout(() => {
            sitesService.getAll('', props.authToken).then(data => {
                setListOfSite(data);
                //setLoading(false);
            });
        }, Math.random() * 1000 + 250);
    }


    return (<div className="grid">

        <div className="col-12">
            <div className="card">
                <div className="col-12">
                    <h3 style={{textAlign: center}}>Carte des sites nationales</h3>
                </div>
                {/*whenCreated={mapInstance => { mapRef.current = mapInstance}}*/}
                <MapContainer center={center} zoom={6} zoomControl={true}>
                    <LayersControl position="topright">
                        <LayersControl.BaseLayer checked name="Esri, DigitalGlobe">
                            <TileLayer
                                attribution="Esri, DigitalGlobe, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community"
                                className="basemap"
                                maxNativeZoom={19}
                                maxZoom={19}
                                subdomains={["clarity"]}
                                url="https://{s}.maptiles.arcgis.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                            />
                        </LayersControl.BaseLayer>
                        <LayersControl.BaseLayer name="OpenStreetMap">
                            <TileLayer
                                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                        </LayersControl.BaseLayer>
                        <LayersControl.BaseLayer name="Dark layer">
                            <TileLayer
                                attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
                                url="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png"
                            />
                        </LayersControl.BaseLayer>
                    </LayersControl>
                    {listOfSite && listOfSite.map(item => ((item.delimitations != null && <Polygon fillColor='cyan' key={item.id} pathOptions={{color: '#f5ff00'}} positions={item.delimitations.coordinates}>
                        <Popup>
                            {item.site_nom}
                        </Popup>
                        <Tooltip sticky offset={[0, 20]} direction="center" opacity={1} className="my-labels">{item.site_nom}</Tooltip>
                    </Polygon>)))}
                </MapContainer>
            </div>
        </div>
    </div>);
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

const mapStateToProps = state => {
    return {
        authToken: state.userReducer.authToken,
        isLoggedIn: state.userReducer.isLoggedIn,
    };
};
export default connect(mapStateToProps, {Login})(React.memo(SitesMapPolygonsPage, comparisonFn))
