import React, {useEffect, useRef, useState} from 'react';
import classNames from "classnames";
import {Field, Form} from 'react-final-form';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';

import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {Toolbar} from "primereact/toolbar";
import ErrorMessageTemplate from "../../components/errorMessageTemplate";
import RightToolbarTemplate from "../../components/rightToolbarTemplate";
import LeftToolbarTemplate from "../../components/leftToolbarTemplate";
import ActionBodyTemplate from "../../components/actionBodyTemplate";
import DatatableHeaderTemplate from "../../components/datatableHeaderTemplate";
import {composeValidators, getFormErrorMessage, isFormFieldValid, required} from "../../utils";
import '../../template/App.css';
import {EspeceNatService} from "../../service/espece/EspeceNatService";
import {Dropdown} from "primereact/dropdown";
import {connect} from "react-redux";
import {Login} from "../../redux/UserActions";


const EspeceNatPage = (props) => {
    const dt = useRef(null);


    const especeNatService = new EspeceNatService();

    let emptyRecord = {
        id: null,
        code: '',
        nom: '',
        note: '',
    };
    const [showMessage, setShowMessage] = useState(false);


    const [expandedRows, setExpandedRows] = useState(null);

    const [rows1, setRows1] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    let loadLazyTimeout = null;
    const [lazyParams, setLazyParams] = useState({
        first: 0, rows: 10, page: 0, sortField: null, sortOrder: null, filters: {
            'codenat': {value: '', matchMode: 'contains'},
            'ordre': {value: '', matchMode: 'contains'},
            'famille': {value: '', matchMode: 'contains'},
            'genre': {value: '', matchMode: 'contains'},
            'espece': {value: '', matchMode: 'contains'},
            'sespece': {value: '', matchMode: 'contains'},
            'global': {value: '', matchMode: 'contains'},
        }
    });

    const [dialogTitle, setDialogTitle] = useState('');
    const [recordDialog, setRecordDialog] = useState(false);
    const [deleteSiteDialog, setDeleteSiteDialog] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(emptyRecord);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [loading, setLoading] = useState(false);

    const [recordList, setRecordList] = useState(null);


    const niveauEspece = [
        {libelle: 'Ordre', value: 'ordre'},
        {libelle: 'Famille', code: 'famille'},
        {libelle: 'Genre', code: 'genre'},
        {libelle: 'Espèce', code: 'espece'},
        {libelle: 'Sous espèce', code: 'sespece'}
    ];

    useEffect(() => {
        loadLazyData();
    }, [lazyParams]) // eslint-disable-line react-hooks/exhaustive-deps


    const loadLazyData = () => {
        setLoading(true);
        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }
        loadLazyTimeout = setTimeout(() => {
            especeNatService.getList(lazyParams, props.authToken).then(data => {
                setTotalRecords(data.count);
                setRecordList(data.results);
                setLoading(false);
            });
        }, Math.random() * 1000 + 250);
    }

    const onPage = (event) => {
        setRows1(event.rows);
        setLazyParams(event);
        event['page'] = event['page'] + 1
    }

    const onSort = (event) => {
        setLazyParams(event);
    }

    const onFilter = (event) => {
        event['first'] = 1;
        setLazyParams(event);
    }

    const onSelectionChange = (event) => {
        const value = event.value;
        setSelectedRecord(value);
    }


    const identity = value => (value);

    const validate = (data) => {
        let errors = {};
        if (!data.code) {
            errors.code = 'Le code est obligatoire';
        }
        if (!data.nom) {
            errors.nom = 'Le nom est obligatoire';
        }
        return errors;
    };


    const newRecord = () => {
        setDialogTitle('Nouveau type site')
        setSelectedRecord(emptyRecord);
        setSubmitted(false);
        setRecordDialog(true);
    }

    const editRecord = (selectedRecord) => {

        setDialogTitle(selectedRecord.id === null ? 'Nouveau site' : `Edition site: (${selectedRecord.code}) ${selectedRecord.nom}`)
        setSelectedRecord(selectedRecord);
        setRecordDialog(true);
    }

    const [addedRecord, setAddedRecor] = useState({});
    useEffect(() => {


    }, [recordList])

    const saveRecord = async (data, form) => {
        setSubmitted(true);
        setShowMessage(true);
        if (data.code.trim() && data.nom.trim()) {
            let _recordList = [...recordList];
            let _record = {...data}
            if (data.id) {
                const index = findIndexById(data.id);
                especeNatService.updateOne(data, props.authToken).then((res) => {

                    _recordList[index] = _record;
                    setRecordList(_recordList);
                    //toast.current.show({severity: 'success', summary: 'Successful', detail: 'Le type site a été mis a jour avec succés', life: 3000});
                    form.reset()
                }).catch(error => {


                })
            } else {
                especeNatService.addOne(data, props.authToken).then((res) => {
                    lazyParams.sortField = 'id';
                    lazyParams.sortOrder = -1
                    setLazyParams(lazyParams)
                    loadLazyData()
                    //toast.current.show({severity: 'success', summary: 'Successful', detail: 'Le type site a été crée avec succès', life: 3000});
                    form.reset()
                }).catch(error => {


                })
            }
            setRecordDialog(false);
            setSelectedRecord(emptyRecord);
        }
    }

    const hideDialog = () => {
        setSubmitted(false);
        setRecordDialog(false);
    }

    const hideDeleteSiteDialog = () => {
        setDeleteSiteDialog(false);
    }


    const confirmDeleteSite = (selectedRecord) => {

        setSelectedRecord(selectedRecord);
        setDeleteSiteDialog(true);
    }

    const deleteSite = () => {

        //return;
        especeNatService.deleteOne(selectedRecord.id, props.authToken).then((res) => {

            if (res.error) {
                setDeleteSiteDialog(false);
                //toast.current.show({severity: 'error', summary: 'Opération annulé', detail: res.error, life: 3000});
            } else {
                let _sitesList = recordList.filter(val => val.id !== selectedRecord.id);
                setRecordList(_sitesList);
                setDeleteSiteDialog(false);
                setSelectedRecord(emptyRecord);
                //toast.current.show({severity: 'success', summary: 'Successful', detail: "L'enregistrement a bien été supprimé", life: 3000});
            }

        }).catch(error => {


        })
    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < recordList.length; i++) {
            if (recordList[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }


    const confirmDeleteSelected = () => {
        setDeleteSiteDialog(true);
    }


    const onNiveauChange = (v) => {

    }


    const siteDialogFooter = (
        <>
            <Button label="Annuler" icon="pi pi-times" className="p-button-text" onClick={hideDialog}/>
            <Button label="Enregistrer" icon="pi pi-check" type="submit" form="siteForm"/>
        </>
    );
    const deleteSiteDialogFooter = (
        <>
            <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideDeleteSiteDialog}/>
            <Button label="Oui" icon="pi pi-check" className="p-button-text" onClick={deleteSite}/>
        </>
    );


    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toolbar
                        className="mb-4"
                        left={<LeftToolbarTemplate
                            addNew={newRecord}
                            confirmDelete={confirmDeleteSelected}
                            disabled={selectedRecord.id === null}
                        />}
                        right={<RightToolbarTemplate dt={dt}/>}
                    />
                    <DataTable
                        ref={dt}
                        size="small"
                        responsiveLayout="scroll"
                        loading={loading}
                        value={recordList}
                        dataKey="id"
                        lazy
                        rows={rows1}
                        paginator first={lazyParams.first}
                        rowsPerPageOptions={[10, 20, 50]}
                        filterDisplay="row"
                        filters={lazyParams.filters}
                        sortField={lazyParams.sortField}
                        totalRecords={totalRecords}
                        selection={selectedRecord}
                        sortOrder={lazyParams.sortOrder}
                        onSort={onSort}
                        onFilter={onFilter}
                        onPage={onPage}
                        selectionMode="single"
                        globalFilter={globalFilter} emptyMessage="Aucune enregistrement trouvé."
                        header={
                            <DatatableHeaderTemplate
                                title="Gestion des espèces nationale"
                                setGlobalFilter={setGlobalFilter}
                            />
                        }
                        onSelectionChange={onSelectionChange}
                        scrollable scrollHeight='calc(100vh - 11rem'
                    >
                        <Column field="ordre"
                                header="Ordre"
                                showFilterMenu={false}
                                filterField="ordre"
                                sortable
                                filter
                                filterPlaceholder=""
                                style={{flexGrow: 1, flexBasis: '10%'}}
                        />

                        <Column field="famille"
                                header="Famille"
                                showFilterMenu={false}
                                filterField="famille"
                                sortable
                                filter
                                filterPlaceholder=""
                                style={{flexGrow: 1, flexBasis: '10%'}}
                        />
                        <Column field="genre"
                                header="Genre"
                                showFilterMenu={false}
                                filterField="genre"
                                sortable
                                filter
                                filterPlaceholder=""
                                style={{flexGrow: 1, flexBasis: '10%'}}
                        />
                        <Column field="espece"
                                header="Espece"
                                showFilterMenu={false}
                                filterField="espece"
                                sortable
                                filter
                                filterPlaceholder=""
                                style={{flexGrow: 1, flexBasis: '10%'}}
                        />
                        <Column field="sespece"
                                header="Sous èspece"
                                showFilterMenu={false}
                                filterField="sespece"
                                sortable
                                filter
                                filterPlaceholder=""
                                style={{flexGrow: 1, flexBasis: '10%'}}
                        />
                        <Column field="nomsc"
                                header="Nom scientifique"
                                showFilterMenu={false}
                                filterField="nomsc"
                                sortable
                                filter
                                filterPlaceholder=""
                                style={{flexGrow: 1, flexBasis: '30%'}}
                        />
                        <Column
                            body={
                                (rowData) => <ActionBodyTemplate
                                    data={rowData}
                                    editRecord={editRecord}
                                    deleteRecord={confirmDeleteSite}
                                />
                            }
                            style={{flexGrow: 1, flexBasis: '8%'}}
                        />
                    </DataTable>
                    <Form
                        onSubmit={saveRecord}
                        initialValues={selectedRecord}
                        validate={validate}
                        render={({
                                     errors,
                                     handleSubmit,
                                     touched
                                 }) => (
                            <form
                                onSubmit={handleSubmit}
                                className="p-fluid"
                                id="siteForm">
                                <Dialog
                                    modal
                                    position='top'
                                    draggable={false}
                                    maximizable
                                    style={{width: '50vw'}}
                                    className="p-fluid"
                                    visible={recordDialog}
                                    header={dialogTitle}
                                    footer={siteDialogFooter}
                                    onHide={hideDialog}
                                >
                                    <div className="col-12">
                                        <div className="p-fluid formgrid grid">
                                            <Field
                                                name="niveau"
                                                validate={
                                                    composeValidators(
                                                        required('site')
                                                    )}
                                                render={({input, meta}) => (
                                                    <div className="field col-12 md:col-3">
                                                        <label htmlFor="site" className={classNames({'p-error': isFormFieldValid(meta)})}>Site *</label>
                                                        <Dropdown
                                                            id="niveau"
                                                            {...input}
                                                            options={niveauEspece}
                                                            optionLabel="libelle"
                                                            filter
                                                            showClear
                                                            dropdown
                                                            onChange={onNiveauChange}
                                                            filterBy="libelle"
                                                            placeholder="Niveau espèce"
                                                            emptyMessage="Pas d'enregistrement"
                                                        />
                                                        {getFormErrorMessage(meta)}
                                                    </div>
                                                )}/>
                                            <div className="col-12">
                                                <ErrorMessageTemplate touched={touched} errors={errors}/>
                                            </div>
                                        </div>
                                    </div>
                                </Dialog>
                                {/*<pre>{JSON.stringify(selectedRecord, 0, 2)}</pre>*/}
                            </form>
                        )}/>

                    <Dialog visible={deleteSiteDialog} style={{width: '450px'}} header="Confirm" modal footer={deleteSiteDialogFooter} onHide={hideDeleteSiteDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{fontSize: '2rem'}}/>
                            {selectedRecord && <span>Etes-vous sûr que vous voulez supprimer<b>({selectedRecord.code}){selectedRecord.nom}</b>?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
}

const mapStateToProps = state => {
    return {
        authToken: state.userReducer.authToken,
        isLoggedIn: state.userReducer.isLoggedIn,
    };
};
export default connect(mapStateToProps, {Login})(React.memo(EspeceNatPage, comparisonFn))

