import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {addSiteValeur, cancelEditSiteValeur, loadSiteValeurs, setSelectedSiteValeur} from "../../../../redux/site/valeur/SiteValeurActions";
import SearchIcon from "../../../../components/SearchIcon";
import AccordionComponent from "./AccordionComponent";

const SiteValeur = (props) => {
    const [text, setText] = useState("");

    let loadLazyTimeout = null;

    useEffect(() => {
        if (props.selectedSite.id !== undefined && props.selectedSite.id !== null)
            loadLazyData();
        return () => clearTimeout(loadLazyTimeout);
    }, [props.selectedSite.id])


    const loadLazyData = () => {
        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }
        loadLazyTimeout = setTimeout(() => {
            props.loadSiteValeurs()
        }, Math.random() * 1000 + 250);
    }


    const filterList = (e) => {
        let text = e.target.value
        setText(text);
        if (text.length >= 3 || text.length < 1) {
            props.loadSiteValeurs(text)
        }

    }


    /*    const ActionBtns = () => {
            return <React.Fragment>
                <div className="surface-0 fixed pl-4" style={{bottom: '30px'}}>
                    <Button
                        icon="pi pi-plus"
                        className="p-button-success"
                        type="button"
                        onClick={() => props.addSiteValeur()}/>
                </div>
            </React.Fragment>
        };*/

    return (
        <div className="surface-0 ">
            <div className="minilist-header flexcolumn">
                <div className="flexrow searchbar-row">
                    <input type="text"
                           className="minilist-searchbar"
                           placeholder="Search..."
                        //value={text}
                           onChange={filterList}
                    />
                    <SearchIcon/>

                </div>
            </div>
            <div className="flexcolumn items-list-scrollbox">
                <div className="items-list">
                    {
                        props.loading ? <i className="pi pi-spin pi-spinner" style={{'fontSize': '2em'}}></i> :
                            props.dataList ? <AccordionComponent datalist={props.dataList}/> : null
                    }
                </div>
            </div>
        </div>
    );
}


const mapStateToProps = state => {
    return {
        selectedSite: state.siteReducer.selectedRecord,
        selectedSiteValeur: state.siteValeurReducer.selectedRecord,
        loading: state.siteValeurReducer.loading,
        dataList: state.siteValeurReducer.dataList,

    };
};
export default connect(mapStateToProps,
    {loadSiteValeurs, setSelectedSiteValeur, addSiteValeur, cancelEditSiteValeur}
)(SiteValeur)


