import React from 'react';
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";

const ResRowExpansionTemplate = (props) => {
    return (

        <>
            <div className="col-12 " style={{background: '#e5f5e8'}}>
                <div className="field col-12 md:col-12 ">
                    <h5>Note d'observation:</h5>
                    <p className="ml-3">{props.data.note}</p>
                    <h5>Liste des bagues:</h5>
                    <DataTable
                        className="ml-3"
                        value={props.data.donneebags}
                        scrollable
                        scrollHeight="flex"
                        size="small"
                        responsiveLayout="scroll"
                        style={{width: '100%'}}
                        emptyMessage="Aucune enregistrement"
                    >
                        <Column field="numero" header="numero" style={{minWidth: '11rem'}}></Column>
                        <Column field="archive" header="archive" style={{minWidth: '11rem'}}></Column>
                        <Column field="description" header="description" style={{minWidth: '11rem'}}></Column>
                        <Column field="typebag.nom" header="typebag" style={{minWidth: '11rem'}}></Column>
                    </DataTable>
                </div>
            </div>
        </>
    );
}

export default ResRowExpansionTemplate

