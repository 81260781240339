import React, {useEffect, useRef, useState} from 'react';
import {Field, Form} from 'react-final-form';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';

import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {Toolbar} from "primereact/toolbar";
import {InputText} from "primereact/inputtext";

import '../../template/App.css';
import classNames from "classnames";
import {InputTextarea} from "primereact/inputtextarea";
import {getFormErrorMessage, isFormFieldValid} from "../../utils";
import DatatableHeaderTemplate from "../../components/datatableHeaderTemplate";
import ActionBodyTemplate from "../../components/actionBodyTemplate";
import ErrorMessageTemplate from "../../components/errorMessageTemplate";
import RightToolbarTemplate from "../../components/rightToolbarTemplate";
import LeftToolbarTemplate from "../../components/leftToolbarTemplate";

import {TypehabitatService} from "../../service/espace/TypehabitatService";
import {connect} from "react-redux";
import {Login} from "../../redux/UserActions";


const TypeHabitatPage = (props) => {
    const module_title = "Gestion des typologies d'habitats";
    const module_title_new = "Nouvelle type habitat";
    const module_title_edit = "Edition type habitat";
    const module_create_success_msg = "Le type habitat a été crée avec succès";
    const module_update_success_msg = "Le type habitat a été mis a jour avec succés";

    const dt = useRef(null);

    const typehabitatService = new TypehabitatService();

    let emptyRecord = {
        id: null,
        nom: '',
        description: '',
    };
    const [showMessage, setShowMessage] = useState(false);


    const [expandedRows, setExpandedRows] = useState(null);

    const [rows1, setRows1] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    let loadLazyTimeout = null;
    const [lazyParams, setLazyParams] = useState({
        first: 0, rows: 10, page: 0, sortField: null, sortOrder: null, filters: {
            'nom': {value: '', matchMode: 'contains'},
            'description': {value: '', matchMode: 'contains'},
            'global': {value: '', matchMode: 'contains'},
        }
    });

    const [dialogTitle, setDialogTitle] = useState('');
    const [recordDialog, setRecordDialog] = useState(false);
    const [deleteSiteDialog, setDeleteSiteDialog] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(emptyRecord);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [loading, setLoading] = useState(false);

    const [recordList, setRecordList] = useState(null);


    useEffect(() => {
        loadLazyData();
    }, [lazyParams]) // eslint-disable-line react-hooks/exhaustive-deps


    const loadLazyData = () => {
        setLoading(true);
        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }
        loadLazyTimeout = setTimeout(() => {
            typehabitatService.getList(lazyParams, props.authToken).then(data => {
                setTotalRecords(data.count);
                setRecordList(data.results);
                setLoading(false);
            });
        }, Math.random() * 1000 + 250);
    }

    const onPage = (event) => {
        setRows1(event.rows);
        setLazyParams(event);
        event['page'] = event['page'] + 1
    }

    const onSort = (event) => {
        setLazyParams(event);
    }

    const onFilter = (event) => {
        event['first'] = 1;
        setLazyParams(event);
    }

    const onSelectionChange = (event) => {
        const value = event.value;
        setSelectedRecord(value);
    }


    const identity = value => (value);

    const validate = (data) => {
        let errors = {};
        if (!data.nom) {
            errors.nom = 'Le nom est obligatoire';
        }
        if (!data.description) {
            errors.description = 'La description est obligatoire';
        }
        return errors;
    };


    const newRecord = () => {
        setDialogTitle(module_title_new)
        setSelectedRecord(emptyRecord);
        setSubmitted(false);
        setRecordDialog(true);
    }

    const editRecord = (selectedRecord) => {

        setDialogTitle(selectedRecord.id === null ? module_title_new : `${module_title_edit}: (${selectedRecord.nom}) `)
        setSelectedRecord(selectedRecord);
        setRecordDialog(true);
    }

    const [addedRecord, setAddedRecor] = useState({});
    useEffect(() => {


    }, [recordList])

    const saveRecord = async (data, form) => {
        setSubmitted(true);
        setShowMessage(true);
        if (data.nom.trim() && data.description.trim()) {
            let _recordList = [...recordList];
            let _record = {...data}
            if (data.id) {
                const index = findIndexById(data.id);
                typehabitatService.updateOne(data, props.authToken).then((res) => {
                    _recordList[index] = _record;
                    setRecordList(_recordList);
                    //toast.current.show({severity: 'success', summary: 'Successful', detail: module_update_success_msg, life: 3000});
                    form.reset()
                }).catch(error => {


                })
            } else {
                typehabitatService.addOne(data, props.authToken).then((res) => {
                    lazyParams.sortField = 'id';
                    lazyParams.sortOrder = -1
                    setLazyParams(lazyParams)
                    loadLazyData()
                    //toast.current.show({severity: 'success', summary: 'Successful', detail: module_create_success_msg, life: 3000});
                    form.reset()
                }).catch(error => {


                })
            }
            setRecordDialog(false);
            setSelectedRecord(emptyRecord);
        }
    }

    const hideDialog = () => {
        setSubmitted(false);
        setRecordDialog(false);
    }

    const hideDeleteSiteDialog = () => {
        setDeleteSiteDialog(false);
    }


    const confirmDeleteSite = (selectedRecord) => {

        setSelectedRecord(selectedRecord);
        setDeleteSiteDialog(true);
    }

    const deleteSite = () => {

        //return;
        typehabitatService.deleteOne(selectedRecord.id, props.authToken).then((res) => {

            if (res.error) {
                setDeleteSiteDialog(false);
                //toast.current.show({severity: 'error', summary: 'Opération annulé', detail: res.error, life: 3000});
            } else {
                let _sitesList = recordList.filter(val => val.id !== selectedRecord.id);
                setRecordList(_sitesList);
                setDeleteSiteDialog(false);
                setSelectedRecord(emptyRecord);
                //toast.current.show({severity: 'success', summary: 'Successful', detail: "L'enregistrement a bien été supprimé", life: 3000});
            }

        }).catch(error => {


        })
    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < recordList.length; i++) {
            if (recordList[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const confirmDeleteSelected = () => {
        setDeleteSiteDialog(true);
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _selectedSite = {...selectedRecord};
        _selectedSite[`${name}`] = val;

        setSelectedRecord(_selectedSite);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Nouveau" icon="pi pi-plus" className="p-button-success mr-2" onClick={newRecord}/>
                    <Button label="Supprimer" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={selectedRecord.id === null}/>
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/*<FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block"/>*/}
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV}/>
            </React.Fragment>
        )
    }


    const siteDialogFooter = (
        <>
            <Button label="Annuler" icon="pi pi-times" className="p-button-text" onClick={hideDialog}/>
            <Button label="Enregistrer" icon="pi pi-check" type="submit" form="siteForm"/>
        </>
    );
    const deleteSiteDialogFooter = (
        <>
            <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideDeleteSiteDialog}/>
            <Button label="Oui" icon="pi pi-check" className="p-button-text" onClick={deleteSite}/>
        </>
    );


    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toolbar
                        className="mb-4"
                        left={<LeftToolbarTemplate
                            addNew={newRecord}
                            confirmDelete={confirmDeleteSelected}
                            disabled={selectedRecord.id === null}
                        />}
                        right={<RightToolbarTemplate dt={dt}/>}
                    />
                    <DataTable
                        ref={dt}
                        size="small"
                        responsiveLayout="scroll"
                        loading={loading}
                        value={recordList}
                        dataKey="id"
                        lazy
                        rows={rows1}
                        paginator first={lazyParams.first}
                        rowsPerPageOptions={[10, 20, 50]}
                        filterDisplay="row"
                        filters={lazyParams.filters}
                        sortField={lazyParams.sortField}
                        totalRecords={totalRecords}
                        selection={selectedRecord}
                        sortOrder={lazyParams.sortOrder}
                        onSort={onSort}
                        onFilter={onFilter}
                        onPage={onPage}
                        selectionMode="single"
                        globalFilter={globalFilter} emptyMessage="Aucune enregistrement trouvé."
                        header={
                            <DatatableHeaderTemplate
                                title={module_title}
                                setGlobalFilter={setGlobalFilter}
                            />
                        }
                        onSelectionChange={onSelectionChange}
                        scrollable scrollHeight='calc(100vh - 11rem'
                    >
                        <Column field="nom"
                                header="Nom"
                                showFilterMenu={false}
                                filterField="nom"
                                sortable
                                filter
                                filterPlaceholder="Rechercher par type"
                                style={{flexGrow: 1, flexBasis: '40%'}}
                        />
                        <Column field="description"
                                header="Description"
                                showFilterMenu={false}
                                filterField="description"
                                sortable
                                filter
                                filterPlaceholder="Rechercher par description"
                                style={{flexGrow: 1, flexBasis: '32%'}}
                        />
                        <Column
                            body={
                                (rowData) => <ActionBodyTemplate
                                    data={rowData}
                                    editRecord={editRecord}
                                    deleteRecord={confirmDeleteSite}
                                />
                            }
                            style={{flexGrow: 1, flexBasis: '8%'}}
                        />
                    </DataTable>
                    <Form
                        onSubmit={saveRecord}
                        initialValues={selectedRecord}
                        validate={validate}
                        render={({
                                     errors,
                                     handleSubmit,
                                     touched
                                 }) => (
                            <form
                                onSubmit={handleSubmit}
                                className="p-fluid"
                                id="siteForm">
                                <Dialog
                                    modal
                                    position='top'
                                    draggable={false}
                                    maximizable
                                    style={{width: '50vw'}}
                                    className="p-fluid"
                                    visible={recordDialog}
                                    header={dialogTitle}
                                    footer={siteDialogFooter}
                                    onHide={hideDialog}
                                >
                                    <div className="col-12">
                                        <div className="p-fluid formgrid grid">
                                            <Field parse={identity}
                                                   name="nom"
                                                   render={({input, meta}) => (
                                                       <div className="field col-12 md:col-8">
                                                           <label htmlFor="nom" className={classNames({'p-error': isFormFieldValid(meta)})}>Nom*</label>
                                                           <InputText
                                                               id="nom"
                                                               {...input}

                                                               className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                                           />
                                                           {getFormErrorMessage(meta)}

                                                       </div>
                                                   )}/>
                                            <Field parse={identity} name="description" render={({input, meta}) => (
                                                <div className="field col-12 md:col-12">
                                                    <label htmlFor="description" className={classNames({'p-error': isFormFieldValid(meta)})}>Description</label>
                                                    <InputTextarea
                                                        {...input}
                                                        id="description"
                                                        rows={3}
                                                        cols={20}
                                                    />
                                                    {getFormErrorMessage(meta)}
                                                </div>
                                            )}/>
                                        </div>
                                        <div className="col-12">
                                            <ErrorMessageTemplate touched={touched} errors={errors}/>
                                        </div>
                                    </div>

                                </Dialog>
                                {/*<pre>{JSON.stringify(selectedRecord, 0, 2)}</pre>*/}
                            </form>
                        )}/>

                    <Dialog visible={deleteSiteDialog} style={{width: '450px'}} header="Confirm" modal footer={deleteSiteDialogFooter} onHide={hideDeleteSiteDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{fontSize: '2rem'}}/>
                            {selectedRecord && <span>Etes-vous sûr que vous voulez supprimer<b>{selectedRecord.nom}</b>?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
}

const mapStateToProps = state => {
    return {
        authToken: state.userReducer.authToken,
        isLoggedIn: state.userReducer.isLoggedIn,
    };
};
export default connect(mapStateToProps, {Login})(React.memo(TypeHabitatPage, comparisonFn))

