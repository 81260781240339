import {REDUX_CONST} from '../config/constats';

const initialState = {
    isLoggedIn: false,
    connectionError: false,
    connectionErrorMessage: '',
    connectionLoading: false,

    registerErrors: false,
    registerErrorMessage: '',
    registerLoading: false,
    registerSuccess: undefined,

    updateProfileErrors: false,
    updateProfileErrorMessage: '',
    updateProfileLoading: false,
    userProfileUpdated: false,
    provider: 'EMAIL',

    userId: null,
    authToken: null,
    userName: null,
    userEmail: null,
    userLangue: 'fr',
    userLangueLabel: 'Français',
};

export default (state = initialState, action) => {
    switch (action.type) {
        case REDUX_CONST.LOGIN:
            return {
                ...state,
                connectionError: false,
                isLoggedIn: true,
                //userId: action.payload.id,
                authToken: action.payload,
                //userName: action.payload.name,
                //userEmail: action.payload.email,
            };
        case REDUX_CONST.IS_LOGGED_IN:
            return {
                ...state,
                isLoggedIn: true,
                connectionError: false,
            };
        case REDUX_CONST.LOGOUT:
            return {
                ...state,
                connectionError: false,
                connectionErrorMessage: '',
                isLoggedIn: false,
                authToken: null,
            };
        case REDUX_CONST.LOGIN_ERRORS:
            return {
                ...state,
                connectionError: action.payload.status,
                connectionErrorMessage: action.payload.message,
                connectionLoading: action.payload.loading,
            };
        default:
            return state;
    }
};
