import React from 'react';
import {Accordion, AccordionTab} from "primereact/accordion";
import {connect} from "react-redux";
import {Button} from "primereact/button";
import {addSiteValeur, cancelEditSiteValeur, editSiteValeur, setSelectedSiteValeur} from "../../../../redux/site/valeur/SiteValeurActions";
import ModalValeur from "./ModalValeur";


const AccordionComponent = (props) => {
    //const [selectedSubItem, setSelectedSubItem] = useState(null);

    const siteRowClicked = (subItem, event) => {
        //setSelectedSubItem(subItem)
        props.setSelectedSiteValeur(subItem)
        props.editSiteValeur(subItem)

    };

    const BtnNewValaeur = ({valeur}) => {
        return <div className={'flex w-full align-items-center'}>
            <div className={'flex-1'}>{valeur.nom}</div>
            <div className={'flex-2'}>
                <Button className="p-button-rounded p-button-text p-button-secondary" icon="pi pi-plus" iconPos="right" onClick={() => props.addSiteValeur(valeur)}/>
            </div>
        </div>
    }

    return (
        <>
            <Accordion multiple={false} className="accordion-custom">
                {props.datalist &&
                    Object.values(props.datalist).map((item) => (
                        <AccordionTab
                            key={item.id}
                            //header={item.nom}
                            contentClassName='AccordionTab-without-padding'
                            //header={<BtnNewValaeur valeur={item.nom}/>}
                            headerTemplate={<BtnNewValaeur valeur={item}/>}
                        >
                            {item.sitevaleurs.map((subItem) => (
                                <div
                                    key={'SiteValeur' + subItem.id}
                                    className={'list-group minilist-list'}
                                    onClick={() => siteRowClicked(subItem)}>
                                    <div className={subItem.id === props.selectedSiteValeur.id ? 'list-group-item-mod second' : 'list-group-item-mod'}>
                                        <div className="horizontal-flexbox metadata-item">
                                            <div className="searchable-text authors">{subItem.synthese}</div>
                                        </div>
                                        <div className="bottom_border_div "></div>
                                    </div>
                                </div>
                            ))}
                        </AccordionTab>
                    ))}
            </Accordion>
            <React.Fragment>
                <ModalValeur/>
            </React.Fragment>
        </>
    );
}



const mapStateToProps = state => {
    return {
        selectedSite: state.siteReducer.selectedRecord,
        selectedSiteValeur: state.siteValeurReducer.selectedRecord,
    };
};
export default connect(mapStateToProps, {addSiteValeur, cancelEditSiteValeur,editSiteValeur, setSelectedSiteValeur}
)(AccordionComponent)
