import React, {useEffect, useState} from 'react';

import {connect} from "react-redux";
import {NavLink, Route, Switch, useRouteMatch} from "react-router-dom";

import {loadFullSiteList, setSelectedSite} from '../../redux/site/SiteActions'

import SitesMapComponent from "./map/SitesMapComponent";

import SiteIdentification from "./descr/SiteIdentification";

import './css/site_form.css';
import SiteDescription from "./descr/SiteDescription";
import SiteRechercheSc from "./descr/SiteRechercheSc";
import SiteBassinVersant from "./descr/SiteBassinVersant";
import SiteValeur from "./descr/valeur/SiteValeur";
import SearchIcon from "../../components/SearchIcon";
import SiteDelimitation from "./descr/SiteDelimitation";
import SiteFloreVegetation from "./descr/SiteFloreVegetation";
import SiteUsageActivites from "./descr/SiteUsageActivites";
import SiteDysfonctionnements from "./descr/SiteDysfonctionnements";
import SiteConservations from "./descr/SiteConservations";
import SiteFaune from "./descr/SiteFaune";


const SitePage = (props) => {
    const {path} = useRouteMatch();
    //const history = useHistory();

    const [leftPaneFolded, setLeftPaneFolded] = useState(false);
    const [rightPaneFolded, setRightPaneFolded] = useState(true);
    const [topMenuFolded, setTopMenuFolded] = useState(true);
    const [text, setText] = useState("");

    let loadLazyTimeout = null;

    let menuItems = [
        {label: 'Identification', icon: 'pi pi-fw pi-inbox', to: `${path}/identification`, component: SiteIdentification},
        {label: 'Localisation/Délimitation', icon: 'pi pi-fw pi-user-plus', to: `${path}/delimitation`, component: SiteDelimitation},
        {label: 'Bassin verssant', icon: 'pi pi-fw pi-user-plus', to: `${path}/bassinversant`, component: SiteBassinVersant},
        {label: 'Description', icon: 'pi pi-fw pi-user-plus', to: `${path}/description`, component: SiteDescription},
        {label: 'Flore et végétation', icon: 'pi pi-fw pi-user-plus', to: `${path}/florevegetation`, component: SiteFloreVegetation},
        {label: 'Faune', icon: 'pi pi-fw pi-user-plus', to: `${path}/faune`, component: SiteFaune},
        {label: 'Valeurs du site', icon: 'pi pi-fw pi-user-plus', to: `${path}/valeur/`, component: SiteValeur},
        {label: 'Usages/activités', icon: 'pi pi-fw pi-user-plus', to: `${path}/usageactivites`, component: SiteUsageActivites},
        {label: 'Dysfonctionnements', icon: 'pi pi-fw pi-user-plus', to: `${path}/dysfonctionnements`, component: SiteDysfonctionnements},
        {label: 'Conservation', icon: 'pi pi-fw pi-user-plus', to: `${path}/conservation`, component: SiteConservations},
        {label: 'Recherche', icon: 'pi pi-fw pi-user-plus', to: `${path}/recherche`, component: SiteRechercheSc},
    ]

    useEffect(() => {
        console.log('useEffect Sitepage')
        var menu = document.getElementById('wraper_menu')
        menu.classList.add("layout-static-sidebar-inactive")
        loadLazyData();
        return () => clearTimeout(loadLazyTimeout);
    }, [])


    const loadLazyData = () => {
        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }
        loadLazyTimeout = setTimeout(() => {
            props.loadFullSiteList()
        }, Math.random() * 1000 + 250);
    }


    const siteRowClicked = (item) => {
        props.setSelectedSite(item)
        //history.push(`${path}/identification`)
        setRightPaneFolded(false)
        setTopMenuFolded(false)
    }


    const filterList = (e) => {
        let text = e.target.value
        setText(text);
        if (text.length >= 3 || text.length < 1) {
            props.loadFullSiteList(text)
            setRightPaneFolded(true)
            setTopMenuFolded(true)
        }

    }


    const DrawSitesRows = () => (
        props.dataList && Object.values(props.dataList).map(item => (
            <div key={'site' + item.id} className="list-group minilist-list" onClick={(e) => siteRowClicked(item)}>
                <div className={item.id === props.selectedRecord.id ? 'list-group-item-mod second' : 'list-group-item-mod'}>
                    <div className="horizontal-flexbox">
                        <h5>
                            <div className="searchable-text">{item.site_nom}</div>
                        </h5>
                    </div>
                    <div className="horizontal-flexbox metadata-item">
                        <div className="searchable-text authors">{item?.typesite?.nom} {item.delimitation_text}</div>
                        <div>{item.id === props.setSelectedSite.id}</div>
                    </div>
                    <div className="bottom_border_div"></div>
                </div>
            </div>
        )))


    return (
        <div id="desktop-app">
            <div className="flexcolumn main-router">
                <div className="main-view-window">
                    {/* TOP MENU PANE*/}
                    <div className={"main-view-navbar " + (topMenuFolded ? 'folded' : null)}>
                        <div className="flexrow internal-cont">
                            {
                                menuItems.map((item, i) => {
                                    return <NavLink
                                        strict
                                        activeStyle={{boxShadow: '0 0 0 0.2rem #b7e0b8', zIndex: 1}}
                                        to={item.to}
                                        className="outline-button outline_button_inactive mainview-navbar-navs prior-works"
                                        key={`link_${i}`}>
                                        <div className="outlined-slot">
                                            <span className="height-limited-text" style={{height: "20px"}}>{item.label}</span>
                                        </div>
                                    </NavLink>
                                })
                            }
                        </div>
                    </div>

                    {/* LEFT PANE*/}
                    <div className={"minilist-column flexcolumn " + (leftPaneFolded ? 'folded' : null)}>
                        <div className="minilist shadowed-box ">
                            <div className="minilist-header flexcolumn">
                                <div className="flexrow searchbar-row">
                                    <input type="text"
                                           className="minilist-searchbar"
                                           placeholder="Search..."
                                           value={text}
                                           onChange={filterList}
                                    />
                                    <SearchIcon/>
                                </div>
                            </div>
                            <div
                                className="fold-button-div fold-button-div-left shadowed-box"
                                onClick={() => setLeftPaneFolded(!leftPaneFolded)}>
                                <i className={"pi " + (leftPaneFolded ? 'pi-chevron-right' : 'pi-chevron-left')}></i>
                            </div>

                            {/*SITES LIST*/}
                            <div className="flexcolumn items-list-scrollbox">
                                <div className="items-list">
                                    {
                                        props.loading ? <i className="pi pi-spin pi-spinner" style={{'fontSize': '2em'}}></i> :
                                            props.dataList ? <DrawSitesRows/> : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*CENTER MAP PANE*/}
                    <div className="internal-mainview-column flexcolumn">
                        <div className="graph-view ">
                            <div className="graph-inline-cont">
                                <div id="graph" className="svg-container">
                                    <SitesMapComponent
                                        height={'90vh'}
                                        selected={props.selectedRecord}
                                        setSlected={siteRowClicked}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>

                    {/*RIGHT FORMS PANE*/}
                    <div className={"abstractbox-column flexcolumn " + (rightPaneFolded ? 'folded' : null)}>
                        <div className="abstractbox shadowed-box ">
                            <div
                                className="fold-button-div fold-button-div-right shadowed-box"
                                onClick={() => setRightPaneFolded(!rightPaneFolded)}>
                                <i className={"pi " + (rightPaneFolded ? 'pi-chevron-left' : 'pi-chevron-right')}></i>
                            </div>
                            <div className="abtract-scrollbox shadowed-box bg-white">
                                <Switch>
                                    {
                                        menuItems.map((item, i) => (
                                                <Route path={item.to} children={<item.component/>} key={`route_${item.to}`} exact={true} strict={true}/>
                                            )
                                        )
                                    }
                                </Switch>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


const mapStateToProps = state => {
    return {
        loading: state.siteReducer.loading,
        selectedRecord: state.siteReducer.selectedRecord,
        saveProcess: state.siteReducer.loadingValidationProcess,
        dataList: state.siteReducer.dataList,
    };
}


export default connect(mapStateToProps,
    {
        loadFullSiteList, setSelectedSite
    }
)(SitePage)



