import React, {useEffect, useRef, useState} from 'react';
import {Field, Form} from 'react-final-form';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Toast} from "primereact/toast";

import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {Toolbar} from "primereact/toolbar";
import {InputText} from "primereact/inputtext";

import '../../template/App.css';
import classNames from "classnames";
import {composeValidators, getFormErrorMessage, isFormFieldValid, maxLength, required} from "../../utils";
import DatatableHeaderTemplate from "../../components/datatableHeaderTemplate";
import ActionBodyTemplate from "../../components/actionBodyTemplate";
import LeftToolbarTemplate from "../../components/leftToolbarTemplate";
import RightToolbarTemplate from "../../components/rightToolbarTemplate";
import ErrorMessageTemplate from "../../components/errorMessageTemplate";

import {InputTextarea} from "primereact/inputtextarea";
import {TypeTendanceService} from "../../service/espece/TypeTendanceService";
import {connect} from "react-redux";
import {Login} from "../../redux/UserActions";


const TypeTendancePage = (props) => {
    const dt = useRef(null);


    const typeTendanceService = new TypeTendanceService();

    let emptyRecord = {
        id: null,
        nom: null,
        note: null,
    };
    const [showMessage, setShowMessage] = useState(false);


    const [expandedRows, setExpandedRows] = useState(null);

    const [rows1, setRows1] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    let loadLazyTimeout = null;
    const [lazyParams, setLazyParams] = useState({
        first: 0, rows: 10, page: 0, sortField: null, sortOrder: null, filters: {
            'nom': {value: '', matchMode: 'contains'},
            'note': {value: '', matchMode: 'contains'},
            'global': {value: '', matchMode: 'contains'},
        },
    });

    const [dialogTitle, setDialogTitle] = useState('');
    const [recordDialog, setRecordDialog] = useState(false);
    const [deleteRecordDialog, setDeleteRecordDialog] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(emptyRecord);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [loading, setLoading] = useState(false);

    const [recordLsit, setRecordLsit] = useState(null);


    useEffect(() => {
        loadLazyData();
    }, [lazyParams]) // eslint-disable-line react-hooks/exhaustive-deps


    const loadLazyData = () => {
        setLoading(true);
        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }
        loadLazyTimeout = setTimeout(() => {
            typeTendanceService.getList(lazyParams, props.authToken).then(data => {
                setTotalRecords(data.count);
                setRecordLsit(data.results);
                setLoading(false);
            });
        }, Math.random() * 1000 + 250);
    }

    const onPage = (event) => {
        setRows1(event.rows);
        setLazyParams(event);
        event['page'] = event['page'] + 1
    }

    const onSort = (event) => {
        setLazyParams(event);
    }

    const onFilter = (event) => {
        event['first'] = 1;
        setLazyParams(event);
    }

    const onSelectionChange = (event) => {
        const value = event.value;
        setSelectedRecord(value);
    }


    const identity = value => (value);

    const validate = (data) => {
        let errors = {};
        if (!data.nom) {
            errors.nom = "Le nom est obligatoire";
        }
        if (!data.note) {
            errors.abbreviation = "La note est obligatoire";
        }
        return errors;
    };


    const newRecord = () => {

        setDialogTitle('Nouvelle tendance')
        setSelectedRecord(emptyRecord);
        setSubmitted(false);
        setRecordDialog(true);
    }

    const editRecord = (selectedRecord) => {
        selectedRecord = selectedRecord
        setDialogTitle(selectedRecord.id === null ? 'Nouveau tendance' : `Edition tendance: (${selectedRecord.nom}) `)
        setSelectedRecord(selectedRecord);
        setRecordDialog(true);
    }


    const saveRecord = async (data, form) => {
        setSubmitted(true);
        setShowMessage(true);
        if (data.nom.trim() && data.note.trim()) {
            let _recordList = [...recordLsit];
            let _record = {...data}
            if (data.id) {
                const index = findIndexById(data.id);
                typeTendanceService.updateOne(data, props.authToken).then((res) => {
                    _recordList[index] = _record;
                    setRecordLsit(_recordList);
                    //toast.current.show({severity: 'success', summary: 'Successful', detail: "L'enregistrement a été mis a jour avec succés", life: 3000});
                    form.reset()
                }).catch(error => {


                })
            } else {
                typeTendanceService.addOne(data, props.authToken).then((res) => {
                    lazyParams.sortField = 'id';
                    lazyParams.sortOrder = -1
                    setLazyParams(lazyParams)
                    loadLazyData()
                    //toast.current.show({severity: 'success', summary: 'Successful', detail: "L'enregistrement a été crée avec succès", life: 3000});
                    form.reset()
                }).catch(error => {


                })
            }
            setRecordDialog(false);
            setSelectedRecord(emptyRecord);
        }
    }

    const hideDialog = () => {
        setSubmitted(false);
        setRecordDialog(false);
    }

    const hideDeleteRecordDialog = () => {
        setDeleteRecordDialog(false);
    }


    const confirmDeleteRecord = (selectedRecord) => {
        setSelectedRecord(selectedRecord);
        setDeleteRecordDialog(true);
    }

    const deleteRecord = () => {

        //return;
        typeTendanceService.deleteOne(selectedRecord.id, props.authToken).then((res) => {
            if (res.error) {
                setDeleteRecordDialog(false);
                //toast.current.show({severity: 'error', summary: 'Opération annulé', detail: res.error, life: 3000});
            } else {
                let _recordList = recordLsit.filter(val => val.id !== selectedRecord.id);
                setRecordLsit(_recordList);
                setDeleteRecordDialog(false);
                setSelectedRecord(emptyRecord);
                //toast.current.show({severity: 'success', summary: 'Successful', detail: "L'enregistrement a bien été supprimé", life: 3000});
            }

        }).catch(error => {


        })
    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < recordLsit.length; i++) {
            if (recordLsit[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const confirmDeleteSelected = () => {
        setDeleteRecordDialog(true);
    }

    const recordDialogFooter = (
        <>
            <Button label="Annuler" icon="pi pi-times" className="p-button-text" onClick={hideDialog}/>
            <Button label="Enregistrer" icon="pi pi-check" type="submit" form="recordForm"/>
        </>
    );
    const deleteRecordDialogFooter = (
        <>
            <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideDeleteRecordDialog}/>
            <Button label="Oui" icon="pi pi-check" className="p-button-text" onClick={deleteRecord}/>
        </>
    );


    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toolbar
                        className="mb-4"
                        left={<LeftToolbarTemplate
                            addNew={newRecord}
                            confirmDelete={confirmDeleteSelected}
                            disabled={selectedRecord.id === null}
                        />}
                        right={<RightToolbarTemplate dt={dt}/>}/>
                    <DataTable
                        ref={dt}
                        size="small"
                        responsiveLayout="scroll"
                        loading={loading}
                        value={recordLsit}
                        dataKey="id"
                        lazy
                        rows={rows1}
                        paginator first={lazyParams.first}
                        rowsPerPageOptions={[10, 20, 50]}
                        filterDisplay="row"
                        filters={lazyParams.filters}
                        sortField={lazyParams.sortField}
                        totalRecords={totalRecords}
                        selection={selectedRecord}
                        sortOrder={lazyParams.sortOrder}
                        onSort={onSort}
                        onFilter={onFilter}
                        onPage={onPage}
                        selectionMode="single"
                        globalFilter={globalFilter} emptyMessage="Aucune enregistrement trouvé."
                        header={
                            <DatatableHeaderTemplate
                                title="Gestion des types tendances"
                                setGlobalFilter={setGlobalFilter}
                            />
                        }
                        onSelectionChange={onSelectionChange}
                        scrollable scrollHeight='calc(100vh - 11rem'
                    >
                        <Column field="nom"
                                header="Nom"
                                showFilterMenu={false}
                                filterField="nom"
                                sortable
                                filter
                                filterPlaceholder="Rechercher par nom"

                                style={{flexGrow: 1, flexBasis: '20%'}}
                        />
                        <Column field="note"
                                header="Note"
                                showFilterMenu={false}
                                filterField="note"
                                sortable
                                filter
                                filterPlaceholder="Rechercher par note"
                                style={{flexGrow: 1, flexBasis: '82%'}}
                        />
                        <Column
                            body={
                                (rowData) => <ActionBodyTemplate
                                    data={rowData}
                                    editRecord={(rowData) => editRecord(rowData)}
                                    deleteRecord={confirmDeleteRecord}
                                />
                            }
                            style={{flexGrow: 1, flexBasis: '8%'}}
                        />
                    </DataTable>
                    <Form
                        onSubmit={saveRecord}
                        initialValues={selectedRecord}
                        validate={validate}
                        render={({
                                     errors,
                                     handleSubmit,
                                     touched,
                                     hasValidationErrors,

                                     visited,
                                     validating
                                 }) => (
                            <form
                                onSubmit={handleSubmit}
                                className="p-fluid"
                                id="recordForm">
                                <Dialog
                                    modal
                                    position='top'
                                    draggable={false}
                                    maximizable
                                    style={{width: '50vw'}}
                                    className="p-fluid"
                                    visible={recordDialog}
                                    header={dialogTitle}
                                    footer={recordDialogFooter}
                                    onHide={hideDialog}
                                >
                                    <div className="col-12">
                                        <div className="p-fluid formgrid grid">
                                            <Field parse={identity}
                                                   name="nom"
                                                   validate={
                                                       composeValidators(
                                                           required("nom"),
                                                           maxLength(4, "nom"),
                                                       )}
                                                   render={({input, meta}) => (
                                                       <div className="field col-12 md:col-4">
                                                           <label htmlFor="nom" className={classNames({'p-error': isFormFieldValid(meta)})}>Nom*</label>
                                                           <InputText
                                                               id="nom"
                                                               {...input}
                                                               className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                                           />
                                                           {getFormErrorMessage(meta)}
                                                       </div>
                                                   )}/>
                                            <Field
                                                parse={identity}
                                                name="note"
                                                render={({input, meta}) => (
                                                    <div className="field col-12 md:col-12">
                                                        <label htmlFor="note" className={classNames({'p-error': isFormFieldValid(meta)})}>Note</label>
                                                        <InputTextarea
                                                            {...input}
                                                            id="note"
                                                            rows={3}
                                                            cols={20}
                                                        />
                                                        {getFormErrorMessage(meta)}
                                                    </div>
                                                )}/>
                                        </div>
                                        <div className="col-12">
                                            <ErrorMessageTemplate touched={touched} errors={errors}/>
                                        </div>
                                    </div>

                                </Dialog>

                            </form>
                        )}/>

                    <Dialog visible={deleteRecordDialog} style={{width: '450px'}} header="Confirm" modal footer={deleteRecordDialogFooter} onHide={hideDeleteRecordDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{fontSize: '2rem'}}/>
                            {selectedRecord && <span>Etes-vous sûr que vous voulez supprimer<b>({selectedRecord.nom})</b>?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
}

const mapStateToProps = state => {
    return {
        authToken: state.userReducer.authToken,
        isLoggedIn: state.userReducer.isLoggedIn,
    };
};
export default connect(mapStateToProps, {Login})(React.memo(TypeTendancePage, comparisonFn))
