import {REDUX_CONST} from "../../config/constats";
import {apiClient} from "../../api/ApiClient";
import Store from "../Store";
import {getGlobaleToast} from "../../utils";


const endpoint = 'especenat';

const showNetworkLoading = payload => {
    return async dispatch => {
        await dispatch({
            type: REDUX_CONST.ESPECE.LOADING,
            payload: payload,
        });
    };
};

const showNetworkLoadingValidationProcess = payload => {
    return async dispatch => {
        await dispatch({
            type: REDUX_CONST.ESPECE.LOADING_VALIDATION_PROCESS,
            payload: payload,
        });
    };
};

const showErrorMessage = payload => {
    return async dispatch => {
        await dispatch(showNetworkLoading(false));
        await dispatch(showNetworkLoadingValidationProcess(false));
    };
};

export const formatData = (payload) => {
    return async dispatch => {
        await dispatch({
            type: REDUX_CONST.ESPECE.FULL_LIST,
            payload: payload,
        });

        await dispatch({
            type: REDUX_CONST.MAP.SET_DATA,
            payload: payload,
        });

        if (Store.getState().especeReducer.loading) {
            await dispatch(showNetworkLoading(false));
            await dispatch(showNetworkLoadingValidationProcess(false));
        }
    };
};


export const loadFullEspeceList = (text = "") => {
    return async dispatch => {
        let data = {};
        await dispatch(showNetworkLoading(true));
        await apiClient()
            .get(
                `${endpoint}list/?search=${text}`,
            )
            .then(res => {
                if (res.ok) {
                    let data = res.data
                    setTimeout(() => {
                        dispatch(formatData(data));
                    }, Math.random() * 1000 + 250)
                }
            }).catch((error) => {
                if (error.response) {
                    return error.response.data
                    dispatch(showErrorMessage(error.response.data))
                }
            });
    };
}

export const loadEspeceCombo = (q = "") => {
    return async dispatch => {
        await apiClient().get(`${endpoint}combo/?search=${q}`).then(async res => {
            if (res.ok) {
                await dispatch({
                    type: REDUX_CONST.ESPECE.COMBO,
                    payload: res.data,
                });
            }
        }).catch((error) => {
            if (error.response) {
                return error.response.data
            }
        });
    };
}


export const saveEspeceRecord = (data) => {
    return async dispatch => {
        await dispatch(showNetworkLoadingValidationProcess(true));
        await apiClient()
            .post(
                `${endpoint}/`, data
            )
            .then(async res => {
                if (res.ok) {
                    let data = {...res.data};
                    await dispatch({
                        type: REDUX_CONST.ESPECE.DATA_SAVED,
                        payload: {
                            data: res.data,
                            operation: 'ADD'
                        }
                    })
                    getGlobaleToast({severity: 'success', summary: 'Successful', detail: "L'enregistrement a été crée avec succès", life: 5000});
                } else {
                    getGlobaleToast({severity: 'error', summary: 'ERROR', detail: res.error.toString(), life: 3000});
                }
            });
    }
};

export const setSelectedEspece = espece => {
    return async dispatch => {
        await dispatch({
            type: REDUX_CONST.ESPECE.SET_SELECTED,
            payload: espece,
        });
        await dispatch({
            type: REDUX_CONST.MAP.SET_SELECTED_GEO,
            payload: espece,
        });
    };
};

export const editSelectedEspece = (espece) => {
    return async dispatch => {
        await dispatch({
            type: REDUX_CONST.ESPECE.EDIT_SELECTED,
            payload: espece,
        });
        await dispatch({
            type: REDUX_CONST.MAP.SET_EDITION_MODE,
            payload: true,
        });
    };
};

export const cancelEditEspece = () => {
    return async dispatch => {
        await dispatch({
            type: REDUX_CONST.ESPECE.CANCEL_EDIT,
            payload: [],
        });
        await dispatch({
            type: REDUX_CONST.MAP.SET_EDITION_MODE,
            payload: false,
        });
    };
};





