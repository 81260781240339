import React, { useEffect } from "react";
import { EditControl } from "react-leaflet-draw";
import "leaflet-draw/dist/leaflet.draw.css";

import { FeatureGroup, Marker, Polygon, useMapEvents } from "react-leaflet";
import L from "leaflet";
import { connect } from "react-redux";

const MapEditPolygons = (props) => {
    const map = useMapEvents({});

    useEffect(() => {
        if (props.editionMode) {
            document.querySelector(".leaflet-draw").style = "display:block";
        } else {
            document.querySelector(".leaflet-draw").style = "display:none";
        }
    }, [props.editionMode]);

    const _onMounted = (drawControl) => {
        drawControl = null;
    };

    const _onCreated = (e) => {
        /*let type = e.layerType;
        let layer = e.layer;
        var area = 0;
        switch (type) {
            case "marker":
                break;
            case "polygon":
                area = L.GeometryUtil.geodesicArea(layer.getLatLngs()[0]);
                //props.onChange(layer.toGeoJSON().geometry, area)
                break;
            case "rectangle":
                break;
            default:
                break;
        }*/
    };

    const _onDrawStart = (e) => {};

    const _onDrawStop = (e) => {
        let type = e.layerType;
        let layer = e.layer;
        //let shape = layer.toGeoJSON()
        //let shape_for_db = JSON.stringify(shape)
    };

    const _onEdited = (e) => {
        let numEdited = 0;
        e.layers.eachLayer((layer) => {
            numEdited += 1;
        });
    };

    const _onEditStart = (e) => {
        //console.log('_onEditStart', e);
    };

    const _onEditStop = (e) => {
        //console.log('_onEditStop', e);
    };

    const _onDeleted = (e) => {
        let numDeleted = 0;
        e.layers.eachLayer((layer) => {
            numDeleted += 1;
        });
        //console.log(`onDeleted: removed ${numDeleted} layers`, e);
    };

    const _onDeleteStart = (e) => {
        //console.log('_onDeleteStart', e);
    };

    const _onDeleteStop = (e) => {
        //console.log('_onDeleteStop', e);
    };

    const purpleOptions = { color: "red" };

    return (
        <FeatureGroup>
            <EditControl
                name={"rabat"}
                onEdited={(e) => _onEdited(e)}
                onCreated={_onCreated}
                onDeleted={_onDeleted}
                onMounted={_onMounted}
                onEditStart={_onEditStart}
                onEditStop={_onEditStop}
                onDeleteStart={_onDeleteStart}
                onDeleteStop={_onDeleteStop}
                onDrawStart={_onDrawStart}
                onDrawStop={_onDrawStop}
                position={props.position}
                /*edit={{
                    remove: props.editionMode,
                    edit: props.editionMode
                }}
                draw={{
                    marker: props.editionMode,
                    circle: false,
                    circlemarker:false,
                    rectangle: false,
                    polygon: props.editionMode,
                    polyline: false
                }}*/
                //edit={{edit: props.editionMode}}
            />
            {props.selected?.localisation?.coordinates && <Marker position={props.selected.localisation.coordinates}></Marker>}
            {props.selected?.delimitations?.coordinates && <Polygon pathOptions={purpleOptions} positions={props.selected.delimitations.coordinates} />}
        </FeatureGroup>
    );
};

const mapStateToProps = (state) => {
    return {
        loading: state.mapReducer.loading,
        data: state.mapReducer.data,
        center: state.mapReducer.center,
        zoom_level: state.mapReducer.zoom_level,
        editionMode: state.mapReducer.editionMode,
    };
};

export default connect(mapStateToProps, {})(MapEditPolygons);
