import React, {useEffect, useState} from 'react';
import {MapContainer, TileLayer} from 'react-leaflet'
import "react-leaflet-fullscreen/dist/styles.css";
import "leaflet-draw/dist/leaflet.draw.css";
import {RecensementService} from '../../../service/recensement/RecensementService';

import {EspeceNatService} from "../../../service/espece/EspeceNatService";
import {AutoComplete} from "primereact/autocomplete";
import {Button} from "primereact/button";
import ShowMapClustering from "./ShowMapClustering";
import {connect} from "react-redux";
import {Login} from "../../../redux/UserActions";

const center = [31.558059, -7.483406,]

const SitesMapClusteringPage = (props) => {
    const [data, setData] = useState()
    const [yearRecComboItems, setYearRecComboItems] = useState();
    const [especeResComboItems, setEspeceResComboItems] = useState()
    const [filteredEspeces, setFilteredEspeces] = useState();
    const [filteredAnnees, setFilteredAnnees] = useState();


    const [selectedAnnee, setSelectedAnnee] = useState(null);
    const [selectedEspece, setSelectedEspece] = useState(null);
    const [isLoading, setIsLoading] = useState(false)


    const recensementService = new RecensementService();
    const especeNatService = new EspeceNatService();


    useEffect(() => {
        loadEspeceCombo()
        loadYearRecCombo()
    }, []);

    if (isLoading) return "Loading...";


    const loadMap = () => {
        recensementService.getRecNbrByYearSiteEspece(`annee=${selectedAnnee?.annee_rec ?? ''}&espece_id=${selectedEspece?.id ?? ''}`, props.authToken).then(data => setData(data));
    }


    const loadYearRecCombo = () => {
        recensementService.getComboYear("", props.authToken).then(data => {
            setYearRecComboItems(data)
        });
    }

    const loadEspeceCombo = () => {
        especeNatService.getCombo("", props.authToken).then(data => {
            setEspeceResComboItems(data)
        });
    }

    const searchEspece = (event) => {
        setTimeout(() => {
            let _filteredEspeces;
            if (!event.query.trim().length) {
                _filteredEspeces = [...especeResComboItems];
            } else {
                _filteredEspeces = especeResComboItems.filter((espece) => {
                    return espece.espece.toLowerCase().includes(event.query.toLowerCase());
                });
            }

            setFilteredEspeces(_filteredEspeces);
        }, 250);
    }
    const searchYear = (event) => {

        setTimeout(() => {
            let _filteredYears;
            if (!event.query.trim().length) {
                _filteredYears = [...yearRecComboItems];
            } else {
                _filteredYears = yearRecComboItems.filter((v) => {
                    return v.annee_rec.toString().includes(event.query.toString())
                });
            }

            setFilteredAnnees(_filteredYears);
        }, 250);
    }

    return (<div className="grid">
            <div className="col-12">
                <div className="card">
                    <div className="p-fluid formgrid grid">
                        <div className="col-12">
                            <h3 style={{textAlign: center}}>Distribution des oiseaux d'eau par année. </h3>
                        </div>
                        <div className="field col-12 md:col-4">
                            <h5>Espèces</h5>
                            <AutoComplete
                                id="espece"
                                field="espece"
                                dropdown
                                showClear
                                forceSelection
                                placeholder="Toutes les espèces"
                                suggestions={filteredEspeces}
                                completeMethod={searchEspece}
                                onChange={(e) => setSelectedEspece(e.value)}
                                value={selectedEspece}
                            />
                        </div>
                        <div className="field col-12 md:col-4">
                            <h5>Années</h5>
                            <AutoComplete
                                id="annee"
                                field="annee_rec"
                                dropdown
                                //showClear
                                //forceSelection
                                placeholder="Toutes les années"
                                suggestions={filteredAnnees}
                                completeMethod={searchYear}
                                onChange={(e) => setSelectedAnnee(e.value)}
                                value={selectedAnnee}
                            />
                        </div>
                        <div className="col-2">
                            <h5>&nbsp;</h5>
                            <Button label="Filtrer" icon="pi pi-check" className="p-button-lg" onClick={loadMap}/>
                        </div>
                        <div className="col-12">
                            <hr/>
                        </div>

                    </div>
                    <MapContainer center={center} zoom={6}>
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        />
                        {data != undefined && <ShowMapClustering data={data}/>}
                    </MapContainer>
                </div>
            </div>
        </div>

    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

const mapStateToProps = state => {
    return {
        authToken: state.userReducer.authToken,
        isLoggedIn: state.userReducer.isLoggedIn,
    };
};
export default connect(mapStateToProps, {Login})(React.memo(SitesMapClusteringPage, comparisonFn))


