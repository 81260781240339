import React, {useCallback, useEffect, useState} from "react";
import L from "leaflet";
import "./ShowMapClustering.css";
import useSupercluster from "use-supercluster";
import {Marker, Popup, useMap} from "react-leaflet";

const icons = {};
const fetchIcon = (count, size) => {
    if (!icons[count]) {
        icons[count] = L.divIcon({
            html: `<div class="cluster-marker" style="width: ${size}px; height: ${size}px;">
        ${count}
      </div>`,
        });
    }
    return icons[count];
};

const cuffs = new L.Icon({
    iconUrl: "/bird.svg", iconSize: [25, 25],
});

function ShowMapClustering({data}) {
    const maxZoom = 22;
    const [bounds, setBounds] = useState(null);
    const [zoom, setZoom] = useState(12);
    //const [points, setPoints] = useState();
    const map = useMap();

    // get map bounds
    function updateMap() {
        const b = map.getBounds();
        setBounds([b.getSouthWest().lng, b.getSouthWest().lat, b.getNorthEast().lng, b.getNorthEast().lat,]);
        setZoom(map.getZoom());
    }

    const onMove = useCallback(() => {
        updateMap();
    }, [map]);

    React.useEffect(() => {
        updateMap();
    }, [map]);

    useEffect(() => {
        map.on("move", onMove);
        return () => {
            map.off("move", onMove);
        };
    }, [map, onMove]);

    const points = data.map((item) => ({
        type: "Feature", properties: {cluster: false, data: item}, geometry: JSON.parse(item.localisation)
    }));


    const {clusters, supercluster} = useSupercluster({
        points: points, bounds: bounds, zoom: zoom, options: {radius: 75, maxZoom: 17},
    });


    return (<>
        {clusters.map((cluster) => {
            // every cluster point has coordinates
            const [longitude, latitude] = cluster.geometry.coordinates;
            // the point may be either a cluster or a site point
            const {cluster: isCluster, point_count: pointCount} = cluster.properties;


            // we have a cluster to render
            if (isCluster) {
                return (points && <Marker
                    key={`cluster-${cluster.id}`}
                    position={[latitude, longitude]}
                    icon={fetchIcon(pointCount, 10 + (pointCount / points.length) * 40)}
                    eventHandlers={{
                        click: () => {
                            const expansionZoom = Math.min(supercluster.getClusterExpansionZoom(cluster.id), maxZoom);
                            map.setView([latitude, longitude], expansionZoom, {
                                animate: true,
                            });
                        },
                    }}
                />);
            }

            // we have a single point (site) to render
            return (<Marker
                    key={`espece-${cluster.properties.data.espece_id + '' + cluster.properties.data.code + cluster.properties.data.annee}`}
                    position={[latitude, longitude]}
                    icon={cuffs}>
                    <Popup>
                        {cluster.properties.data.nom}
                        {cluster.properties.data.nbr}
                    </Popup>
                </Marker>


            );
        })}
    </>);
}

export default ShowMapClustering;
