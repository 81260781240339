import React, {useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import {useLocation} from 'react-router-dom';


import PrimeReact from 'primereact/api';
import {Tooltip} from 'primereact/tooltip';

import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import './assets/layout/layout.scss';
import './template/App.scss';
import {connect} from "react-redux";

import {Init, setGlobaleToastRef} from './redux/UserActions';
import AppTopbar from "./template/AppTopbar";
import {AppFooter} from "./template/AppFooter";
import LoginPage from "./pages/LoginPage";
import {AppMenu} from "./template/AppMenu";
import {menu} from "./utils/menu";
import Navigation from "./utils/Navigation";
import {AppConfig} from "./template/AppConfig";
import {CSSTransition} from "react-transition-group";
import {Toast} from "primereact/toast";


const App = (props) => {

    React.useEffect(() => {
        props.Init()
        props.setGlobaleToastRef()
    }, []);

    const [layoutMode, setLayoutMode] = useState('static');
    const [layoutColorMode, setLayoutColorMode] = useState('light')
    const [inputStyle, setInputStyle] = useState('outlined');
    const [ripple, setRipple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    const copyTooltipRef = useRef();
    const toast = useRef(null);
    const location = useLocation();

    PrimeReact.ripple = true;

    let menuClick = false;
    let mobileTopbarMenuClick = false;


    useEffect(() => {
        if (toast) {
            props.setGlobaleToastRef(toast)
        }
    }, [toast])

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
        document.documentElement.style.fontSize = '12px';
        setLayoutColorMode('saga-green');
    }, [mobileMenuActive]);

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    }

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value)
    }

    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode)
    }

    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode)
    }

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    }

    const onToggleMenuClick = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === 'overlay') {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            } else if (layoutMode === 'static') {
                setStaticMenuInactive((prevState) => !prevState);
            }
        } else {
            setMobileMenuActive((prevState) => !prevState);
        }

        event.preventDefault();
    }

    const onSidebarClick = () => {
        menuClick = true;
    }

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    }

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        event.preventDefault();
    }

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    }
    const isDesktop = () => {
        return window.innerWidth >= 992;
    }


    const addClass = (element, className) => {
        if (element.classList) element.classList.add(className); else element.className += ' ' + className;
    }

    const removeClass = (element, className) => {
        if (element.classList) element.classList.remove(className); else element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    const wrapperClass = classNames('layout-wrapper', {
        'layout-overlay': layoutMode === 'overlay',
        'layout-static': layoutMode === 'static',
        'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
        'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
        'layout-mobile-sidebar-active': mobileMenuActive,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': ripple === false,
        'layout-theme-light': layoutColorMode === 'dark'
    });

    return (
        <>

            <div className={wrapperClass} onClick={onWrapperClick} id='wraper_menu'>
                <Toast ref={toast}/>
                <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus"/>
                {props.isLoggedIn ? (
                    <>
                        <AppTopbar onToggleMenuClick={onToggleMenuClick}
                                   layoutColorMode={layoutColorMode}
                                   mobileTopbarMenuActive={mobileTopbarMenuActive}
                                   onMobileTopbarMenuClick={onMobileTopbarMenuClick}
                                   onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick}/>
                        <div className="layout-sidebar" onClick={onSidebarClick}>
                            <AppMenu model={menu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode}/>
                        </div>
                        <div className="layout-main-container">
                            <div className="layout-main">
                                <Navigation layoutColorMode={layoutColorMode} location={location}/>
                            </div>
                        </div>
                        <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange}
                                   layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange}/>

                        <CSSTransition classNames="layout-mask" timeout={{enter: 200, exit: 200}} in={mobileMenuActive} unmountOnExit>
                            <div className="layout-mask p-component-overlay"></div>
                        </CSSTransition>


                    </>
                ) : (<LoginPage/>)}
            </div>
            <AppFooter layoutColorMode={layoutColorMode}/>
        </>
    )
}

//export default App;

const mapStateToProps = state => {
    return {
        layoutMode: state.globaleReducer.layoutMode,
        staticMenuInactive: state.globaleReducer.staticMenuInactive,
        overlayMenuActive: state.globaleReducer.overlayMenuActive,
        mobileMenuActive: state.globaleReducer.mobileMenuActive,
        inputStyle: state.globaleReducer.inputStyle,
        ripple: state.globaleReducer.ripple,
        layoutColorMode: state.globaleReducer.layoutColorMode,

        authToken: state.userReducer.authToken,
        isLoggedIn: state.userReducer.isLoggedIn,
    };
};
export default connect(mapStateToProps, {Init, setGlobaleToastRef})(App);
