import {REDUX_CONST} from '../../config/constats';

let emptyRecord = {
    id: null,
    site_id: "",
    localisation: {
        type: '',
        coordinates: null
    },
    delimitations: {
        type: '',
        coordinates: null
    },
    delimitation_text: "",
    accessibilite: "",
    polygon: {},
    centroid: {}
};
const initialState = {
    dialogTitle: "",
    loading: false,
    loadingValidationProcess: true,
    editionMode: false,
    selectedRecord: {...emptyRecord},
    showErrorMessage: {
        show: false,
        message: ''
    },
};

export default (state = initialState, action) => {
    let dt = {};
    switch (action.type) {
        case REDUX_CONST.SITE.LOADING:
            return {
                ...state,
                loading: action.payload
            };
        case REDUX_CONST.SITE.LOADING_VALIDATION_PROCESS:
            return {
                ...state,
                loadingValidationProcess: action.payload
            };
        case REDUX_CONST.SITE.FULL_LIST:
            return {
                ...state,
                dataList: action.payload,
                selectedRecord: {...emptyRecord},
            };
        case REDUX_CONST.SITE.SET_SELECTED:
            console.log('case REDUX_CONST.SITE.SET_SELECTED:')
            return {
                ...state,
                selectedRecord: action.payload,
                editionMode: false,
            };
        case REDUX_CONST.SITE.ADD_NEW:
            return {
                ...state,
                dialogTitle: 'Nouveau site',
                editionMode: action.payload,
                selectedRecord: {...emptyRecord},
                loadingValidationProcess: false
            };
        case REDUX_CONST.SITE.EDIT_SELECTED:
            return {
                ...state,
                dialogTitle: `Edition site`,
                editionMode: true,
                loadingValidationProcess: false,
                selectedRecord: action.payload,
            };
        case REDUX_CONST.SITE.CANCEL_EDIT:
            return {
                ...state,
                editionMode: false,
                loadingValidationProcess: false
            };
        case REDUX_CONST.SITE.DATA_SAVED:
            let {operation, data} = action.payload;
            return {
                ...state,
                selectedRecord: data,
                editionMode: false,
                loadingValidationProcess: false
            };
        default:
            return state;
    }
}
