import React from "react";
import { Button } from "primereact/button";

const LeftToolbarTemplate = (props) => {
    return (
        <React.Fragment>
            <div className="my-2">
                {props.clearFilters && <Button type="button" icon="pi pi-filter-slash" label="Effecer filtre" className="p-button-outlined mr-2" onClick={props.clearFilters} />}
                {props.addNew && <Button disabled={props.newDisabled} label="Nouveau" icon="pi pi-plus" className="p-button-success mr-2" onClick={props.addNew} />}
                {props.confirmDelete &&<Button label="Supprimer" icon="pi pi-trash" className="p-button-danger" onClick={props.confirmDelete} disabled={props.disabled} />}
            </div>
        </React.Fragment>
    );
};

export default LeftToolbarTemplate;
