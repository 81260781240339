import React from 'react';
import {allValuesAreTrue} from "../utils";
import {Message} from "primereact/message";

const ErrorMessageTemplate = (props) => {
    return (
        <React.Fragment>
            <div className="grid">
                {
                    allValuesAreTrue(props.touched) && Object.entries(props.errors).map(([key, value]) => (
                        <div className="col-12 md:col-3" key={key}>
                            <Message severity="error" text={value}/>
                        </div>
                    ))
                }
            </div>
        </React.Fragment>
    );
}

export default ErrorMessageTemplate

