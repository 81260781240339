import {REDUX_CONST} from '../../../config/constats';

let emptyRecord = {
    id: null,
    code: '',
    libellezone: '',
    description: '',
};
const initialState = {
    loading: false,
    DATA_LIST: null,
    COMBO_LIST: null,
    selectedRecord: emptyRecord,
    emptyRecord: emptyRecord,
    dialogTitle: "",

    formSubmitted: false,
    showSiteFormDialog: false,
    showMessage: false,
    expandedRows: false,

    rows: 10,
    totalRecords: 0,
    siteDialog: false,
    deleteSiteDialog: false,
    globalFilter: null,


    lazyParams: {
        first: 0, rows: 10, page: 0, sortField: null, sortOrder: null,
        filters: {
            'code': {value: '', matchMode: 'contains'},
            'nom': {value: '', matchMode: 'contains'},
            'regionnat': {value: '', matchMode: 'contains'},
            'complexe': {value: '', matchMode: 'contains'},
            'global': {value: '', matchMode: 'contains'},
        }
    }

};

export default (state = initialState, action) => {
    switch (action.type) {
        case REDUX_CONST.SET_TYPESITE_LIST:
            return {
                ...state,
                DATA_LIST: action.payload.results,
                totalRecords: action.payload.count,
                loading: false
            };
        case REDUX_CONST.SET_TYPESITE_COMBO:
            return {
                ...state,
                COMBO_LIST: action.payload,
            };
        case REDUX_CONST.SET_TYPESITE_LAZY_PARAMS:
            return {
                ...state,
                selectedRecord: emptyRecord,
                lazyParams: action.payload,
                rows: action.payload.rows,
            };
        case REDUX_CONST.SET_TYPESITE_GLOBAL_FILTER:
            return {
                ...state,
                globalFilter: action.payload,
            };
        case REDUX_CONST.SET_TYPESITE_EXPANDED_ROWS:
            return {
                ...state,
                expandedRows: action.payload,
            };
        case REDUX_CONST.ADD_NEW_TYPESITE:
            return {
                ...state,
                dialogTitle: 'Nouvelle région',
                selectedRecord: emptyRecord,
                formSubmitted: false,
                showSiteFormDialog: true
            };
        case REDUX_CONST.EDIT_SELECTED_TYPESITE:
            return {
                ...state,
                dialogTitle: `Edition site: (${state.selectedRecord.code}) ${state.selectedRecord.nom}`,
                formSubmitted: false,
                showSiteFormDialog: true
            };
        case REDUX_CONST.CANCEL_EDIT_SELECTED_TYPESITE:
            return {
                ...state,
                dialogTitle: "",
                formSubmitted: false,
                showSiteFormDialog: true
            };
        case REDUX_CONST.SET_TYPESITE_LOADING:
            return {
                ...state,
                loading: action.payload
            };
        case REDUX_CONST.SET_SELECTED_TYPESITE:
            return {
                ...state,
                selectedRecord: action.payload
            };
        case REDUX_CONST.SET_DELETE_TYPESITE_DIALOG:
            return {
                ...state,
                deleteSiteDialog: action.payload
            };
        default:
            return state;
    }
}
;
