import axios from 'axios';
import {API_URL} from "../../config";

export class RecensementService {
    constructor() {
        this.endpoint = "recensements"
    }

    getList({page, rows, filters, sortField, sortOrder}, token) {
        page = page === 0 || page === undefined ? 1 : page
        let site = filters.site.value?.id
        site = site !== undefined && site !== null && `${site}`.length > 0 ? `&site=${`${site}`.replace('&', '')}` : ''
        let date = filters.date.value
        date = date !== undefined && date !== null && date.length > 0 ? `&date=${date.replace('&', '')}` : ''
        let year = filters.year.value
        year = year !== undefined && year !== null && year.length > 0 ? `&year=${year.replace('&', '')}` : ''
        let global = filters.global.value
        global = global !== undefined && global !== null && global.length > 0 ? `&search=${global.replace('&', '')}` : ''
        let ordering = sortField !== undefined && sortField !== null && sortField.length > 0 ? `&ordering=${sortOrder === 1 ? sortField : '-'.concat(sortField)}` : ''


        let filter = `${site}${date}${year}${global}${ordering}`

        return axios.get(`${API_URL}/${this.endpoint}?page=${page}&page_size=${rows}${filter}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {

            return res.data;
        }).catch((error) => {
            console.error(error)
        })
    }

    getCombo(q, token) {
        return axios.get(`${API_URL}/${this.endpoint}combo/?search=${q}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {
            return res.data;
        }).catch((error) => {
            console.error(error)
        })
    }

    getComboYear(q, token) {
        return axios.get(`${API_URL}/${this.endpoint}year/?search=${q}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {
            return res.data;
        }).catch((error) => {
            console.error(error)
        })
    }

    getRecNbrByYearSiteEspece(q, token) {
        return axios.get(`${API_URL}/${this.endpoint}effectifbyyearsiteespece/?${q}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {
            return res.data;
        }).catch((error) => {
            console.error(error)
        })
    }

    getRecNbrByYear(q, token) {
        return axios.get(`${API_URL}/${this.endpoint}nbrbyyear/?search=${q}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {
            return res.data;
        }).catch((error) => {
            console.error(error)
        })
    }


    getRecEffectifByYear(q, token) {
        return axios.get(`${API_URL}/${this.endpoint}effectifbyyear/?search=${q}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {
            return res.data;
        }).catch((error) => {

        })
    }

    addOne(data, token) {
        return axios.post(`${API_URL}/${this.endpoint}/`, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {
            return res.data;
        })
    }

    updateOne(data, token) {
        return axios.patch(`${API_URL}/${this.endpoint}/${data.id}/`, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {
            return res.data;
        }).catch((error) => {
            if (error.response) {
                return error.response.data
            }
        })
    }

    deleteOne(id, token) {
        return axios.delete(`${API_URL}/${this.endpoint}/${id}/`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {
            return res.data;
        }).catch((error) => {
            if (error.response) {
                return error.response.data
            }
        })
    }
}
