import React, {useEffect} from "react";
import {connect} from "react-redux";
import {useFormik} from "formik";
import * as Yup from "yup";
import {Button} from "primereact/button";
import {Accordion, AccordionTab} from "primereact/accordion";
import {InputTextarea} from "primereact/inputtextarea";
import {classNames} from "primereact/utils";
import {getFormikFormErrorMessage, isFormikFormFieldValid} from "../../../utils";


const SiteRechercheSc = (props) => {
    //const {selected} = props;


    const validationSchema = Yup.object().shape({
        code: Yup
            .number()
            .typeError('Ce champ ne doit contenir que des nombre positive')
            .required("Ce champ est obligatoire")
            .min(1000)
            .max(9999, `Ce champ $ {path} ne Doit pas être supérieur à $ {max}`)
            .test('len', `Ce champ $ {path} Doit être supérieur à $ {min}`, val => val + ""
                .length >= 4),

        //.integer("Ce champ ne doit contenir que des nombre positive")
        //.required("Ce champ est obligatoire").nullable(),
        typesite: Yup.object().shape({
            id: Yup.string().required("Ce champ est obligatoire").nullable(),
        }),
        nom: Yup.string().required("code is required").nullable(),
    })


    const formik = useFormik({
        initialValues: props.selected,
        validationSchema: validationSchema,
        onSubmit: (values) => {

        }
    });

    useEffect(() => {
        if (props.selected.recherchesc) {
            formik.setValues(props.selected.recherchesc)
        }
    }, [props.selected]);


    const ActionBtns = () => {
        return <React.Fragment>
            <div className="surface-0 fixed pl-4" style={{bottom: '30px'}}>
                {props.editionMode ? (
                    <>
                        <Button
                            icon="pi pi-save"
                            type="submit"
                            form="formTabSiteRechercheSc"
                            className="mr-2"/>
                        <Button
                            icon="pi pi-times"
                            className="p-button-secondary"
                            type="button"
                            onClick={() => props.cancelEditSite()}/>
                    </>
                ) : (
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-warning"
                        type="button"
                        onClick={() => props.editSelectedSite(props.selected)}/>
                )}
            </div>
        </React.Fragment>
    };


    return (
        <form onSubmit={formik.handleSubmit} className="p-fluid" id="siteForm">
            <div className="surface-0 ">
                <Accordion activeIndex={[0]} multiple={true}>
                    <AccordionTab header="Recherche">
                        <InputTextarea
                            disabled={!props.editionMode}
                            id="recherche"
                            name="recherche"
                            value={formik.values.recherche}
                            onChange={formik.handleChange}
                            rows={10}
                            cols={20}
                            className={classNames({
                                "p-invalid": isFormikFormFieldValid(formik, "recherche"),
                                "bg-black-alpha-10": !props.editionMode
                            })}
                        />
                        {getFormikFormErrorMessage(formik, "recherche")}

                    </AccordionTab>
                    <AccordionTab header="equipement">
                        {/* <label
                            htmlFor="equipement"
                            className={classNames({'p-error': isFormikFormFieldValid(formik, "equipement")})}>
                            Géologie *
                        </label>*/}
                        <InputTextarea
                            disabled={!props.editionMode}
                            id="equipement"
                            name="equipement"
                            value={formik.values.equipement}
                            onChange={formik.handleChange}
                            rows={10}
                            cols={20}
                            className={classNames({
                                "p-invalid": isFormikFormFieldValid(formik, "equipement"),
                                "bg-black-alpha-10": !props.editionMode
                            })}
                        />
                        {getFormikFormErrorMessage(formik, "equipement")}
                    </AccordionTab>
                </Accordion>
            </div>

            <ActionBtns/>
        </form>
    );
}


const mapStateToProps = state => {
    return {
        selected: state.siteReducer.selectedRecord,
        //loading: state.siteDescriptionReducer.loading
    };
};

export default connect(mapStateToProps, {})(SiteRechercheSc)


