import { REDUX_CONST } from "../../../config/constats";

let emptyRecord = {
    id: "",
    numero: "",
    annee_rec: "",
    date: "",
    description: "",
    file_name: "",
};
const initialState = {
    dialogTitle: "",
    loading: false,
    loadingValidationProcess: true,
    dataList: null,
    comboList: null,
    selectedRecord: { ...emptyRecord },
    showEditDialog: false,
    showDeleteDialog: false,
    showErrorMessage: {
        show: false,
        message: "",
    },

    datatable: {
        totalRecords: 0,
        globalFilter: null,
        rows: 10,
        lazyParams: {
            first: 0,
            rows: 10,
            page: 0,
            sortField: null,
            sortOrder: null,
            filters: {
                id: { value: "", matchMode: "exact" },
                numero: { value: "", matchMode: "contains" },
                annee_rec: { value: "", matchMode: "contains" },
                date: { value: "", matchMode: "contains" },
                created_at: { value: "", matchMode: "contains" },
                description: { value: "", matchMode: "contains" },
                global: { value: "", matchMode: "contains" },
            },
        },
    },
};

export default (state = initialState, action) => {
    let dt = {};
    switch (action.type) {
        case REDUX_CONST.REC_IMPORTATION.LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case REDUX_CONST.REC_IMPORTATION.LOADING_VALIDATION_PROCESS:
            return {
                ...state,
                loadingValidationProcess: action.payload,
            };
        case REDUX_CONST.REC_IMPORTATION.LIST:
            dt = { ...state.datatable };
            dt.totalRecords = action.payload.count;
            return {
                ...state,
                dataList: action.payload.results,
                datatable: { ...dt },
            };
        case REDUX_CONST.REC_IMPORTATION.COMBO:
            return {
                ...state,
                comboList: action.payload,
            };
        case REDUX_CONST.REC_IMPORTATION.SET_SELECTED:
            return {
                ...state,
                selectedRecord: action.payload,
            };
        case REDUX_CONST.REC_IMPORTATION.SET_LAZY_PARAMS:
            dt = { ...state.datatable };
            dt.lazyParams = action.payload;
            return {
                ...state,
                datatable: dt,
            };
        case REDUX_CONST.REC_IMPORTATION.SET_GLOBAL_FILTER:
            dt = { ...state.datatable };
            dt.globalFilter = action.payload;
            return {
                ...state,
                datatable: dt,
            };
        case REDUX_CONST.REC_IMPORTATION.ADD_NEW:
            console.log(emptyRecord)
            return {
                ...state,
                showEditDialog: action.payload,
                dialogTitle: "Nouvelle Importation",
                selectedRecord: { ...emptyRecord },
                loadingValidationProcess: false,
            };
        case REDUX_CONST.REC_IMPORTATION.EDIT_SELECTED:
            return {
                ...state,
                dialogTitle: `Edition Importation: (${action.payload.annee_rec})`,
                showEditDialog: true,
                selectedRecord: {...action.payload},
                loadingValidationProcess: false,
            };
        case REDUX_CONST.REC_IMPORTATION.SHOW_DELETE_DIALOG:
            return {
                ...state,
                showDeleteDialog: action.payload.show,
                selectedRecord: action.payload.data ? action.payload.data : state.selectedRecord,
            };
        case REDUX_CONST.REC_IMPORTATION.CANCEL_EDIT:
            return {
                ...state,
                showEditDialog: false,
                loadingValidationProcess: false,
            };
        case REDUX_CONST.REC_IMPORTATION.DATA_SAVED:
            let { operation, data } = action.payload;
            let records = {};
            switch (operation) {
                case "ADD":
                    records = [data, ...state.dataList];
                    break;
                case "EDIT":
                    records = state.dataList.map((item) => {
                        if (item.id === data.id) {
                            item = data;
                        }
                        return item;
                    });
                    break;
                case "DELETE":
                    records = state.dataList.filter((item) => item.id !== data.id);
                    break;
                default:
                    break;
            }
            console.log(records)
            return {
                ...state,
                showEditDialog: false,
                showDeleteDialog: false,
                dataList: records,
                selectedRecord: data,
                loadingValidationProcess: false,
            };
        default:
            return state;
    }
};
