import React from 'react';
import {Button} from "primereact/button";
import {exportCSV} from "../utils";

const RightToolbarTemplate = (props) => {
    return (
        <React.Fragment>
            {/*<FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block"/>*/}
            <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={(e) => exportCSV(props.dt)}/>
        </React.Fragment>
    );
}

export default RightToolbarTemplate

