import React from 'react';
import {InputText} from "primereact/inputtext";

const DatatableHeaderTemplate = (props) => {
    return (
        <React.Fragment>
            <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                <h5 className="m-0">{props.title}</h5>
                <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search"/>
                <InputText type="search" onInput={(e) => props.setGlobalFilter(e.target.value)} placeholder="Search..."/>
            </span>
            </div>
        </React.Fragment>
    );
}

export default DatatableHeaderTemplate

