import React, {useEffect} from "react";
import {connect} from "react-redux";
import {useFormik} from "formik";
import * as Yup from "yup";
import {Button} from "primereact/button";
import {Accordion, AccordionTab} from "primereact/accordion";
import {InputTextarea} from "primereact/inputtextarea";
import {classNames} from "primereact/utils";
import {getFormikFormErrorMessage, isFormikFormFieldValid} from "../../../utils";


const SiteBassinVersant = (props) => {
    //const {selected} = props;


    const validationSchema = Yup.object().shape({
        code: Yup
            .number()
            .typeError('Ce champ ne doit contenir que des nombre positive')
            .required("Ce champ est obligatoire")
            .min(1000)
            .max(9999, `Ce champ $ {path} ne Doit pas être supérieur à $ {max}`)
            .test('len', `Ce champ $ {path} Doit être supérieur à $ {min}`, val => val + ""
                .length >= 4),

        //.integer("Ce champ ne doit contenir que des nombre positive")
        //.required("Ce champ est obligatoire").nullable(),
        typesite: Yup.object().shape({
            id: Yup.string().required("Ce champ est obligatoire").nullable(),
        }),
        nom: Yup.string().required("code is required").nullable(),
    })


    const formik = useFormik({
        initialValues: props.selected,
        validationSchema: validationSchema,
        onSubmit: (values) => {

        }
    });

    useEffect(() => {
        if (props.selected.bassinsversant && props.selected.bassinsversant.length > 0) {
            formik.setValues(props.selected.bassinsversant[0])
        }
    }, [props.selected]);


    const ActionBtns = () => {
        return <React.Fragment>
            <div className="surface-0 fixed pl-4" style={{bottom: '30px'}}>
                {props.editionMode ? (
                    <>
                        <Button
                            icon="pi pi-save"
                            type="submit"
                            form="formTabSiteRechercheSc"
                            className="mr-2"/>
                        <Button
                            icon="pi pi-times"
                            className="p-button-secondary"
                            type="button"
                            onClick={() => props.cancelEditSite()}/>
                    </>
                ) : (
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-warning"
                        type="button"
                        onClick={() => props.editSelectedSite(props.selected)}/>
                )}
            </div>
        </React.Fragment>
    };


    return (
        <form onSubmit={formik.handleSubmit} className="p-fluid" id="siteForm">
            <div className="surface-0 ">
                <Accordion activeIndex={[0]} multiple={false}>
                    <AccordionTab header="Orohydrographie">
                        <InputTextarea
                            disabled={!props.editionMode}
                            id="orohydrographie"
                            name="orohydrographie"
                            value={formik.values.orohydrographie}
                            onChange={formik.handleChange}
                            rows={10}
                            cols={20}
                            className={classNames({
                                "p-invalid": isFormikFormFieldValid(formik, "orohydrographie"),
                                "bg-black-alpha-10": !props.editionMode
                            })}
                        />
                        {getFormikFormErrorMessage(formik, "orohydrographie")}

                    </AccordionTab>
                    <AccordionTab header="Géologie">
                        {/* <label
                            htmlFor="geologie"
                            className={classNames({'p-error': isFormikFormFieldValid(formik, "geologie")})}>
                            Géologie *
                        </label>*/}
                        <InputTextarea
                            disabled={!props.editionMode}
                            id="geologie"
                            name="geologie"
                            value={formik.values.geologie}
                            onChange={formik.handleChange}
                            rows={10}
                            cols={20}
                            className={classNames({
                                "p-invalid": isFormikFormFieldValid(formik, "geologie"),
                                "bg-black-alpha-10": !props.editionMode
                            })}
                        />
                        {getFormikFormErrorMessage(formik, "geologie")}
                    </AccordionTab>
                    <AccordionTab header="Climat/Bioclimat">
                        {/* <label
                            htmlFor="climatbioclimat"
                            className={classNames({'p-error': isFormikFormFieldValid(formik, "climatbioclimat")})}>
                            Climat/Bioclimat *
                        </label>*/}
                        <InputTextarea
                            disabled={!props.editionMode}
                            id="climatbioclimat"
                            name="climatbioclimat"
                            value={formik.values.climatbioclimat}
                            onChange={formik.handleChange}
                            rows={10}
                            cols={20}
                            className={classNames({
                                "p-invalid": isFormikFormFieldValid(formik, "climatbioclimat"),
                                "bg-black-alpha-10": !props.editionMode
                            })}
                        />
                        {getFormikFormErrorMessage(formik, "climatbioclimat")}
                    </AccordionTab>
                    <AccordionTab header="Végétation">
                        {/*<label
                            htmlFor="hydrologie"
                            className={classNames({'p-error': isFormikFormFieldValid(formik, "hydrologie")})}>
                            Hydrologie *
                        </label>*/}
                        <InputTextarea
                            disabled={!props.editionMode}
                            id="vegetation"
                            name="vegetation"
                            value={formik.values.vegetation}
                            onChange={formik.handleChange}
                            rows={10}
                            cols={20}
                            className={classNames({
                                "p-invalid": isFormikFormFieldValid(formik, "vegetation"),
                                "bg-black-alpha-10": !props.editionMode
                            })}
                        />
                        {getFormikFormErrorMessage(formik, "vegetation")}
                    </AccordionTab>
                    <AccordionTab header="Occupation et aménagement">
                        <InputTextarea
                            disabled={!props.editionMode}
                            id="ocupamenag"
                            name="ocupamenag"
                            value={formik.values.ocupamenag}
                            onChange={formik.handleChange}
                            rows={10}
                            cols={20}
                            className={classNames({
                                "p-invalid": isFormikFormFieldValid(formik, "ocupamenag"),
                                "bg-black-alpha-10": !props.editionMode
                            })}
                        />
                        {getFormikFormErrorMessage(formik, "ocupamenag")}
                    </AccordionTab>
                </Accordion>
            </div>
            <ActionBtns/>
        </form>
    );
}


const mapStateToProps = state => {
    return {
        selected: state.siteReducer.selectedRecord,
        //loading: state.siteDescriptionReducer.loading
    };
};

export default connect(mapStateToProps, {})(SiteBassinVersant)


