import axios from 'axios';
import {API_URL} from "../../config";

export class RecensementResultatService {
    constructor() {
        this.endpoint = "recensementresultat"
    }

    getList({page, rows, filters, sortField, sortOrder}, token) {
        //?ordering=-published_at&page=1&page_size=10&search=d
        //?ordering=-published_at&page=1&page_size=10&search=d&title=a

        page = page === 0 || page === undefined ? 1 : page
        let rec_id = filters.rec_id.value
        rec_id = rec_id !== undefined && rec_id !== null && `${rec_id}`.length > 0 ? `&rec_id=${`${rec_id}`.replace('&', '')}` : ''
        let espece_id = filters.espece.value
        espece_id = espece_id !== undefined && espece_id !== null && `${espece_id}`.length > 0 ? `&espece=${`${espece_id}`.replace('&', '')}` : ''
        let global = filters.global.value
        global = global !== undefined && global !== null && global.length > 0 ? `&search=${global.replace('&', '')}` : ''
        let ordering = sortField !== undefined && sortField !== null && sortField.length > 0 ? `&ordering=${sortOrder === 1 ? sortField : '-'.concat(sortField)}` : ''

        let filter = `${rec_id}${espece_id}${global}${ordering}`

        return axios.get(`${API_URL}/${this.endpoint}?page=${page}&page_size=${rows}${filter}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {

            return res.data;
        }).catch((error) => {
            console.error(error)
        })
    }

    getCombo(q, token) {
        return axios.get(`${API_URL}/${this.endpoint}combo/?search=${q}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {
            return res.data;
        }).catch((error) => {
            console.error(error)
        })
    }

    getComboYear(q, token) {
        return axios.get(`${API_URL}/${this.endpoint}year/?search=${q}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {
            return res.data;
        }).catch((error) => {
            console.error(error)
        })
    }

    addOne(data, token) {
        return axios.post(`${API_URL}/${this.endpoint}/`, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {

            return res.data;
        })
    }

    updateOne(data, token) {
        return axios.patch(`${API_URL}/${this.endpoint}/${data.id}/`, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {
            return res.data;
        }).catch((error) => {
            if (error.response) {
                return error.response.data
            }
        })
    }

    deleteOne(id, token) {
        return axios.delete(`${API_URL}/${this.endpoint}/${id}/`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {
            return res.data;
        }).catch((error) => {
            if (error.response) {
                return error.response.data
            }
        })
    }
}
