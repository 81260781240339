import React, { useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

const DeleteDialogComponent = (props) => {
    const deleteSiteDialogFooter = (
        <>
            <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={props.executeCancel} disabled={props.saveProcess} />
            <Button label="Oui" icon={props.saveProcess ? "pi pi-spin pi-spinner" : "pi pi-check"} className="p-button-text" onClick={props.executeDelete} disabled={props.saveProcess} />
        </>
    );

    return (
        <Dialog visible={props.showDialog} onHide={props.executeCancel} style={{ width: "450px" }} header="Confirmation de suppression" modal footer={deleteSiteDialogFooter}>
            <div className="flex align-items-center justify-content-center">
                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                {props.message && (
                    <span>
                        Etes-vous sûr que vous voulez supprimer<b>({props.message})</b>?
                    </span>
                )}
            </div>
        </Dialog>
    );
};

export default DeleteDialogComponent;
