import {applyMiddleware, combineReducers, compose, legacy_createStore,} from 'redux';
import thunkMiddleware from 'redux-thunk';
import UserReducer from './UserReducer';
import {isDevEnv} from "../utils";
import GlobaleReducer from "./GlobaleReducer";

import TypeSiteReducer from "./parametrage/typesite/TypeSiteReducer";
import PersonneReducer from "./parametrage/personne/PersonneReducer";
import TypeBioclimatReducer from "./parametrage/bioclimat/TypeBioclimatReducer";
import TypeFoncierReducer from "./parametrage/typefoncier/TypeFoncierReducer";

/*
import RegionNaturelleReducer from "../pages/sites2/redux/old/RegionNaturelleReducer";
import ComplexeReducer from "../pages/sites2/redux/old/ComplexeReducer";
import DecoupageAdminReducer from "../pages/sites2/redux/old/DecoupageAdminReducer";
import SiteDescriptionReducer from "../pages/sites2/description/description/redux/SiteDescriptionReducer";
import SiteRechercheScReducer from "../pages/sites2/description/recherchesc/redux/SiteRechercheScReducer";
import SiteBassinsVersantReducer from "../pages/sites2/description/bassinsversant/redux/SiteBassinsVersantReducer";
import SiteSynonymeReducer from "../pages/sites2/description/synonyme/redux/SiteSynonymeReducer";
import SiteRegimefoncierReducer from "../pages/sites2/description/foncier/redux/SiteRegimefoncierReducer";
import SiteRedacteurReducer from "../pages/sites2/description/redacteur/redux/SiteRedacteurReducer";
import SiteBioclimatReducer from "../pages/sites2/description/bioclimat/redux/SiteBioclimatReducer";
import SiteStatutConservationReducer from "../pages/sites2/description/statut/redux/SiteStatutConservationReducer";
import SiteDelimitationReducer from "../pages/sites2/description/delimitation/redux/SiteDelimitationReducer";

import OldSiteReducer from "../pages/sites2/redux/old/OldSiteReducer";
import Site2Reducer from "../pages/sites2/redux/SiteReducer";*/
import MapReducer from "./site/map/MapReducer";
import SiteReducer from "./site/SiteReducer";
import SiteValeurReducer from "./site/valeur/SiteValeurReducer";
import EspeceReducer from "./espece/EspeceReducer";
import ImportationReducer from "./recensement/importations/ImportationReducer";

const middlewares = [thunkMiddleware];
if (isDevEnv()) {
    //const createDebugger = require('redux-flipper').default;
    //middlewares.push(createDebugger());
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__({
        trace: true,
    })
}


const appReducer = combineReducers({
    globaleReducer: GlobaleReducer,
    userReducer: UserReducer,
    personneReducer: PersonneReducer,

    typeFoncierReducer: TypeFoncierReducer,
    typeBioclimatReducer: TypeBioclimatReducer,

    typeSiteReducer: TypeSiteReducer,

    mapReducer: MapReducer,
    siteReducer: SiteReducer,
    especeReducer: EspeceReducer,
    siteValeurReducer: SiteValeurReducer,
    importationReducer: ImportationReducer,

    //complexeReducer: ComplexeReducer,
    //decoupageAdminReducer: DecoupageAdminReducer,

    //oldSiteReducer: OldSiteReducer,
    //site2Reducer: Site2Reducer,

    //siteDelimitationReducer: SiteDelimitationReducer,
    //siteDescriptionReducer: SiteDescriptionReducer,
    //siteRechercheScReducer: SiteRechercheScReducer,
    //siteBassinsVersantReducer: SiteBassinsVersantReducer,
    //siteSynonymeReducer: SiteSynonymeReducer,
    //siteRegimefoncierReducer: SiteRegimefoncierReducer,
    //siteRedacteurReducer: SiteRedacteurReducer,
    //siteBioclimatReducer: SiteBioclimatReducer,
    //siteStatutConservationReducer: SiteStatutConservationReducer,

    //regionNaturelleReducer: RegionNaturelleReducer,

});


const initialState = appReducer({}, {});

const rootReducer = (state, action) => {
    if (action.type === 'LOGOUT') {
        state = initialState;
    }

    return appReducer(state, action);
};

const store = legacy_createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middlewares),
        //window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    ),
);

export default store;

