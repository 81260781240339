import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {Field, Form} from 'react-final-form';

import React, {useEffect, useState} from 'react';
import {composeValidators, getFormErrorMessage, identity, isFormFieldValid, required} from "../utils";
import classNames from "classnames";
import {Password} from "primereact/password";

import {connect} from "react-redux";
import {Login} from "../redux/UserActions";


const LoginPage = (props) => {
    const [selectedRecord, setSelectedRecord] = useState({
        username: 'admin',
        password: '123456Pp@'
    })



    const validate = (data) => {
        let errors = {};
        if (!data.username) {
            errors.username = "Le nom d'utilisateur obligatoire";
        }
        if (!data.password) {
            errors.password = 'Le mot de passe est obligatoire';
        }
        return errors;
    };


    const submitLogin = async (data, form) => {
        if (data.username.trim() && data.password.trim()) {
            /*authService.login(data).then(data => {
                if (data.access) {
                    localStorage.setItem('token', data.access)
                    setUsercontext({
                        user: data,
                        logged: true,
                        token: data.access,
                    });
                    return <Redirect to="/"/>
                }
            });*/
            props.Login(data.username, data.password)
        }
    }


    return (
        <>
            <div className="card" style={{height: 'calc(100vh - 137px)'}}>
                <div className="grid">
                    <div className="col-4"></div>
                    <div className="col-4">
                        <div className="surface-card p-4 shadow-2 border-round w-full">
                            <div className="text-center mb-5">
                                <img src="images/ibis_logo.png" alt="hyper" height="50" className="mb-3"/>
                                <div className="text-900 text-3xl font-medium mb-3">Recencement des Oiseaux d'Eau du Nord Afrique</div>
                                <div className="text-900 text-3xl font-medium mb-3">تعداد الطيور المائية لشمال افريقية</div>
                                <div className="text-900 text-3xl font-medium mb-3">North African Waterbird Census</div>
                                {/*<span className="text-600 font-medium line-height-3">Don't have an account?</span>
                <button className="p-link font-medium no-underline ml-2 text-blue-500 cursor-pointer">Create today!</button>*/}
                            </div>

                            <div>
                                <Form
                                    onSubmit={submitLogin}
                                    initialValues={selectedRecord}
                                    validate={validate}
                                    render={({
                                                 errors,
                                                 handleSubmit,
                                                 touched,
                                             }) => (
                                        <form
                                            onSubmit={handleSubmit}
                                            className="p-fluid"
                                            id="siteForm">


                                            <Field parse={identity}
                                                   name="username"
                                                   validate={
                                                       composeValidators(
                                                           required("username"),
                                                       )}
                                                   render={({input, meta}) => (
                                                       <div className="field col-12 md:col-12">
                                                           <label htmlFor="username" className={classNames({'p-error': isFormFieldValid(meta)})}>Nom d'utilisateur*</label>
                                                           <InputText
                                                               id="username"
                                                               {...input}
                                                               className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                                           />
                                                           {getFormErrorMessage(meta)}
                                                       </div>
                                                   )}/>
                                            <Field parse={identity}
                                                   name="password"
                                                   validate={
                                                       composeValidators(
                                                           required("password"),
                                                       )}
                                                   render={({input, meta}) => (
                                                       <div className="field col-12 md:col-12">
                                                           <label htmlFor="password" className={classNames({'p-error': isFormFieldValid(meta)})}>Mot de passe*</label>
                                                           <Password
                                                               id="password"
                                                               toggleMask
                                                               feedback={false}
                                                               {...input}
                                                               className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                                           />
                                                           {getFormErrorMessage(meta)}
                                                       </div>
                                                   )}/>

                                            <Button label="Se connecter" icon="pi pi-user" className="w-full"/>

                                        </form>
                                    )}/>


                            </div>
                        </div>
                    </div>
                    <div className="col-4"></div>
                </div>
            </div>
        </>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};


const mapStateToProps = state => {
    return {
        authToken: state.userReducer.authToken,
        isLoggedIn: state.userReducer.isLoggedIn,
    };
};
export default connect(mapStateToProps, {Login})(React.memo(LoginPage, comparisonFn));


