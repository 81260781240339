import React from "react";
import { Message } from "primereact/message";
import L from "leaflet";
import Store from "../redux/Store";

export const isDevEnv = () => {
    return process.env.NODE_ENV === "development";
};

export const getGlobaleToast = (config) => {
    let v = Store.getState().globaleReducer.TOAST.current;
    v.show(config);
};

export const formatDate = (value) => {
    value = new Date(value);
    return value.toLocaleDateString("fr-Fr", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
    });
};

export const formatDateTime = (value) => {
    value = new Date(value);
    return value.toLocaleDateString("fr-Fr", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
    });
};

export const formatDateCA = (value) => {
    value = new Date(value);
    return value.toLocaleDateString("fr-CA", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
    });
};

export const formatDateYYMMDD = (value) => {
    value = new Date(value);
    return value.toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    });
};

export const getNewDate = (value) => {
    return new Date(value);
};

export const getNewHeur = (value) => {
    return new Date(value);
};

export const identity = (value) => {
    return value;
};

export const parse = (data) => {
    return isNaN(parseFloat(data.value)) ? 0 : parseFloat(data.value);
};

export const composeValidators =
    (...validators) =>
    (value) =>
        validators.reduce((error, validator) => error || validator(value), undefined);

export const required = (name) => (value) => value ? undefined : `Le champ ${name} est obligatoire`;
export const mustBeNumber = (name) => (value) => isNaN(value) ? `Le champ ${name} ne doit comporter que des nombres` : undefined;
export const minValue = (min, name) => (value) => isNaN(value) || value >= min ? undefined : `Le champ ${name} Doit être supérieur à ${min}`;
export const maxValue = (max, name) => (value) => isNaN(value) || value <= max ? undefined : `Le champ ${name} ne Doit pas être supérieur à ${max}`;
export const maxLength = (taille, name) => (value) => isNaN(value) || `${value}`.length <= taille ? undefined : `Le champ ${name} ne Doit pas dépassé ${taille} caractéres`;
export const maxLengthString = (taille, name) => (value) => value.length <= taille ? undefined : `Le champ ${name} ne Doit pas dépassé ${taille} caractéres`;

export const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
export const getFormErrorMessage = (meta) => isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;

export const findIndexById = (list, id) => {
    let index = -1;
    for (let i = 0; i < list.length; i++) {
        if (list[i].id === id) {
            index = i;
            break;
        }
    }

    return index;
};

export const createId = () => {
    let id = "";
    let chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return id;
};

export const exportCSV = (dt) => {
    if (dt != null) return dt.current.exportCSV();
};

export const onInputChange = (e, name, selectedSite, setSelectedSite) => {
    const val = (e.target && e.target.value) || "";
    let _selectedSite = { ...selectedSite };
    _selectedSite[`${name}`] = val;
    setSelectedSite(_selectedSite);
};

/*const onNumberInputChange = (e, name) => {
    const re = /^[0-9\b]+$/;

    const val = (e.target && e.target.value) || '';
    if (val === '' || re.test(val)) {
        let _selectedSite = {...selectedSite};
        _selectedSite[`${name}`] = val;
        setSelectedSite(_selectedSite);
    }
}*/

export const allValuesAreTrue = (array) => {
    return Object.values(array).every(Boolean);
};

export const returnAllErrorsMessage = (errors) => {
    return Object.entries(errors).map(([key, value]) => (
        <div className="col-12 md:col-3" key={key}>
            <Message severity="error" text={value} />
        </div>
    ));
};

export const focusNextInputByRef = (event, inputRef) => {
    if (event.key.toLowerCase() === "enter") {
        event.preventDefault();
        inputRef.current.focus();
    }
};

function autoTab(event) {
    const BACKSPACE_KEY = 8;
    const DELETE_KEY = 46;
    if (event.key.toLowerCase() === "enter") {
        let tabIndex = event.target.getAttribute("tabindex");
        tabIndex = Number(tabIndex);
        const form = event.target.form;
        let inputs = Array.from(form.elements);
        inputs.map((item) => {
            let index = Number(item.getAttribute("tabindex"));
            if (index === tabIndex + 1) {
                item.focus();
            }
        });
    }

    /*let tabindex = $(e.target).attr("data-index") || 0;
    tabindex = Number(tabindex);
    let elem = null;
    if (e.keyCode === BACKSPACE_KEY) {
        elem = tabindex > 0 && elemRefs[tabindex - 1];
    } else if (e.keyCode !== DELETE_KEY) {
        elem = tabindex < elemRefs.length - 1 && elemRefs[tabindex + 1];
    }
    if (elem) {
        elem.current.focus();
    }*/
    event.preventDefault();
}

export const isFormikFormFieldValid = (formik, name) => !!(formik.touched[name] && formik.errors[name]);

export const getFormikFormErrorMessage = (formik, name) => {
    if (typeof formik.errors[name] === "object") {
        return isFormikFormFieldValid(formik, name) && <small className="p-error">{formik.errors[name].id}</small>;
    }
    return isFormikFormFieldValid(formik, name) && <small className="p-error">{formik.errors[name]}</small>;
};

export const getCentroid = (arr) => {
    if (arr) return L.polygon(arr).getBounds().getCenter();
    else return [31.558059, -7.483406];
};

export const constructFilter = (lazyParams) => {
    let { page, rows, filters, sortField, sortOrder } = lazyParams;
    page = page === 0 || page === undefined ? 1 : page + 1;
    let ordering = sortField !== undefined && sortField !== null && sortField.length > 0 ? `&ordering=${sortOrder === 1 ? sortField : "-".concat(sortField)}` : "";

    let entries = Object.entries(filters);
    let newFilter =
        "&" +
        entries
            .filter((item) => {
                let value = item[1].value;
                return value !== undefined && value !== null && value.length > 0;
            })
            .map((p) => {
                return p[0] + "=" + p[1].value;
            })
            .join("&")
            .replace("global", "search");
    return `?page=${page}&page_size=${rows}${newFilter}${ordering}`;
};

export const clearTimeoutVar = (timeout) => {
    if (timeout) {
        clearTimeout(timeout);
    }
};
