import axios from 'axios';
import {API_URL} from "../../config";

export class TypeCouvertureService {
    constructor() {
        this.endpoint = "couvertures"
    }

    getList({page, rows, filters, sortField, sortOrder}, token) {
        //?ordering=-published_at&page=1&page_size=10&search=d
        //?ordering=-published_at&page=1&page_size=10&search=d&title=a
        page = page === 0 || page === undefined ? 1 : page
        let libelle = filters.libelle.value
        libelle = libelle !== undefined && libelle !== null && libelle.length > 0 ? `&libelle=${libelle.replace('&', '')}` : ''
        let description = filters.description.value
        description = description !== undefined && description !== null && description.length > 0 ? `&description=${description.replace('&', '')}` : ''
        let valeur = filters.valeur.value
        valeur = valeur !== undefined && valeur !== null && valeur.length > 0 ? `&valeur=${valeur.replace('&', '')}` : ''
        let global = filters.global.value
        global = global !== undefined && global !== null && global.length > 0 ? `&search=${global.replace('&', '')}` : ''
        let ordering = sortField !== undefined && sortField !== null && sortField.length > 0 ? `&ordering=${sortOrder === 1 ? sortField : '-'.concat(sortField)}` : ''

        let filter = `${libelle}${description}${valeur}${global}${ordering}`
        //filter += `code=${code}`
        //filter += `&nom=${nom}`


        return axios.get(`${API_URL}/${this.endpoint}?page=${page}&page_size=${rows}${filter}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {

            return res.data;
        }).catch((error) => {
            console.error(error)
        })
    }

    getCombo(q, token) {
        return axios.get(`${API_URL}/${this.endpoint}combo/?search=${q}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {

            return res.data;
        }).catch((error) => {
            console.error(error)
        })
    }

    addOne(data, token) {
        return axios.post(`${API_URL}/${this.endpoint}/`, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {

            return res.data;
        })
    }

    updateOne(data, token) {
        return axios.patch(`${API_URL}/${this.endpoint}/${data.id}/`, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {
            return res.data;
        }).catch((error) => {
            if (error.response) {
                return error.response.data
            }
        })
    }

    deleteOne(id, token) {
        return axios.delete(`${API_URL}/${this.endpoint}/${id}/`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {
            return res.data;
        }).catch((error) => {
            if (error.response) {
                return error.response.data
            }
        })
    }
}
