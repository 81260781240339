import React from 'react';
import {Button} from "primereact/button";

const ActionBodyTemplate = (props) => {
    return (<div className="actions">
        {props.editRecord && <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={(e) => props.editRecord(props.data)}/>}
        <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2" onClick={(e) => props.deleteRecord(props.data)}/>
    </div>);
}

export default ActionBodyTemplate



