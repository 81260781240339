import React, {useEffect, useState} from "react";
import {connect} from "react-redux";

import {useFormik} from "formik";

import {Button} from "primereact/button";
import {Chip} from "primereact/chip";
import {InputSwitch} from "primereact/inputswitch";

import {cancelEditSite, editSelectedSite} from "../../../redux/site/SiteActions";
import * as Yup from "yup";
import {classNames} from "primereact/utils";
import {isFormikFormFieldValid} from "../../../utils";
import {InputNumber} from "primereact/inputnumber";
import {InputText} from "primereact/inputtext";
import ModalSynonyms from "./ModalSynonyms";


const SiteIdentification = (props) => {
    const [displaySiteSynonymFrom, setDisplaySiteSynonymFrom] = useState(false);

    const validationSchema = Yup.object().shape({
        code: Yup
            .number()
            .typeError('Ce champ ne doit contenir que des nombre positive')
            .required("Ce champ est obligatoire")
            .min(1000)
            .max(9999, 'Ce champ ${path} ne Doit pas être supérieur à ${max}')
            .test('len', "Ce champ ${path} Doit être supérieur à ${min} ", val => val + ""
                .length >= 4),

        //.integer("Ce champ ne doit contenir que des nombre positive")
        //.required("Ce champ est obligatoire").nullable(),
        typesite: Yup.object().shape({
            id: Yup.string().required("Ce champ est obligatoire").nullable(),
        }),
        nom: Yup.string().required("code is required").nullable(),
    })

    const formik = useFormik({
        initialValues: props.selected,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if (values.code && values.nom.trim()) {
                let record = {...values}

                record.typesite_id = record.typesite?.id
                record.commune_id = record.communeadministrative?.id
                record.regionnaturelle_id = record.regionnaturelle?.id
                record.complexe_id = record.complexe?.id

                delete record.typesite
                delete record.regionadministrative
                delete record.provinceadministrative
                delete record.communeadministrative
                delete record.commune
                delete record.regionnaturelle
                delete record.complexe

                props.saveRecord(record)
                formik.resetForm();
            }
        }
    });

    useEffect(() => {
        if (props.selected) {
            formik.setValues(props.selected)
        }
    }, [props.selected]);


    const LiBlock = ({id, label, value}) => {
        return (
            <li key={id} className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                <div className="text-500 w-6 md:w-4 font-medium">{label}</div>
                <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{value}</div>
            </li>
        )
    }


    const ActionBtns = () => {
        return <React.Fragment>
            <div className="surface-0 fixed pl-4" style={{bottom: '30px'}}>
                {props.editionMode ? (
                    <>
                        <Button
                            icon="pi pi-save"
                            type="submit"
                            form="formTabSiteRechercheSc"
                            className="mr-2"/>
                        <Button
                            icon="pi pi-times"
                            className="p-button-secondary"
                            type="button"
                            onClick={() => props.cancelEditSite()}/>
                    </>
                ) : (
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-warning"
                        type="button"
                        onClick={() => props.editSelectedSite(props.selected)}/>
                )}
            </div>
        </React.Fragment>
    };


    return (
        <form onSubmit={formik.handleSubmit} className="p-fluid" id="siteForm">
            <div className="surface-0 p-2">
                <div className="font-medium text-3xl text-900 mb-3">{props.selected.site_nom}</div>
                <ul className="list-none p-0 m-0">
                    <li key="1" className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                        <div className="text-500 w-6 md:w-4 font-medium">Code National</div>
                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                            {
                                props.editionMode ? <InputNumber
                                    id='code'
                                    inputId="code"
                                    value={formik.values.code}
                                    onValueChange={formik.handleChange}
                                    //min={1000} max={9999}
                                    className={classNames({
                                        "p-invalid": isFormikFormFieldValid(formik, "code")
                                    })}
                                /> : props.selected.code
                            }
                        </div>
                    </li>

                    <li key="2" className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                        <div className="text-500 w-6 md:w-4 font-medium">Code Interational</div>
                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                            {
                                props.editionMode ? <InputNumber
                                    id='codwi'
                                    inputId="codwi"
                                    value={formik.values.codwi}
                                    onValueChange={formik.handleChange}
                                    className={classNames({
                                        "p-invalid": isFormikFormFieldValid(formik, "codwi")
                                    })}
                                /> : props.selected.codwi
                            }
                        </div>
                    </li>
                    <li key="3" className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                        <div className="text-500 w-6 md:w-4 font-medium">Nom</div>
                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                            {
                                props.editionMode ? <InputText
                                    id="nom"
                                    name="nom"
                                    value={formik.values.nom}
                                    onChange={formik.handleChange}
                                    autoFocus
                                    className={classNames({
                                        "p-invalid": isFormikFormFieldValid(formik, "nom")
                                    })}
                                /> : props.selected.nom
                            }
                        </div>
                    </li>
                    <li key="4" className="flex align-props.selecteds-center py-3 px-2 border-top-1 border-300 flex-wrap">
                        <div className="text-500 w-6 md:w-3 font-medium">Autres noms</div>
                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">

                            {
                                props.selected?.synonyms?.map(item => {
                                    return <Chip key={item.synonyme} label={item.synonyme} className="mr-2"/>
                                })
                            }
                            {props.editionMode && <Button
                                icon="pi pi-pencil"
                                className="p-button-rounded p-button-warning"
                                aria-label="Notification"
                                onClick={(e) => setDisplaySiteSynonymFrom(true)}
                            />}

                            <ModalSynonyms show={displaySiteSynonymFrom} hide={() => setDisplaySiteSynonymFrom(false)}/>

                        </div>
                    </li>
                    <li key="5" className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                        <div className="field-checkbox margin-right">
                            <label htmlFor="binary" className="margin-right">Site simple</label>
                            <InputSwitch
                                disabled={!props.editionMode}
                                id="simple"
                                name="simple"
                                checked={formik.values.simple}
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div className="field-checkbox margin-right">
                            <label htmlFor="binary" className="margin-right">Site isolé</label>
                            <InputSwitch
                                disabled={!props.editionMode}
                                id="isol"
                                name="isol"
                                checked={formik.values.isol}
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div className="field-checkbox margin-right">
                            <label htmlFor="binary" className="margin-right">Zone humide</label>
                            <InputSwitch
                                disabled={!props.editionMode}
                                id="zonehumide"
                                name="zonehumide"
                                checked={formik.values.zonehumide}
                                onChange={formik.handleChange}
                            />
                        </div>
                    </li>
                    <LiBlock id={6} label="Type" value={props.selected?.typesite?.nom}/>
                    <LiBlock id={7} label="Région" value={props.selected?.commune?.region?.nom_fr}/>
                    <LiBlock id={8} label="Province" value={props.selected?.commune?.province?.nom_fr}/>
                    <LiBlock id={9} label="Commune" value={props.selected?.commune?.commune?.nom_fr}/>
                    <LiBlock id={10} label="Région naturelle" value={props.selected?.complexe?.regionnaturelle?.libellezone}/>
                    <LiBlock id={11} label="Complexe" value={props.selected?.complexe?.nom}/>
                </ul>
            </div>
            <ActionBtns/>
        </form>
    );
}


const mapStateToProps = state => {
    return {
        loading: state.siteReducer.loading,
        selected: state.siteReducer.selectedRecord,
        editionMode: state.siteReducer.editionMode,
    };
};

export default connect(mapStateToProps, {editSelectedSite, cancelEditSite})(SiteIdentification)


