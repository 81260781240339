import {isDevEnv} from "../utils";

const _API_ADRESSE = isDevEnv() ? 'localhost:8000' : 'iwcapi.biodiversity.ma'
const API_URL = isDevEnv() ? `http://${_API_ADRESSE}/api` : `https://${_API_ADRESSE}/api`;
const MEDIA_URL = `http://${_API_ADRESSE}`;


export {API_URL, MEDIA_URL};


