import React from 'react';
import {Popup, Tooltip} from "react-leaflet";

const MapInfoPopUp = (props) => {

    const {item} = props

    return (
        <React.Fragment>
            <Popup>
                <h6>{item.site_nom}</h6>
            </Popup>
            <Tooltip sticky offset={[0, 20]} direction="center" opacity={1} className="my-labels">{item.site_nom}</Tooltip>
        </React.Fragment>
    )
};


export default MapInfoPopUp
