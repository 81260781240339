import {REDUX_CONST} from "../../../config/constats";

let emptyRecord = {
    id: null,
    site_id: "",
    typevaleur_id: ''
};
const initialState = {
    dialogTitle: "",
    loading: false,
    loadingValidationProcess: true,
    dataList: [],
    editionMode: false,
    selectedRecord: {},
    showErrorMessage: {
        show: false,
        message: ''
    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case REDUX_CONST.SITE_VALEUR.LOADING:
            return {
                ...state,
                loading: action.payload
            };
        case REDUX_CONST.SITE_VALEUR.LOADING_VALIDATION_PROCESS:
            return {
                ...state,
                loadingValidationProcess: action.payload
            };
        case REDUX_CONST.SITE_VALEUR.LIST:
            return {
                ...state,
                dataList: action.payload,
                selectedRecord: {},
            };
        case REDUX_CONST.SITE_VALEUR.SET_SELECTED:
            return {
                ...state,
                selectedRecord: {...action.payload},
                loading: false,
                editionMode: false,
            };
        case REDUX_CONST.SITE_VALEUR.ADD_NEW:

            return {
                ...state,
                dialogTitle: 'Nouvelle valeur de site',
                editionMode: true,
                selectedRecord: {
                    id: null,
                    site_id: "",
                    typevaleur_id: action.payload.id
                },
                loadingValidationProcess: false
            };
        case REDUX_CONST.SITE_VALEUR.EDIT_SELECTED:
            return {
                ...state,
                dialogTitle: 'Edition valeur de site',
                editionMode: true,
                selectedRecord: action.payload,
                loadingValidationProcess: false,
            };
        case REDUX_CONST.SITE_VALEUR.CANCEL_EDIT:
            return {
                ...state,
                editionMode: false,
                loadingValidationProcess: false
            };
        case REDUX_CONST.SITE_VALEUR.DATA_SAVED:
            let {operation, data} = action.payload;
            return {
                ...state,
                selectedRecord: data,
                editionMode: false,
                loadingValidationProcess: false
            };
        default:
            return state;
    }
}
