import React, {useEffect, useState,useRef} from 'react';
import "react-leaflet-fullscreen/dist/styles.css";
import "leaflet-draw/dist/leaflet.draw.css";
import {RecensementService} from '../../../service/recensement/RecensementService';

import {EspeceNatService} from "../../../service/espece/EspeceNatService";
import {AutoComplete} from "primereact/autocomplete";
import {Button} from "primereact/button";
import {Accordion, AccordionTab} from "primereact/accordion";
import {InputSwitch} from "primereact/inputswitch";
import {SiteService} from "../../../service/espace/SiteService";
import {API_URL} from "../../../config";
import axios from "axios";
import fileDownload from "js-file-download";
import {trackPromise, usePromiseTracker} from "react-promise-tracker";
import {BlockUI} from "primereact/blockui";
import {ProgressSpinner} from "primereact/progressspinner";
import {connect} from "react-redux";
import {Login} from "../../../redux/UserActions";
import { Menu } from 'primereact/menu';


const center = [31.558059, -7.483406,]

const RecSynthesePage = (props) => {
    const menu = useRef(null);
    const menu2 = useRef(null);

    const [data, setData] = useState()

    const {promiseInProgress} = usePromiseTracker();


    const [yearRecComboItems, setYearRecComboItems] = useState();
    const [filteredAnnees, setFilteredAnnees] = useState();
    const [selectedAnnee, setSelectedAnnee] = useState(null);
    const [selectAllYears, setSelectAllYears] = useState(false)

    const [filteredAnneesMoyFrom, setFilteredAnneesMoyFrom] = useState();
    const [filteredAnneesMoyTo, setFilteredAnneesMoyTo] = useState();
    const [selectedAnneeMoyFrom, setSelectedAnneeMoyFrom] = useState(null);
    const [selectedAnneeMoyTo, setSelectedAnneeMoyTo] = useState(null);


    const [especeResComboItems, setEspeceResComboItems] = useState()
    const [filteredEspeces, setFilteredEspeces] = useState();
    const [selectedEspece, setSelectedEspece] = useState(null);
    const [selectAllEspece, setSelectAllEspece] = useState(false)


    const [siteComboItems, setSiteComboItems] = useState()
    const [filteredSites, setFilteredSites] = useState();
    const [selectedSite, setSelectedSite] = useState(null);
    const [selectAllSite, setSelectAllSite] = useState(false)


    const [filteredAnneesTempoFrom, setFilteredAnneesTempoFrom] = useState();
    const [filteredAnneesTempoTo, setFilteredAnneesTempoTo] = useState();
    const [selectedAnneeTempoFrom, setSelectedAnneeTempoFrom] = useState(null);
    const [selectedAnneeTempoTo, setSelectedAnneeTempoTo] = useState(null);
    const [selectAllYearsTempo, setSelectAllYearsTempo] = useState(false)


    const [filteredAnneesEspeceTempoFrom, setFilteredAnneesEspeceTempoFrom] = useState();
    const [filteredAnneesEspeceTempoTo, setFilteredAnneesEspeceTempoTo] = useState();
    const [selectedAnneeEspeceTempoFrom, setSelectedAnneeEspeceTempoFrom] = useState(null);
    const [selectedAnneeEspeceTempoTo, setSelectedAnneeEspeceTempoTo] = useState(null);
    const [selectAllYearsEspeceTempo, setSelectAllYearsEspeceTempo] = useState(false)


    const [isLoading, setIsLoading] = useState(false)

    const recensementService = new RecensementService();
    const especeNatService = new EspeceNatService();
    const siteService = new SiteService();


    useEffect(() => {
        loadEspeceCombo()
        loadYearRecCombo()
        loadSiteCombo()
    }, []);

    if (isLoading) return "Loading...";


    const loadMap = () => {
        recensementService.getRecNbrByYearSiteEspece(`annee=${selectedAnnee?.annee_rec ?? ''}&espece_id=${selectedEspece?.id ?? ''}`, props.authToken).then(data => setData(data));
    }


    const loadYearRecCombo = () => {
        recensementService.getComboYear("", props.authToken).then(data => {
            setYearRecComboItems(data)
        });
    }

    const loadEspeceCombo = () => {
        especeNatService.getCombo("", props.authToken).then(data => {
            setEspeceResComboItems(data)
        });
    }

    const loadSiteCombo = () => {
        siteService.getCombo("", props.authToken).then(data => {
            setSiteComboItems(data)
        });
    }

    const searchEspece = (event) => {
        setTimeout(() => {
            let _filteredEspeces;
            if (!event.query.trim().length) {
                _filteredEspeces = [...especeResComboItems];
            } else {
                _filteredEspeces = especeResComboItems.filter((espece) => {
                    return espece.espece.toLowerCase().includes(event.query.toLowerCase());
                });
            }

            setFilteredEspeces(_filteredEspeces);
        }, 250);
    }
    const searchSite = (event) => {
        setTimeout(() => {
            let _filteredSite;
            if (!event.query.trim().length) {
                _filteredSite = [...siteComboItems];
            } else {
                _filteredSite = siteComboItems.filter((site) => {
                    return site.site_nom.toLowerCase().includes(event.query.toLowerCase());
                });
            }

            setFilteredSites(_filteredSite);
        }, 250);
    }
    const searchYear = (event, _case) => {

        setTimeout(() => {
            let _filteredYears;
            if (!event.query.trim().length) {
                _filteredYears = [...yearRecComboItems];
            } else {
                _filteredYears = yearRecComboItems.filter((v) => {
                    return v.annee_rec.toString().includes(event.query.toString())
                });
            }
            switch (_case) {
                case 'ALL':
                    setFilteredAnnees(_filteredYears);
                    break;
                case 'MOY_FROM':
                    setFilteredAnneesMoyFrom(_filteredYears.reverse());
                    break;
                case 'MOY_TO':
                    setFilteredAnneesMoyTo(_filteredYears);
                    break;
                case 'TMPO_FROM':
                    setFilteredAnneesTempoFrom(_filteredYears.reverse());
                    break;
                case 'TMPO_TO':
                    setFilteredAnneesTempoTo(_filteredYears);
                    break;
                case 'TMPO_ESPECE_FROM':
                    setFilteredAnneesEspeceTempoFrom(_filteredYears.reverse());
                    break;
                case 'TMPO_ESPECE_TO':
                    setFilteredAnneesEspeceTempoTo(_filteredYears);
                    break;
                    default:
                        break;
            }

        }, 250);
    }

    const items = [
        {
            label: 'Options',
            items: [
                {
                    label: 'Moyenne',
                    icon: 'pi pi-sliders-h',
                    command: () => {
                        //toast.current.show({ severity: 'success', summary: 'Updated', detail: 'Data Updated', life: 3000 });
                        loadEffectefAnnuels("mean")
                    }
                },
                {
                    label: 'Maximum',
                    icon: 'pi pi-align-center',
                    command: () => {
                        loadEffectefAnnuels("max")
                    }
                }
            ]
        },
    ]


    const items2 = [
        {
            label: 'Options',
            items: [
                {
                    label: 'Moyenne',
                    icon: 'pi pi-sliders-h',
                    command: () => {
                        //toast.current.show({ severity: 'success', summary: 'Updated', detail: 'Data Updated', life: 3000 });
                        loadEffectefMoyens("AVG")
                    }
                },
                {
                    label: 'Maximum',
                    icon: 'pi pi-align-center',
                    command: () => {
                        loadEffectefMoyens("MAX")
                    }
                }
            ]
        },
    ]



    const loadEffectefAnnuels = (agg='max') => {
        // TO DO afficher un message pour donner a l'utilisateur la main pour choisir entre la fonction d'aggrégation des totaux Moy ou Max
        if (selectedAnnee || selectAllYears) {
            const url = `${API_URL}/effannuels/${selectAllYears ? 1 : 0}/${selectedAnnee ? selectedAnnee.annee_rec : 0}/${agg}`;
            trackPromise(axios.get(url, {
                responseType: 'blob',
            }).then((response) => {
                let filename = response.headers['content-disposition'].split('filename=')[1].split('.')[0];
                let extension = response.headers['content-disposition'].split('.')[1].split(';')[0];
                filename = `${filename}.${extension}`
                fileDownload(response.data, filename)
            }))
        }
    }

    const loadEffectefMoyens = (agg='AVG') => {
        if (selectedAnneeMoyFrom || selectedAnneeMoyTo) {
            const url = `${API_URL}/effmoyens/${selectedAnneeMoyFrom ? selectedAnneeMoyFrom.annee_rec : 0}/${selectedAnneeMoyTo ? selectedAnneeMoyTo.annee_rec : 0}/${agg}`;
            trackPromise(axios.get(url, {
                responseType: 'blob',
            }).then((response) => {
                let filename = response.headers['content-disposition'].split('filename=')[1].split('.')[0];
                let extension = response.headers['content-disposition'].split('.')[1].split(';')[0];
                filename = `${filename}.${extension}`
                fileDownload(response.data, filename)
            }))
        }
    }

    const loadVariationTempoSite = () => {
        if (selectedSite || selectAllSite || selectedAnneeTempoFrom || selectedAnneeTempoTo || selectAllYearsTempo) {
            const url = `${API_URL}/variationtemposite/${selectAllSite ? 1 : 0}/${selectAllYearsTempo ? 1 : 0}/${selectAllSite ? 0 : selectedSite.id}/${selectedAnneeTempoFrom ? selectedAnneeTempoFrom.annee_rec : 0}/${selectedAnneeTempoTo ? selectedAnneeTempoTo.annee_rec : 0}`;
            trackPromise(axios.get(url, {
                responseType: 'blob',
            }).then((response) => {
                let filename = response.headers['content-disposition'].split('filename=')[1].split('.')[0];
                let extension = response.headers['content-disposition'].split('.')[1].split(';')[0];
                filename = `${filename}.${extension}`
                fileDownload(response.data, filename)
            }))
        }
    }

    const loadVariationTempoEspece = () => {
        if (selectedEspece || selectAllEspece || selectedAnneeEspeceTempoFrom || selectedAnneeEspeceTempoTo || selectAllYearsEspeceTempo) {
            const url = `${API_URL}/variationtempoespece/${selectAllEspece ? 1 : 0}/${selectAllYearsEspeceTempo ? 1 : 0}/${selectAllEspece ? 0 : selectedEspece.id}/${selectedAnneeEspeceTempoFrom ? selectedAnneeEspeceTempoFrom.annee_rec : 0}/${selectedAnneeEspeceTempoTo ? selectedAnneeEspeceTempoTo.annee_rec : 0}`;
            trackPromise(axios.get(url, {
                responseType: 'blob',
            }).then((response) => {
                let filename = response.headers['content-disposition'].split('filename=')[1].split('.')[0];
                let extension = response.headers['content-disposition'].split('.')[1].split(';')[0];
                filename = `${filename}.${extension}`
                fileDownload(response.data, filename)
            }))
        }
    }
    return (<BlockUI blocked={promiseInProgress} fullScreen>
            {promiseInProgress && (<div style={{position: 'absolute', top: '50%', left: '40%', zIndex: 30, textAlign: 'center'}}>
                <ProgressSpinner
                    style={{width: '50px', height: '50px'}}
                    trokeWidth="8"
                    fill="var(--surface-ground)"
                    animationDuration=".5s"
                />
            </div>)}

            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <div className="p-fluid formgrid grid">
                            <div className="col-12">
                                <h3 style={{textAlign: center}}>Syncthèse des recensements. </h3>
                            </div>
                            <div className="col-12 md:col-12">
                                <div className="card">
                                    <Accordion>
                                        <AccordionTab header="Effectifs annuels">
                                            <div className="p-fluid formgrid grid">
                                                <div className="field col-12 md:col-3">
                                                    <h5>Années de recensement</h5>
                                                    <AutoComplete
                                                        disabled={selectAllYears}
                                                        id="annee"
                                                        field="annee_rec"
                                                        dropdown
                                                        showClear
                                                        //forceSelection
                                                        placeholder="Toutes les années"
                                                        suggestions={filteredAnnees}
                                                        completeMethod={e => searchYear(e, 'ALL')}
                                                        onChange={(e) => setSelectedAnnee(e.value)}
                                                        value={selectedAnnee}
                                                    />
                                                </div>
                                                <div className="field col-12 md:col-2"/>
                                                <div className="field col-12 md:col-3 ">
                                                    <h5>Toutes les années</h5>
                                                    <InputSwitch checked={selectAllYears} onChange={(e) => setSelectAllYears(e.value)}/>
                                                </div>
                                                <div className="field col-12 md:col-3"/>
                                                <div className="col-1">
                                                    <h5>&nbsp;</h5>
                                                    <Menu model={items} popup ref={menu} id="popup_menu" />
                                                    <Button label="Générer" icon="pi pi-bars" onClick={(event) => menu.current.toggle(event)} aria-controls="popup_menu" aria-haspopup />
                                                    {/* <Button label="Générer" icon="pi pi-file-excel" className="p-button-lg" onClick={loadEffectefAnnuels}/> */}
                                                </div>
                                            </div>
                                        </AccordionTab>
                                        <AccordionTab header="Effectifs moyens">
                                            <div className="p-fluid formgrid grid">
                                                <div className="field col-12 md:col-3">
                                                    <h5>Années de recensement de</h5>
                                                    <AutoComplete
                                                        id="yearmMoyFrom"
                                                        field="annee_rec"
                                                        dropdown
                                                        showClear
                                                        //forceSelection
                                                        placeholder="Toutes les années"
                                                        suggestions={filteredAnneesMoyFrom}
                                                        completeMethod={e => searchYear(e, 'MOY_FROM')}
                                                        onChange={(e) => setSelectedAnneeMoyFrom(e.value)}
                                                        value={selectedAnneeMoyFrom}
                                                    />
                                                </div>
                                                <div className="field col-12 md:col-3">
                                                    <h5>A</h5>
                                                    <AutoComplete
                                                        id="yearmMoyTo"
                                                        field="annee_rec"
                                                        dropdown
                                                        showClear
                                                        //forceSelection
                                                        placeholder="Toutes les années"
                                                        suggestions={filteredAnneesMoyTo}
                                                        completeMethod={e => searchYear(e, 'MOY_TO')}
                                                        onChange={(e) => setSelectedAnneeMoyTo(e.value)}
                                                        value={selectedAnneeMoyTo}
                                                    />
                                                </div>
                                                <div className="field col-12 md:col-5"/>
                                                <div className="col-1">
                                                    <h5>&nbsp;</h5>
                                                    <Menu model={items2} popup ref={menu2} id="popup_menu2" />
                                                    <Button label="Générer" icon="pi pi-bars" onClick={(event) => menu2.current.toggle(event)} aria-controls="popup_menu2" aria-haspopup />
                                                     {/* <Button label="Générer" icon="pi pi-file-excel" className="p-button-lg" onClick={loadEffectefMoyens}/> */}
                                                </div>
                                            </div>
                                        </AccordionTab>
                                        <AccordionTab header="Variation temporelle par site">
                                            <div className="p-fluid formgrid grid">
                                                <div className="field col-12 md:col-4">
                                                    <h5>Site</h5>
                                                    <AutoComplete
                                                        disabled={selectAllSite}
                                                        id="site"
                                                        field="site_nom"
                                                        dropdown
                                                        showClear
                                                        //forceSelection
                                                        placeholder="Tous les sites"
                                                        suggestions={filteredSites}
                                                        completeMethod={e => searchSite(e)}
                                                        onChange={(e) => setSelectedSite(e.value)}
                                                        value={selectedSite}
                                                    />
                                                </div>
                                                <div className="field col-12 md:col-2"/>
                                                <div className="field col-12 md:col-3 ">
                                                    <h5>Tous les sites</h5>
                                                    <InputSwitch checked={selectAllSite} onChange={(e) => setSelectAllSite(e.value)}/>
                                                </div>
                                                <div className="field col-12 md:col-3"/>
                                            </div>
                                            <div className="p-fluid formgrid grid">
                                                <div className="field col-12 md:col-2">
                                                    <h5>De</h5>
                                                    <AutoComplete
                                                        disabled={selectAllYearsTempo}
                                                        id="yearTempoFrom"
                                                        field="annee_rec"
                                                        dropdown
                                                        showClear
                                                        //forceSelection
                                                        placeholder="Toutes les années"
                                                        suggestions={filteredAnneesTempoFrom}
                                                        completeMethod={e => searchYear(e, 'TMPO_FROM')}
                                                        onChange={(e) => setSelectedAnneeTempoFrom(e.value)}
                                                        value={selectedAnneeTempoFrom}
                                                    />
                                                </div>
                                                <div className="field col-12 md:col-2">
                                                    <h5>A</h5>
                                                    <AutoComplete
                                                        disabled={selectAllYearsTempo}
                                                        id="yearTempoTo"
                                                        field="annee_rec"
                                                        dropdown
                                                        showClear
                                                        //forceSelection
                                                        placeholder="Toutes les années"
                                                        suggestions={filteredAnneesTempoTo}
                                                        completeMethod={e => searchYear(e, 'TMPO_TO')}
                                                        onChange={(e) => setSelectedAnneeTempoTo(e.value)}
                                                        value={selectedAnneeTempoTo}
                                                    />
                                                </div>
                                                <div className="field col-12 md:col-2"/>
                                                <div className="field col-12 md:col-3 ">
                                                    <h5>Toutes les années</h5>
                                                    <InputSwitch checked={selectAllYearsTempo} onChange={(e) => setSelectAllYearsTempo(e.value)}/>
                                                </div>
                                                <div className="field col-12 md:col-2"/>
                                                <div className="col-1">
                                                    <h5>&nbsp;</h5>
                                                    <Button label="Générer" icon="pi pi-file-excel" className="p-button-lg" onClick={loadVariationTempoSite}/>
                                                </div>
                                            </div>
                                        </AccordionTab>
                                        <AccordionTab header="Variation temporelle par espèce">
                                            <div className="p-fluid formgrid grid">
                                                <div className="field col-12 md:col-4">
                                                    <h5>Espèce</h5>
                                                    <AutoComplete
                                                        disabled={selectAllEspece}
                                                        id="espece"
                                                        field="espece"
                                                        dropdown
                                                        showClear
                                                        //forceSelection
                                                        placeholder="Tous les espèces"
                                                        suggestions={filteredEspeces}
                                                        completeMethod={e => searchEspece(e)}
                                                        onChange={(e) => setSelectedEspece(e.value)}
                                                        value={selectedEspece}
                                                    />
                                                </div>
                                                <div className="field col-12 md:col-2"/>
                                                <div className="field col-12 md:col-3 ">
                                                    <h5>Tous les espèces</h5>
                                                    <InputSwitch checked={selectAllEspece} onChange={(e) => setSelectAllEspece(e.value)}/>
                                                </div>
                                                <div className="field col-12 md:col-3"/>
                                            </div>
                                            <div className="p-fluid formgrid grid">
                                                <div className="field col-12 md:col-2">
                                                    <h5>De</h5>
                                                    <AutoComplete
                                                        disabled={selectAllYearsEspeceTempo}
                                                        id="yearTempoEspeceFrom"
                                                        field="annee_rec"
                                                        dropdown
                                                        showClear
                                                        //forceSelection
                                                        placeholder="Toutes les années"
                                                        suggestions={filteredAnneesEspeceTempoFrom}
                                                        completeMethod={e => searchYear(e, 'TMPO_ESPECE_FROM')}
                                                        onChange={(e) => setSelectedAnneeEspeceTempoFrom(e.value)}
                                                        value={selectedAnneeEspeceTempoFrom}
                                                    />
                                                </div>
                                                <div className="field col-12 md:col-2">
                                                    <h5>A</h5>
                                                    <AutoComplete
                                                        disabled={selectAllYearsEspeceTempo}
                                                        id="yearTempoTo"
                                                        field="annee_rec"
                                                        dropdown
                                                        showClear
                                                        //forceSelection
                                                        placeholder="Toutes les années"
                                                        suggestions={filteredAnneesEspeceTempoTo}
                                                        completeMethod={e => searchYear(e, 'TMPO_ESPECE_TO')}
                                                        onChange={(e) => setSelectedAnneeEspeceTempoTo(e.value)}
                                                        value={selectedAnneeEspeceTempoTo}
                                                    />
                                                </div>
                                                <div className="field col-12 md:col-2"/>
                                                <div className="field col-12 md:col-3 ">
                                                    <h5>Toutes les années</h5>
                                                    <InputSwitch checked={selectAllYearsEspeceTempo} onChange={(e) => setSelectAllYearsEspeceTempo(e.value)}/>
                                                </div>
                                                <div className="field col-12 md:col-2"/>
                                                <div className="col-1">
                                                    <h5>&nbsp;</h5>
                                                    <Button label="Générer" icon="pi pi-file-excel" className="p-button-lg" onClick={loadVariationTempoEspece}/>
                                                </div>
                                            </div>
                                        </AccordionTab>
                                    </Accordion>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </BlockUI>

    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

const mapStateToProps = state => {
    return {
        authToken: state.userReducer.authToken,
        isLoggedIn: state.userReducer.isLoggedIn,
    };
};
export default connect(mapStateToProps, {Login})(React.memo(RecSynthesePage, comparisonFn))
