import React, {useState} from 'react';
import {CircleMarker, LayerGroup, LayersControl, Polygon, TileLayer, useMapEvents,} from 'react-leaflet'
import {connect} from "react-redux";
import MapInfoPopUp from "./MapInfoPopUp";

const MapLayers = (props) => {
    const [zoom, setZoom] = useState(0);
    const map = useMapEvents({
        zoomend: () => {
            setZoom(map.getZoom())
        },
    })


    const purpleOptions = {color: 'purple'}
    const yellowOptions = {color: 'yellow'}
    const redOptions = {color: 'red'}


    const SiteDelimitations = () => {
        return props.data.map(item => {
            return <React.Fragment key={item.id}>

                {item?.delimitations?.coordinates ?
                    (
                        !props.editionMode && <Polygon
                            key={'P' + item.id}
                            data={item}
                            eventHandlers={props.polygonClicked}
                            pathOptions={item.id === props?.selected.id ? purpleOptions : yellowOptions}
                            positions={item.delimitations.coordinates}>
                            <MapInfoPopUp item={item}/>
                        </Polygon>
                    )
                    :
                    (
                        item?.localisation?.coordinates ?
                            (
                                <CircleMarker
                                    data={item}
                                    eventHandlers={props.polygonClicked}
                                    center={item.localisation.coordinates}
                                    fillColor={item.id === props?.selected.id ? "purple" : "red"}
                                    pathOptions={item.id === props?.selected.id ? purpleOptions : redOptions}
                                    radius={zoom <= 4 ? 2 : zoom}>
                                    <MapInfoPopUp item={item}/>
                                </CircleMarker>
                            ) : null
                    )
                }
            </React.Fragment>
        })
    }


    return (
        <React.Fragment>
            <LayersControl position={props.position}>
                <LayersControl.Overlay name="Délimitation des sites" checked>
                    <LayerGroup>
                        <SiteDelimitations/>
                    </LayerGroup>
                </LayersControl.Overlay>


                <LayersControl.BaseLayer name="OpenStreetMap">
                    <TileLayer
                        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                </LayersControl.BaseLayer>

                <LayersControl.BaseLayer name="Thunderforest">
                    <TileLayer
                        attribution='&amp;copy Stamen contributors'
                        url="https://stamen-tiles.a.ssl.fastly.net/terrain/{z}/{x}/{y}.png"
                    />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer checked name="Esri, DigitalGlobe">
                    <TileLayer
                        attribution="Esri, DigitalGlobe, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community"
                        className="basemap"
                        maxNativeZoom={19}
                        maxZoom={19}
                        subdomains={["clarity"]}
                        url="https://{s}.maptiles.arcgis.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                    />
                </LayersControl.BaseLayer>
            </LayersControl>
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    return {
        center: state.mapReducer.center,
        zoom_level: state.mapReducer.zoom_level,
        data: state.mapReducer.data,
        selected: state.siteReducer.selectedRecord,
        editionMode: state.siteReducer.editionMode,
    };
};


export default connect(mapStateToProps, {})(MapLayers)
